import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import appRoutes from "./app";

import GuestRoute from "app/components/GuestRoute";
import HandleServiceRedirectRoute from "app/components/HandleServiceRedirectRoute";
import AuthRoutesWrapper from "app/components/AuthRoutesWrapper";
import DashboardRoutesWrapper from "app/components/DashboardRoutesWrapper";
import MobileAppAuth from "app/components/MobileAppAuth";

import Login from "app/views/Login";
import Signup from "app/views/Signup";
import ForgotPassword from "app/views/ForgotPassword";
import ResetPassword from "app/views/ResetPassword";
import VerifyEmailAddress from "app/views/VerifyEmailAddress";
import NoMatch from "app/views/NoMatch";

const Routes: React.FC = () => {
  return (
    <Router>
      <MobileAppAuth>
        <Switch>
          <Redirect from="/" to="/login" exact />
          <Redirect
            from="/register"
            to={{ pathname: "/signup", search: window.location.search }}
          />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/change-password" component={ResetPassword} />
          <Route path="/verify-email" component={VerifyEmailAddress} exact />
          <Route path="/not-found" component={NoMatch} exact />
          {/* routes that cannot be accessed when authenticated */}
          <HandleServiceRedirectRoute path="/login" component={Login} exact />
          <GuestRoute path="/signup" component={Signup} />
          <AuthRoutesWrapper>
            <Switch>
              {/* authenticated routes without dashboard layout */}
              <DashboardRoutesWrapper>
                <Switch>
                  {/* authenticated routes with dashboard layout */}
                  {appRoutes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      component={route.component}
                      exact={route.exact}
                    />
                  ))}
                </Switch>
              </DashboardRoutesWrapper>
            </Switch>
          </AuthRoutesWrapper>
        </Switch>
      </MobileAppAuth>
    </Router>
  );
};

export default Routes;
