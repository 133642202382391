import React from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Avatar,
  DropdownButton,
  UserName,
  Dropdown,
} from "./styles";
import P from "app/styles/elements/P";
import { Link } from "react-router-dom";
import { logout } from "app/redux/auth/actions";
import ClickAway from "../ClickAway";

interface Props {
  fullName?: string;
  avatar?: string;
  signingOut: boolean;
}

const UserDropdown: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropDown] = React.useState(false);
  const toggleDropdown = () => setShowDropDown((s) => !s);
  const closeDropDown = () => setShowDropDown(false);

  function LogoutUser() {
    dispatch(logout());
  }

  return (
    <ClickAway onClickAway={closeDropDown}>
      <Container>
        <DropdownButton onClick={toggleDropdown}>
          <Avatar>
            {props?.avatar ? (
              <img src={props.avatar} alt={props.fullName} />
            ) : (
              <P medium semiBold>
                {props.fullName?.charAt(0).toUpperCase()}
              </P>
            )}
          </Avatar>
          <UserName little semiBold>
            {props.fullName}
          </UserName>
        </DropdownButton>
        {showDropdown && (
          <>
            <div className="triangle" />

            <Dropdown>
              <button
                onClick={LogoutUser}
                className={props.signingOut ? "loading" : ""}
              >
                Log out
              </button>
              <div className="links">
                <Link to="">Busha help center </Link>
                <Link to="">Blog</Link>
              </div>
            </Dropdown>
          </>
        )}
      </Container>
    </ClickAway>
  );
};

export default UserDropdown;
