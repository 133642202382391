import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ChangePhoneNumberPage from "./ChangePhoneNumber";
import ConfirmPage from "./Confirm";

const ChangePhoneNumber: React.FC = (props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ChangePhoneNumberPage} exact />
      <Route path={`${match.path}/confirm`} component={ConfirmPage} exact />
    </Switch>
  );
};

export default ChangePhoneNumber;
