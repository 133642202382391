import React from "react";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";

import "./style.scss";

import { ReactComponent as Icon } from "app/assets/img/icons/dropdown-icon.svg";
import { ReactComponent as NextIcon } from "app/assets/img/icons/next.svg";
import { ReactComponent as PrevIcon } from "app/assets/img/icons/prev.svg";
import { ReactComponent as EndIcon } from "app/assets/img/icons/end.svg";
import { ReactComponent as FirstIcon } from "app/assets/img/icons/first.svg";

interface Props {
  fullWidth?: boolean;
  onChange: (val: Date) => void;
  value: Date;
  transformDate?: (val: Date) => string;
  textStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  minDate?: Date;
  maxDate?: Date;
  defaultView?: "month" | "year" | "decade" | "century";
}

function DatePicker(props: Props) {
  const {
    value,
    fullWidth,
    transformDate,
    textStyle,
    containerStyle,
    minDate,
    maxDate,
    defaultView,
  } = props;

  const [showCalendar, setShowCalendar] = React.useState(false);
  const rootRef = React.useRef(null);

  const toggleCalendar = (val?: boolean) => (
    e: React.MouseEvent<HTMLButtonElement> | React.FocusEvent<HTMLInputElement>
  ) => setShowCalendar(val || !showCalendar);

  const onDateChange = (date: Date | Date[]) => {
    if (date instanceof Date) {
      props.onChange(date);
      setShowCalendar(false);
    }
  };

  const handleClickAway = (e: MouseEvent) => {
    const el: any = rootRef.current;
    if (el && !el.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickAway, false);
    return () => {
      document.removeEventListener("mousedown", handleClickAway, false);
    };
  });

  return (
    <div
      className={`date-picker ${fullWidth ? "full-width" : ""}`}
      ref={rootRef}
      style={{ ...containerStyle }}
    >
      <input
        onFocus={toggleCalendar(true)}
        readOnly
        value={transformDate ? transformDate(value) : value.toDateString()}
        style={{ ...textStyle }}
      />
      <button type="button" onClick={toggleCalendar()}>
        <Icon className={showCalendar ? "rotate" : ""} />
      </button>
      {/* tslint:disable-next-line: jsx-no-multiline-js */}
      {showCalendar && (
        <div className="dropdown">
          <Calendar
            onChange={onDateChange}
            calendarType="US"
            className="date-picker-calendar"
            nextLabel={<NextIcon />}
            prevLabel={<PrevIcon />}
            next2Label={<EndIcon />}
            prev2Label={<FirstIcon />}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            view={defaultView || "month"}
            tileClassName=""
          />
        </div>
      )}
    </div>
  );
}

export default DatePicker;
