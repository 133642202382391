import React from "react";

import { Badge, BadgeProps } from "./styles";
import { ReactComponent as AcceptedIcon } from "./icons/accepted.svg";
import { ReactComponent as PendingIcon } from "./icons/pending.svg";

const icons = {
  accepted: AcceptedIcon,
  pending: PendingIcon,
};

interface Props extends BadgeProps {}

const StatusBadge: React.FC<Props> = (props) => {
  const { status } = props;

  const Icon = icons[status];

  return (
    <Badge status={status}>
      {!!Icon && <Icon />}
      <span>{status}</span>
    </Badge>
  );
};

export default StatusBadge;
