import React from "react";
import { Redirect } from "react-router-dom";
import useAuth from "app/hooks/useAuth";

interface Props {}

const AuthRoutesWrapper: React.FC<Props> = (props) => {
  const isLoggedIn = useAuth();
  return !isLoggedIn ? <Redirect to="/login" /> : <>{props.children}</>;
};

export default AuthRoutesWrapper;
