import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

import { Section, SectionAction, SectionInfo } from "./styles";
import P from "app/styles/elements/P";
import Button from "app/styles/elements/Button";

const DocumentRequestsSection: React.FC = (props) => {
  const match = useRouteMatch();

  return (
    <Section>
      <SectionInfo>
        <P medium semiBold>
          Document Requests
        </P>
        <P small className="sub-title">
          This is where we alert you if we need any paperwork from you. All is
          good right now!
        </P>
      </SectionInfo>
      <SectionAction>
        <Button as={Link} to={`${match.path}/requests`} variant="outline">
          View requests
        </Button>
      </SectionAction>
    </Section>
  );
};

export default DocumentRequestsSection;
