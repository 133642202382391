import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

import { PageSection } from "../../styles";
import { Container, Title } from "./styles";
import { UserProfileType } from "app/api/profile/types";
import H2 from "app/styles/elements/H2";
import P from "app/styles/elements/P";

interface Props {
  userProfile: UserProfileType | null;
}

const UsernameSection: React.FC<Props> = (props) => {
  const match = useRouteMatch();
  const { userProfile } = props;

  return (
    <PageSection>
      <Container>
        <Title>
          <P medium semiBold>
            Busha Tag
          </P>
          <Link to={`${match.url}/username`}>Update Username</Link>
        </Title>
        {userProfile && (
          <H2 semiBold>
            {userProfile.username ? `#${userProfile.username}` : "N/A"}
          </H2>
        )}
        {userProfile && userProfile.username && (
          <P>
            Get paid fast and securely -{" "}
            <a href="google.com">https://b-tag.com/{userProfile.username}</a>
          </P>
        )}
      </Container>
    </PageSection>
  );
};

export default UsernameSection;
