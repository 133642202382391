import React, { useEffect } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "app/styles/blocks/PageHeader";
import PrevPageLink from "app/components/PrevPageLink";
import WebSessions from "./components/WebSessions";
import { RootState } from "app/redux/reducers";
import LoaderBox from "app/components/LoaderBox";
import {
  fetchUserSessions,
  signoutUserSession,
} from "app/redux/sessions/actions";
import EmptyList from "app/components/EmptyList";

const WebSessionsPage: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    data,
    paginator,
    pending,
    success,
    updating,
    selectedSessionId,
  } = useSelector((state: RootState) => state.sessions);

  function fetchPreviousPage() {
    const page = paginator?.page ? paginator?.page - 1 : 1;
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  }

  function fetchNextPage() {
    const page = paginator?.page ? paginator?.page + 1 : 1;
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  }

  function handleSignoutSession(id: string | number) {
    dispatch(signoutUserSession(id));
  }

  useEffect(() => {
    dispatch(fetchUserSessions());
  }, [dispatch]);

  useEffect(() => {
    if (location.search) {
      const queryValues = queryString.parse(location.search);
      const { page } = queryValues;
      if (page) dispatch(fetchUserSessions(page.toString()));
    }
  }, [location.search, dispatch]);

  return (
    <div className="page-content">
      <PrevPageLink text="Your devices" to="/devices" />
      <PageHeader.Main>
        <PageHeader.Title big>Web sessions</PageHeader.Title>
        <PageHeader.Description small>
          Places where you’re logged into Busha
        </PageHeader.Description>
      </PageHeader.Main>
      {pending ? (
        <LoaderBox height={400} />
      ) : (
        <>
          {data.length < 1 && success ? (
            <EmptyList text="No mobile applications authorized" />
          ) : (
            <WebSessions
              sessions={data}
              paginator={paginator}
              handleNextPage={fetchNextPage}
              handlePreviousPage={fetchPreviousPage}
              handleSignoutSession={handleSignoutSession}
              updating={updating}
              selectedSessionId={selectedSessionId}
            />
          )}
        </>
      )}
    </div>
  );
};

export default WebSessionsPage;
