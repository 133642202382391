import React from "react";

import { ErrorBox, Message } from "./styles";
import Button, { ButtonComponentProps } from "../Button";
import P from "app/styles/elements/P";

interface Props {
  containerClassName?: string;
  message: string;
  retryButtonProps?: ButtonComponentProps;
}
const ErrorState: React.FC<Props> = (props) => {
  const { message, retryButtonProps, containerClassName } = props;

  return (
    <ErrorBox className={containerClassName}>
      <Message>
        <P small>{message}</P>
      </Message>
      {retryButtonProps && (
        <Button
          {...retryButtonProps}
          variant="green"
          text={retryButtonProps.text || "Retry"}
        />
      )}
    </ErrorBox>
  );
};

export default ErrorState;
