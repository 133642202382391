import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Section, SectionAction, SectionInfo } from "./styles";
import P from "app/styles/elements/P";
import Button from "app/components/Button";
import ButtonElement from "app/styles/elements/Button";
import { RootState } from "app/redux/reducers";

const BvnSection: React.FC = (props) => {
  const { data: userProfile } = useSelector(
    (state: RootState) => state.profile
  );

  return (
    <Section>
      <SectionInfo>
        <P medium semiBold>
          {userProfile?.bvn || "No BVN"}
        </P>
        <P small className="sub-title">
          {userProfile?.bvn
            ? "You have a added your BVN number to your account"
            : "Please add your BVN"}
        </P>
      </SectionInfo>
      <SectionAction>
        {userProfile?.bvn ? (
          <Button disabled={true} variant="outline" text="BVN added" />
        ) : (
          <ButtonElement
            as={Link}
            to={{
              pathname: `/limits-and-levels/verify/bvn`,
              state: { securityPageAction: true },
            }}
            variant="outline"
          >
            Add BVN
          </ButtonElement>
        )}
      </SectionAction>
    </Section>
  );
};

export default BvnSection;
