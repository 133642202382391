import React from "react";
import { useFormik } from "formik";

import { Form } from "./styles";
import Input from "app/components/Input";
import Button from "app/components/Button";
import { ValidateReferralCodePayload } from "app/api/auth/types";
import { Title, InfoText, EmailBlock } from "../styles";
import H2 from "app/styles/elements/H2";
import P from "app/styles/elements/P";
import api from "app/api";

interface Props {
  onRefCodeVerified: (ref: string) => void;
  onChangeEmail: () => void;
  onNoRefCode: () => void;
  email: string;
  loading?: boolean;
}

const ReferralForm: React.FC<Props> = props => {
  const { onNoRefCode, onChangeEmail, onRefCodeVerified, email } = props;

  const validate = (values: ValidateReferralCodePayload) => {
    const errors: any = {};

    if (!values.code) {
      errors.code = "Referral code is required";
    }

    return errors;
  };
  const form = useFormik<ValidateReferralCodePayload>({
    initialValues: {
      code: ""
    },
    onSubmit: async values => {
      try {
        await api.authService.validateCredential({
          name: "referral",
          value: values.code
        });
        onRefCodeVerified(values.code);
      } catch (error) {
        // the request failing means that the username does not exist
        form.errors.code = "Referral code is invalid";
      }
    },
    validate,
    validateOnChange: true
  });
  const onInputFocus = (name: string) => () =>
    form.setFieldError(name, undefined);

  return (
    <>
      <Title>
        <H2>Referral</H2>
        <P small>
          Enter the referral code given to you by the person that referred you.
        </P>
      </Title>
      <EmailBlock>
        <P small>{email}</P>
        <button onClick={onChangeEmail}>Change email</button>
      </EmailBlock>
      <Form onSubmit={form.handleSubmit}>
        <Input
          fullwidth
          placeholder="Enter your referral code"
          label="Referral code"
          name="code"
          id="code"
          value={form.values.code}
          onChange={form.handleChange}
          autoFocus
          error={!!form.errors.code}
          errorMessage={form.errors.code}
          onFocus={onInputFocus("code")}
        />
        <Button
          type="submit"
          className="submit-button"
          text="Next"
          variant="green"
          disabled={form.isSubmitting}
        />
      </Form>
      <InfoText small>
        <button onClick={onNoRefCode}>I don’t have a referral code</button>
      </InfoText>
    </>
  );
};

export default ReferralForm;
