import React from "react";

import { NotificationProps, NotificationContainer } from "./styles";
import P from "app/styles/elements/P";
import { ReactComponent as CloseIcon } from "app/assets/img/icons/close-white.svg";

export interface NotificationComponentProps extends NotificationProps {
  message?: string | JSX.Element;
  closeToast?: () => void;
}

const Notification: React.FC<NotificationComponentProps> = (props) => {
  const { message, closeToast, ...rest } = props;
  return (
    <NotificationContainer {...rest}>
      <P small>{message}</P>
      <button onClick={closeToast}>
        <CloseIcon />
      </button>
    </NotificationContainer>
  );
};

export default Notification;
