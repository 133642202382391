import styled, { keyframes } from "styled-components";

export interface LoaderLineProps {
  color?: string;
  size?: number;
}

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const LoaderLine = styled.div<LoaderLineProps>`
  border: 2px solid ${props => props.color || "#fff"};
  border-right-color: transparent;
  border-top-color: transparent;
  width: ${props => props.size || 16}px;
  height: ${props => props.size || 16}px;
  border-radius: 100%;
  animation: ${spin} 500ms infinite linear;
`;
