import React from "react";
import { LoaderBoxContainer } from "./styles";
import Loader from "../Loader";

interface Props {
  text?: string;
  height?: number;
}

const LoaderBox: React.FC<Props> = ({ text, height }) => {
  return (
    <LoaderBoxContainer height={height}>
      <Loader size={40} color="#18981d" />
      {text && <p>{text}</p>}
    </LoaderBoxContainer>
  );
};

export default LoaderBox;
