import styled, { css } from "styled-components";

interface Props {
  medium?: boolean;
  semiBold?: boolean;
  big?: boolean;
}

const H2 = styled.h2<Props>`
  font-weight: bold;
  font-size: 1rem;

  ${props =>
    props.medium &&
    css`
      /* 24px */
      font-size: 1.2rem;
    `};

  ${props =>
    props.big &&
    css`
      /* 32px */
      font-size: 1.6rem;
    `};
  ${props =>
    props.semiBold &&
    css`
      font-weight: 500;
    `};
`;

export default H2;
