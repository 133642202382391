import styled from "styled-components";

export const Form = styled.form`
  width: 100%;

  .submit-button {
    margin-top: 20px;
  }
`;

export const RecaptchaBlock = styled.div`
  margin-top: 30px;
  border: 1px solid #d3d3d3;
  background-color: #f9f9f9;
  border-radius: 3px;
  height: 74px;
  width: 300px;
`;
