import React, { useRef, useState, useCallback, useEffect } from "react";
import { InputGroup, InputContainer, Form } from "./styles";
import FormInput from "app/styles/blocks/FormInput";
import P from "app/styles/elements/P";
import { InfoText } from "../styles";
import Loader from "app/components/Loader";

interface Props {
  onSubmitCode: (code: string) => void;
  loading: boolean;
  onResendCode?: () => void;
  resendCode?: boolean;
  resendingCode?: boolean;
  message?: string;
}

interface CodeObject {
  [key: string]: { [key: string]: string };
}

type CodeInputRef = React.MutableRefObject<HTMLInputElement | null>;

const TwoFactorForm: React.FC<Props> = ({
  onSubmitCode,
  loading,
  onResendCode,
  resendCode,
  resendingCode,
  message
}) => {
  const initialCodObject: CodeObject = {
    first: { 0: "", 1: "", 2: "" },
    second: { 0: "", 1: "", 2: "" }
  };
  const [codeObject, setCodeObject] = useState(initialCodObject);

  const inputRefs: CodeInputRef[] = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),

    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null)
  ];

  const onInputChange = (section: string, key: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (codeObject[section][key]) return;
    const value = e.target.value;
    setCodeObject(_codeObject => ({
      ..._codeObject,
      [section]: { ..._codeObject[section], [key]: value }
    }));
  };

  const gotoNextInput = (index: number) => {
    const nextInput = inputRefs[index + 1];
    if (nextInput) {
      nextInput.current?.focus();
    }
  };
  const gotoPrevInput = (index: number) => {
    const prevInput = inputRefs[index - 1];
    if (prevInput) {
      prevInput.current?.focus();
    }
  };

  const onKeyDown = (section: string, key: string, index: number) => (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    e.stopPropagation();
    if (codeObject[section][key].length === 1) {
      if (e.key === "Backspace") {
        setCodeObject(_codeObject => ({
          ..._codeObject,
          [section]: { ..._codeObject[section], [key]: "" }
        }));
      } else {
        gotoNextInput(index);
      }
    } else {
      if (e.key === "Enter" || e.ctrlKey || e.metaKey || e.altKey || e.shiftKey)
        return;
      if (e.key !== "Backspace") gotoNextInput(index);
      else gotoPrevInput(index);
    }
  };

  const getCode = useCallback(() => {
    return `${Object.values(codeObject.first).join("")}${Object.values(
      codeObject.second
    ).join("")}`;
  }, [codeObject]);

  useEffect(
    useCallback(() => {
      const code = getCode();
      if (code.length === 6) {
        onSubmitCode(code);
      }
    }, [getCode, onSubmitCode]),
    [codeObject]
  );

  return (
    <Form as="div">
      <P small>{message}</P>
      <InputContainer>
        <InputGroup>
          {Object.keys(codeObject.first).map((key, index) => (
            <FormInput.Input
              key={index}
              autoFocus={key === "0"}
              value={codeObject.first[key]}
              onChange={onInputChange("first", key)}
              onKeyUp={onKeyDown("first", key, index)}
              ref={inputRefs[index]}
              disabled={loading}
            />
          ))}
        </InputGroup>
        <div className="divider" />
        <InputGroup>
          {Object.keys(codeObject.second).map((key, index) => (
            <FormInput.Input
              key={index}
              value={codeObject.second[key]}
              onChange={onInputChange("second", key)}
              onKeyUp={onKeyDown("second", key, index + 3)}
              ref={inputRefs[index + 3]}
              disabled={loading}
            />
          ))}
        </InputGroup>
      </InputContainer>
      {resendCode && (
        <InfoText small onClick={onResendCode}>
          {resendingCode ? (
            <Loader color="#18981d" />
          ) : (
            <button>Resend Code</button>
          )}
        </InfoText>
      )}
    </Form>
  );
};

export default TwoFactorForm;
