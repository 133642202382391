import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ReactComponent as Logo } from "app/assets/img/logo.svg";
import AuthLayout from "app/styles/blocks/AuthLayout";
import { Title } from "./styles";
import H2 from "app/styles/elements/H2";
import { Link } from "react-router-dom";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import P from "app/styles/elements/P";
import { RootState } from "app/redux/reducers";
import {
  sendForgotPasswordEmail,
  cleanupForgotPasswordState,
} from "app/redux/auth/actions";

const ForgotPassword: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const { forgotPassword: ForgotPasswordState } = useSelector(
    (state: RootState) => state.auth
  );

  const onSubmitForm = (email: string, token: string | null) => {
    dispatch(sendForgotPasswordEmail({ email, token }));
  };

  // clean up forgot password state on page load
  useEffect(
    useCallback(() => {
      dispatch(cleanupForgotPasswordState());
    }, [dispatch]),
    []
  );

  useEffect(
    useCallback(() => {
      if (ForgotPasswordState.success) {
        history.push(`${match.path}/success`);
      }
    }, [ForgotPasswordState, history, match.path]),
    [ForgotPasswordState]
  );

  return (
    <AuthLayout.Main>
      <AuthLayout.Body>
        <AuthLayout.Header>
          <Link to="/">
            <Logo className="logo" />
          </Link>
        </AuthLayout.Header>
        <Title>
          <H2>Reset password</H2>
          <P small subdued>
            Enter the email you use for Busha, and we’ll help you create a new
            password
          </P>
        </Title>
        <ForgotPasswordForm
          onSubmitForm={onSubmitForm}
          loading={ForgotPasswordState.pending}
          error={ForgotPasswordState.error}
        />
      </AuthLayout.Body>
    </AuthLayout.Main>
  );
};

export default ForgotPassword;
