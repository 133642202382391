import styled from "styled-components";
import media from "app/styles/utils/media";

export const Container = styled.div`
  width: 100%;
  padding-bottom: 37px;
`;

export const Form = styled.form`
  width: 100%;

  .footer {
    width: 100%;
    margin-top: 8px;
    padding-top: 24px;
    border-top: 1px solid rgba(211, 213, 216, 0.5);
    display: flex;
    justify-content: flex-end;

    ${media.mobile`
        padding-top: 0px;
        border-top: none;
    `};
  }
  & button[type="submit"] {
    width: 241px;
    max-width: 100%;
    margin-top: 8px;

    ${media.mobile`
        width: 100%;
    `};
  }
`;

export const InputsBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 35px;
  align-items: center;
  ${media.mobile`
    grid-template-columns: 1fr;
  `};
  .form-group {
    margin-bottom: 16px;
  }
`;

export const DetailItem = styled.div`
  text-align: left;
  margin-bottom: 16px;
  & > .title {
    color: #616e7c;
  }
  & > .value {
    margin-top: 2px;
  }
`;
