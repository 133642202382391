import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "app/styles/blocks/PageHeader";
import PrevPageLink from "app/components/PrevPageLink";
import PersonalDetailsForm from "app/views/Profile/components/PersonalDetailsForm";
import { Container, Info } from "../styles";
import StepIndicator from "app/views/Signup/components/StepIndicator";
import { IndicatorContext } from "..";
import P from "app/styles/elements/P";
import { RootState } from "app/redux/reducers";
import {
  updateUserProfile,
  clearProfileActionState,
} from "app/redux/profile/actions";
import { UpdateProfilePayload } from "app/api/profile/types";

const ProfileVerfication: React.FC = (props) => {
  const stepIndicatorCntxt = React.useContext(IndicatorContext);

  const dispatch = useDispatch();

  const { data, action } = useSelector((state: RootState) => state.profile);

  const onUpdateProfile = React.useCallback(
    (payload: UpdateProfilePayload) => {
      dispatch(updateUserProfile(payload));
    },
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(clearProfileActionState());
  }, [dispatch]);

  React.useEffect(() => {
    if (action === "updated") {
      dispatch(clearProfileActionState());
      stepIndicatorCntxt.goToNextStep(stepIndicatorCntxt.currentStep);
    }
  }, [action, stepIndicatorCntxt, dispatch]);

  return (
    <div className="page-content">
      <PrevPageLink to="/limits-and-levels" text="Limits and levels" />
      <PageHeader.Main>
        <PageHeader.Title big>Complete your profile</PageHeader.Title>
      </PageHeader.Main>
      <Container>
        <Info>
          <div className="step-indicator">
            <StepIndicator
              numberOfSteps={stepIndicatorCntxt.totalSteps}
              activeStep={stepIndicatorCntxt.currentStep}
            />
          </div>
          <P medium semiBold>
            Personal details
          </P>
        </Info>
        <PersonalDetailsForm
          initialValues={data}
          loading={action === "updating"}
          onUpdateProfile={onUpdateProfile}
        />
      </Container>
    </div>
  );
};

export default ProfileVerfication;
