import { all } from "redux-saga/effects";

import authSagas from "./auth/saga";
import tokenSagas from "./tokens/saga";
import profileSagas from "./profile/saga";
import notificationsSagas from "./notifications/saga";
import activitiesSagas from "./activities/saga";
import devicesSagas from "./devices/saga";
import applicationsSagas from "./applications/saga";
import sessionsSagas from "./sessions/saga";
import securitySagas from "./security/saga";
import documentsSagas from "./documents/saga";

export default function* root() {
  yield all([
    authSagas(),
    tokenSagas(),
    profileSagas(),
    notificationsSagas(),
    activitiesSagas(),
    devicesSagas(),
    applicationsSagas(),
    sessionsSagas(),
    securitySagas(),
    documentsSagas(),
  ]);
}
