import request from "../request";
import { FetchApplicationsResponse } from "./types";
import {
  GenericParamsPayload,
  GenericDeletePayload,
  GenericResponse,
} from "../types";

const fetchApplications = async (payload: GenericParamsPayload) => {
  const query = `?page=${payload.page}`;
  return await request.get<FetchApplicationsResponse>(
    `/v3/me/applications${query}`
  );
};

const removeApplication = async (payload: GenericDeletePayload) => {
  return await request.delete<GenericResponse>(
    `/v3/me/applications/${payload.id}`
  );
};

export default { fetchApplications, removeApplication };
