import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

import { Section, SectionAction, SectionInfo } from "./styles";
import P from "app/styles/elements/P";
import Button from "app/styles/elements/Button";

const WebSessionSection: React.FC = (props) => {
  const match = useRouteMatch();

  return (
    <Section>
      <SectionInfo>
        <P medium semiBold>
          Web sessions
        </P>
        <P small className="sub-title">
          Places where you’re logged in to Busha
        </P>
      </SectionInfo>
      <SectionAction>
        <Button as={Link} to={`${match.path}/web`} variant="outline">
          Manage web sessions
        </Button>
      </SectionAction>
    </Section>
  );
};

export default WebSessionSection;
