import React from "react";
import { useFormik } from "formik";
import { Form } from "./styles";
import Button from "app/components/Button";
import Select from "app/components/Select";
import Input from "app/components/Input";
import { DocumentCountryType } from "app/api/documents/types";

interface Props {
  onIdSubmitted: (idNumber: string) => void;
  country?: DocumentCountryType | null;
}

interface FormProps {
  id_number: string;
}

const NumberIDForm: React.FC<Props> = (props) => {
  const validate = (values: FormProps) => {
    const errors: any = {};

    if (!values.id_number) {
      errors.id_number = "Id number is required";
    }

    return errors;
  };

  const form = useFormik<FormProps>({
    initialValues: {
      id_number: "",
    },
    onSubmit: (values) => {
      props.onIdSubmitted(values.id_number);
    },
    validate,
    validateOnChange: false,
  });

  const onInputFocus = (name: string) => () => {
    form.setFieldError(name, undefined);
  };

  return (
    <Form onSubmit={form.handleSubmit}>
      <Select
        fullWidth
        name="country"
        label="Issuing country/region"
        value={props.country?.id || ""}
        options={[
          {
            label: props.country?.name || "",
            value: props.country?.id || "",
            icon: props.country?.flag || "",
          },
        ]}
        onChange={() => {}}
        disabled
      />
      <Input
        containerClassName="doc-id"
        name="id_number"
        label="Passport number"
        value={form.values.id_number}
        onChange={form.handleChange}
        error={!!form.errors.id_number}
        errorMessage={form.errors.id_number}
        autoFocus
        onFocus={onInputFocus("id_number")}
      />
      <Button text="Proceed" type="submit" variant="green" />
    </Form>
  );
};

export default NumberIDForm;
