import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import TwoStepAuthPage from "./TwoStepAuth";
import AuthAppRoutes from "./AuthApp";
import SmsRoutes from "./Sms";

const Security: React.FC = props => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={TwoStepAuthPage} exact />

      <Route path={`${match.path}/auth-app`} component={AuthAppRoutes} />

      <Route path={`${match.path}/sms`} component={SmsRoutes} />
    </Switch>
  );
};

export default Security;
