import styled from "styled-components";

export const Container = styled.div`
  width: 380px;
  max-width: 100%;
  padding-top: 25px;

  & > form {
    width: 100%;

    & > * {
      margin-bottom: 16px;
    }

    & > button {
      margin-top: 8px;
    }
  }
`;
