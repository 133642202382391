import React from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Info, CodeInputBox } from "../styles";
import P from "app/styles/elements/P";
import Button from "app/components/Button";
import CodeInput from "app/components/CodeInput";
import StepIndicator from "app/views/Signup/components/StepIndicator";
import { IndicatorContext } from "..";
import {
  finalizeUserSecurityService,
  cleanupSecurityActionState,
} from "app/redux/security/actions";
import { RootState } from "app/redux/reducers";

interface State {
  bvn: string;
  first_number: string;
  last_number: string;
  complete_phone_number_allowed: boolean;
  securityPageAction: boolean;
}

const BVNEnablePage: React.FC = () => {
  const stepIndicatorCntxt = React.useContext(IndicatorContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const [allowMissingDigits, setAllowMissingDigits] = React.useState(false);
  const switchToUseMissingDigits = () => setAllowMissingDigits(true);

  const location = useLocation<State | null>();
  const state = location.state;

  const { action } = useSelector((state: RootState) => state.security);

  const onCodeCompleted = (code: string) => {
    dispatch(
      finalizeUserSecurityService("bvn", {
        code,
        bvn: state?.bvn,
        missing_phone_number_confirmation: allowMissingDigits,
      })
    );
  };

  React.useEffect(
    React.useCallback(() => {
      if (action.success && action.finalized) {
        dispatch(cleanupSecurityActionState());
        if (state?.securityPageAction) {
          history.push("/security");
        } else {
          stepIndicatorCntxt.goToNextStep(stepIndicatorCntxt.currentStep);
        }
      }
      return () => {
        dispatch(cleanupSecurityActionState());
      };
    }, [action, stepIndicatorCntxt, dispatch, state, history]),
    [action.success, action.finalized]
  );

  if (
    !action.initialized ||
    !state?.bvn ||
    !state?.first_number ||
    !state?.last_number
  ) {
    return <Redirect to="/security" />;
  }

  return (
    <Container>
      <Info>
        <div className="step-indicator">
          <StepIndicator
            numberOfSteps={stepIndicatorCntxt.totalSteps}
            activeStep={stepIndicatorCntxt.currentStep}
          />
        </div>
        <P medium semiBold>
          {allowMissingDigits
            ? " Complete the missing numbers"
            : "Enter verification code"}
        </P>
        <P small className="description">
          {allowMissingDigits
            ? "Enter the 6 missing numbers from"
            : "Enter OTP code sent to"}
          <br /> {state.first_number} - - - - - - {state.last_number}
        </P>
      </Info>

      <CodeInputBox>
        <CodeInput
          onCodeCompleted={onCodeCompleted}
          disableFields={action.pending}
        />
        {allowMissingDigits && (
          <P little className="help-text">
            This is the phone number registered to your BVN
          </P>
        )}
      </CodeInputBox>

      {!allowMissingDigits && state.complete_phone_number_allowed && (
        <Button
          variant="clear"
          text="I don’t have access to this number."
          onClick={switchToUseMissingDigits}
        />
      )}
    </Container>
  );
};

export default BVNEnablePage;
