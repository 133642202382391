import { fetchSessions, signoutSession } from "./types";

const fetchUserSessions = (page: number | string = 1) => {
  return { type: fetchSessions.default, meta: { page } };
};

const signoutUserSession = (id: number | string) => {
  return { type: signoutSession.default, payload: { id } };
};

export { fetchUserSessions, signoutUserSession };
