import React, { useRef, useState } from "react";
import { PageSection } from "../../styles";
import { Container, AvatarBox } from "./styles";
import Avatar from "app/styles/elements/Avatar";
import Button from "app/components/Button";
import P from "app/styles/elements/P";
import H2 from "app/styles/elements/H2";
import { UserProfileType } from "app/api/profile/types";
import { UploadImage } from "app/utils/amazonS3upload";
import { asyncWrap } from "app/utils/helpers";
import toast from "app/utils/toast";

interface Props {
  userProfile: UserProfileType | null;
  updating: boolean;
  handleAvatarUrlUpdate: (avatar: string) => void;
}

const ProfileImageSection: React.FC<Props> = ({
  userProfile,
  updating,
  handleAvatarUrlUpdate,
}) => {
  const [uploading, setUploading] = useState(false);

  const fileInput = useRef<HTMLInputElement | null>(null);

  const onUploadAvatar = async (event: any) => {
    setUploading(true);

    const file = event?.target?.files[0];

    if (fileInput.current?.value) fileInput.current.value = "";

    if (file) {
      const [error, link] = await asyncWrap(UploadImage(file));

      if (link) {
        handleAvatarUrlUpdate(link);
      } else if (error) {
        toast(error.message);
      }
    }
    setUploading(false);
  };

  return (
    <PageSection>
      <Container>
        <AvatarBox>
          <Avatar>
            {userProfile?.avatar ? (
              <img src={userProfile.avatar} alt={userProfile.first_name} />
            ) : (
              <P>{userProfile?.first_name?.charAt(0).toUpperCase()}</P>
            )}
          </Avatar>
          <Button
            variant="clear"
            text="Update photo"
            loading={updating || uploading}
            onClick={() => fileInput.current?.click()}
          />
          <label htmlFor="avatar-file">Select file for Avatar upload</label>
          <input
            type="file"
            id="avatar-file"
            name="avatar-file"
            accept="image/png,image/gif,image/jpeg"
            ref={fileInput}
            onChange={onUploadAvatar}
          />
        </AvatarBox>
        <H2 medium semiBold>
          {userProfile?.first_name} {userProfile?.last_name}
        </H2>
        <P little>{userProfile?.email}</P>
      </Container>
    </PageSection>
  );
};

export default ProfileImageSection;
