import React from "react";
import {
  LevelBox,
  PipeSection,
  TextSection,
  Circle,
  Line,
  LimitValues,
  LevelItem,
} from "./styles";
import P from "app/styles/elements/P";
import Button from "app/components/Button";
import { ReactComponent as LimitDoneIcon } from "app/assets/img/icons/limit-done.svg";
import { ReactComponent as ItemActiveIcon } from "app/assets/img/icons/2fa-on.svg";
import { ReactComponent as ItemInActiveIcon } from "app/assets/img/icons/level-item-off.svg";

interface LevelItem {
  name: string;
  done: boolean;
}
export interface LevelProps {
  name: string;
  status: "inactive" | "active" | "done";
  actionButtonText: string;
  onActionButtonClicked: () => void;
  hideActionButton?: boolean;
  items: LevelItem[];
  maxCardDeposit: string;
  maxBankTransfer: string;
  maxValuesinMonths?: boolean;
  hidePipeLine?: boolean;
}
const Level: React.FC<LevelProps> = (props) => {
  const {
    name,
    status,
    actionButtonText,
    onActionButtonClicked,
    maxCardDeposit,
    maxBankTransfer,
    maxValuesinMonths,
    items,
    hideActionButton,
    hidePipeLine,
  } = props;
  return (
    <LevelBox>
      <PipeSection>
        <Circle active={status === "active"} done={status === "done"}>
          <LimitDoneIcon />
        </Circle>
        {!hidePipeLine && <Line active={status === "done"} />}
      </PipeSection>
      <TextSection>
        <P medium semiBold className="name">
          {name}
        </P>
        <LimitValues>
          <div>
            <P tiny className="title">
              CARD DEPOSITS
            </P>
            <P medium semiBold className="value">
              {maxCardDeposit}
              {maxValuesinMonths && <span className="per-month">/month</span>}
            </P>
          </div>
          <div>
            <P tiny className="title">
              BANK TRANSFERS
            </P>
            <P medium semiBold className="value">
              {maxBankTransfer}
              {maxValuesinMonths && <span className="per-month">/month</span>}
            </P>
          </div>
        </LimitValues>
        <div className="items">
          {items.map((item) => (
            <LevelItem key={item.name}>
              {item.done ? <ItemActiveIcon /> : <ItemInActiveIcon />}
              <P little>{item.name}</P>
            </LevelItem>
          ))}
        </div>
        {!hideActionButton && (
          <Button
            variant="green"
            text={actionButtonText}
            onClick={onActionButtonClicked}
            type="button"
          />
        )}
      </TextSection>
    </LevelBox>
  );
};

export default Level;
