import { AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/utils/parseError";
import {
  fetchApplications,
  FetchApplicationsAction,
  removeApplication,
  RemoveApplicationAction,
} from "./types";
import { FetchApplicationsResponse } from "app/api/applications/types";

function* fetchApplicationsSaga(action: FetchApplicationsAction) {
  try {
    const { meta } = action;

    yield put({ type: fetchApplications.pending });

    const res: AxiosResponse<FetchApplicationsResponse> = yield call(
      api.applicationsService.fetchApplications,
      meta
    );
    const data = res.data;
    yield put({ type: fetchApplications.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: fetchApplications.rejected, payload: errorMessage });
  }
}

function* removeApplicationsSaga(action: RemoveApplicationAction) {
  try {
    const { payload } = action;

    yield put({ type: removeApplication.pending });

    const res: AxiosResponse<FetchApplicationsResponse> = yield call(
      api.applicationsService.removeApplication,
      payload
    );
    const data = res.data;
    yield put({ type: removeApplication.fulfilled, payload: data });
    yield put({ type: fetchApplications.default, meta: { page: 1 } });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: removeApplication.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchApplications.default, fetchApplicationsSaga),
    takeLatest(removeApplication.default, removeApplicationsSaga),
  ]);
}
