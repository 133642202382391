import styled from "styled-components";

import media from "app/styles/utils/media";

export const Container = styled.div`
  padding-top: 24px;
  width: 380px;
  max-width: 100%;

  & > button {
    width: 335px;
    max-width: 100%;
    margin-top: 36px;

    ${media.mobile`
      width: 100%;
      `};
  }
`;

export const DocTypeSelctBox = styled.div`
  margin-top: 24px;
  .label {
    margin-bottom: 10px;
  }
  .description {
    color: #3e4c59;
  }
  .select {
    margin-top: 24px;
  }
`;
