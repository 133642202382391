import React, { useEffect } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "app/styles/blocks/PageHeader";
import Activities from "./components/Activities";
import { RootState } from "app/redux/reducers";
import LoaderBox from "app/components/LoaderBox";
import { fetchUserActivities } from "app/redux/activities/actions";
import EmptyList from "app/components/EmptyList";

const ActivitiesPage: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { data, paginator, pending, success } = useSelector(
    (state: RootState) => state.activities
  );

  function fetchPreviousPage() {
    const page = paginator?.page ? paginator?.page - 1 : 1;
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  }

  function fetchNextPage() {
    const page = paginator?.page ? paginator?.page + 1 : 1;
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  }

  useEffect(() => {
    dispatch(fetchUserActivities());
  }, [dispatch]);

  useEffect(() => {
    if (location.search) {
      const queryValues = queryString.parse(location.search);
      const { page } = queryValues;
      if (page) dispatch(fetchUserActivities(page.toString()));
    }
  }, [location.search, dispatch]);

  return (
    <div className="page-content">
      <PageHeader.Main>
        <PageHeader.Title big>Account activities</PageHeader.Title>
        <PageHeader.Description small>
          Check your recent account activities to strengthen your security
        </PageHeader.Description>
      </PageHeader.Main>
      {pending ? (
        <LoaderBox height={400} />
      ) : (
        <>
          {data.length < 1 && success ? (
            <EmptyList text="No activities" />
          ) : (
            <Activities
              activities={data}
              paginator={paginator}
              handleNextPage={fetchNextPage}
              handlePreviousPage={fetchPreviousPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ActivitiesPage;
