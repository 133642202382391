import React from "react";
import { useSelector } from "react-redux";
import { Container, Logo, MobileSidebarButton, Section } from "./styles";
import { ReactComponent as LogoImg } from "app/assets/img/logo.svg";
import { ReactComponent as HamburgerIcon } from "app/assets/img/icons/hamburger.svg";
import UserDropdown from "../UserDropdown";
import { RootState } from "app/redux/reducers";

interface Props {
  onMobileBtnclicked: () => void;
}
const Topnav: React.FC<Props> = (props) => {
  const { data } = useSelector((state: RootState) => state.profile);
  const { pending } = useSelector((state: RootState) => state.auth.logout);

  const fullName = `${data?.first_name || ""} ${data?.last_name || ""}`;

  return (
    <Container>
      <Section>
        <MobileSidebarButton onClick={props.onMobileBtnclicked}>
          <HamburgerIcon />
        </MobileSidebarButton>
        <Logo>
          <LogoImg />
        </Logo>
      </Section>
      <UserDropdown
        fullName={fullName}
        avatar={data?.avatar}
        signingOut={pending}
      />
    </Container>
  );
};

export default Topnav;
