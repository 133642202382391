import styled, { css } from "styled-components";
import media from "app/styles/utils/media";

export const Container = styled.div`
  .requirement,
  .warning {
    color: #3e4c59;
    a {
      color: #18981d;
    }
  }

  .warning {
    margin: 16px 0 44px;
  }

  & > button {
    width: 335px;
    max-width: 100%;
    margin-top: 44px;
  }
`;

interface FileDropZoneProps {
  active?: boolean;
  error?: boolean;
}
export const FileDropZone = styled.div<FileDropZoneProps>`
  width: 581px;
  min-height: 220px;
  max-width: 100%;
  border: 2px dashed #dfe3e8;
  border-radius: 2px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;

  transition: border 300ms ease-in;

  & > svg {
    margin-bottom: 20px;
  }

  .help-text {
    color: #637381;
    margin-top: 14px;
  }
  button {
    color: #3e4c59;
    width: 115px;
    height: 36px;
    font-size: 0.7rem;
    padding: 8px 16px;
  }

  .preview {
    width: 192px;
    max-height: 269px;
  }

  ${(props) =>
    props.active &&
    css`
      border-color: #18981d;
    `};
  ${(props) =>
    props.error &&
    css`
      border-color: #e12d39;
    `};
`;

export const UploadBlock = styled.div`
  ${media.mobile`
    width: 100%;
    `}

  .label {
    color: #637381;
    margin-bottom: 10px;
    text-align: center;
  }
`;
interface Props {
  value?: string;
}
export const ProgressBar = styled.div<Props>`
  height: 16px;
  width: 340px;
  max-width: 100%;
  background: #eff1f3;
  border-radius: 3px;
  div {
    border-radius: ${(props) =>
      props.value === "100" ? "3px" : "3px 0px 0px 3px"};
    background: #31b237;
    height: 100%;
    width: ${(props) => props.value}%;
    transition: width 200ms ease-in;
  }
`;
