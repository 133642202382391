import styled from "styled-components";

export const AuthAppContainer = styled.div`
  width: 560px;
  max-width: 100%;
  padding: 12px 0px;

  & > .link {
    margin-top: 35px;
    display: inline-flex;
    /* width: 129px; */
  }
`;

export const Section = styled.div`
  padding-top: 13px;
  text-align: left;

  & > .description {
    margin-top: 10px;
    color: #3e4c59;
  }
  & .description.bold {
    font-weight: bold;
  }
  & .code {
    word-break: break-all;
  }
`;

export const QRBox = styled.div`
  margin: 33px 0px 23px;
  & > .qr-code {
    width: 182px;
    height: 182px;
  }
`;

export const CodeInputBox = styled.div`
  margin: 42px 0 11px;
`;
