import { logoutUser } from "../auth/types";
import { DevicesState, fetchDevices, removeDevice } from "./types";

const INITIAL_STATE: DevicesState = {
  pending: false,
  updating: false,
  error: null,
  success: false,
  paginator: null,
  data: [],
  selectedDeviceId: null,
};

export default function auth(state = INITIAL_STATE, action: any): DevicesState {
  switch (action.type) {
    case fetchDevices.pending:
      return {
        ...state,
        success: false,
        pending: true,
        error: null,
      };

    case fetchDevices.rejected:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.payload,
      };

    case fetchDevices.fulfilled:
      return {
        ...state,
        success: true,
        pending: false,
        error: null,
        data: action.payload.data || [],
        paginator: action.payload.paginator,
      };

    case removeDevice.default:
      return {
        ...state,
        selectedDeviceId: action.payload.id,
      };

    case removeDevice.pending:
      return {
        ...state,
        updating: true,
      };

    case removeDevice.rejected:
      return {
        ...state,
        updating: false,
      };

    case removeDevice.fulfilled:
      return {
        ...state,
        updating: false,
      };

    case logoutUser.fulfilled:
      return INITIAL_STATE;

    default:
      return state;
  }
}
