import AppNoMatch from "../views/NoMatch/AppNoMatch";
import ProfileRoutes from "app/views/Profile";
import Communications from "app/views/Communications";
import Security from "app/views/Security";
import Devices from "app/views/Devices";
import ActivitiesPage from "app/views/Activities";
import LimitsAndLevels from "app/views/LimitsAndLevels";
import DocumentsRoutes from "app/views/Documents";

interface AppRoute {
  path: string;
  component: React.ComponentType;
  exact: boolean;
}

const appRoutes: AppRoute[] = [
  {
    path: "/profile",
    component: ProfileRoutes,
    exact: false,
  },
  {
    path: "/communications",
    component: Communications,
    exact: false,
  },
  {
    path: "/security",
    component: Security,
    exact: false,
  },
  {
    path: "/devices",
    component: Devices,
    exact: false,
  },
  {
    path: "/activities",
    component: ActivitiesPage,
    exact: true,
  },
  {
    path: "/limits-and-levels",
    component: LimitsAndLevels,
    exact: false,
  },
  {
    path: "/documents",
    component: DocumentsRoutes,
    exact: false,
  },
  {
    path: "*",
    component: AppNoMatch,
    exact: true,
  },
];

export default appRoutes;
