export default [
  {
    flag: "https://restcountries.eu/data/afg.svg",
    name: "Afghanistan",
    alpha3Code: "AFG",
    callingCodes: ["93"],
    callingCode: "+93",
  },
  {
    flag: "https://restcountries.eu/data/ala.svg",
    name: "Åland Islands",
    alpha3Code: "ALA",
    callingCodes: ["358"],
    callingCode: "+358",
  },
  {
    flag: "https://restcountries.eu/data/alb.svg",
    name: "Albania",
    alpha3Code: "ALB",
    callingCodes: ["355"],
    callingCode: "+355",
  },
  {
    flag: "https://restcountries.eu/data/dza.svg",
    name: "Algeria",
    alpha3Code: "DZA",
    callingCodes: ["213"],
    callingCode: "+213",
  },
  {
    flag: "https://restcountries.eu/data/asm.svg",
    name: "American Samoa",
    alpha3Code: "ASM",
    callingCodes: ["1684"],
    callingCode: "+1684",
  },
  {
    flag: "https://restcountries.eu/data/and.svg",
    name: "Andorra",
    alpha3Code: "AND",
    callingCodes: ["376"],
    callingCode: "+376",
  },
  {
    flag: "https://restcountries.eu/data/ago.svg",
    name: "Angola",
    alpha3Code: "AGO",
    callingCodes: ["244"],
    callingCode: "+244",
  },
  {
    flag: "https://restcountries.eu/data/aia.svg",
    name: "Anguilla",
    alpha3Code: "AIA",
    callingCodes: ["1264"],
    callingCode: "+1264",
  },
  {
    flag: "https://restcountries.eu/data/ata.svg",
    name: "Antarctica",
    alpha3Code: "ATA",
    callingCodes: ["672"],
    callingCode: "+672",
  },
  {
    flag: "https://restcountries.eu/data/atg.svg",
    name: "Antigua and Barbuda",
    alpha3Code: "ATG",
    callingCodes: ["1268"],
    callingCode: "+1268",
  },
  {
    flag: "https://restcountries.eu/data/arg.svg",
    name: "Argentina",
    alpha3Code: "ARG",
    callingCodes: ["54"],
    callingCode: "+54",
  },
  {
    flag: "https://restcountries.eu/data/arm.svg",
    name: "Armenia",
    alpha3Code: "ARM",
    callingCodes: ["374"],
    callingCode: "+374",
  },
  {
    flag: "https://restcountries.eu/data/abw.svg",
    name: "Aruba",
    alpha3Code: "ABW",
    callingCodes: ["297"],
    callingCode: "+297",
  },
  {
    flag: "https://restcountries.eu/data/aus.svg",
    name: "Australia",
    alpha3Code: "AUS",
    callingCodes: ["61"],
    callingCode: "+61",
  },
  {
    flag: "https://restcountries.eu/data/aut.svg",
    name: "Austria",
    alpha3Code: "AUT",
    callingCodes: ["43"],
    callingCode: "+43",
  },
  {
    flag: "https://restcountries.eu/data/aze.svg",
    name: "Azerbaijan",
    alpha3Code: "AZE",
    callingCodes: ["994"],
    callingCode: "+994",
  },
  {
    flag: "https://restcountries.eu/data/bhs.svg",
    name: "Bahamas",
    alpha3Code: "BHS",
    callingCodes: ["1242"],
    callingCode: "+1242",
  },
  {
    flag: "https://restcountries.eu/data/bhr.svg",
    name: "Bahrain",
    alpha3Code: "BHR",
    callingCodes: ["973"],
    callingCode: "+973",
  },
  {
    flag: "https://restcountries.eu/data/bgd.svg",
    name: "Bangladesh",
    alpha3Code: "BGD",
    callingCodes: ["880"],
    callingCode: "+880",
  },
  {
    flag: "https://restcountries.eu/data/brb.svg",
    name: "Barbados",
    alpha3Code: "BRB",
    callingCodes: ["1246"],
    callingCode: "+1246",
  },
  {
    flag: "https://restcountries.eu/data/blr.svg",
    name: "Belarus",
    alpha3Code: "BLR",
    callingCodes: ["375"],
    callingCode: "+375",
  },
  {
    flag: "https://restcountries.eu/data/bel.svg",
    name: "Belgium",
    alpha3Code: "BEL",
    callingCodes: ["32"],
    callingCode: "+32",
  },
  {
    flag: "https://restcountries.eu/data/blz.svg",
    name: "Belize",
    alpha3Code: "BLZ",
    callingCodes: ["501"],
    callingCode: "+501",
  },
  {
    flag: "https://restcountries.eu/data/ben.svg",
    name: "Benin",
    alpha3Code: "BEN",
    callingCodes: ["229"],
    callingCode: "+229",
  },
  {
    flag: "https://restcountries.eu/data/bmu.svg",
    name: "Bermuda",
    alpha3Code: "BMU",
    callingCodes: ["1441"],
    callingCode: "+1441",
  },
  {
    flag: "https://restcountries.eu/data/btn.svg",
    name: "Bhutan",
    alpha3Code: "BTN",
    callingCodes: ["975"],
    callingCode: "+975",
  },
  {
    flag: "https://restcountries.eu/data/bol.svg",
    name: "Bolivia (Plurinational State of)",
    alpha3Code: "BOL",
    callingCodes: ["591"],
    callingCode: "+591",
  },
  {
    flag: "https://restcountries.eu/data/bes.svg",
    name: "Bonaire, Sint Eustatius and Saba",
    alpha3Code: "BES",
    callingCodes: ["5997"],
    callingCode: "+5997",
  },
  {
    flag: "https://restcountries.eu/data/bih.svg",
    name: "Bosnia and Herzegovina",
    alpha3Code: "BIH",
    callingCodes: ["387"],
    callingCode: "+387",
  },
  {
    flag: "https://restcountries.eu/data/bwa.svg",
    name: "Botswana",
    alpha3Code: "BWA",
    callingCodes: ["267"],
    callingCode: "+267",
  },
  {
    flag: "https://restcountries.eu/data/bvt.svg",
    name: "Bouvet Island",
    alpha3Code: "BVT",
    callingCodes: [""],
    callingCode: "+",
  },
  {
    flag: "https://restcountries.eu/data/bra.svg",
    name: "Brazil",
    alpha3Code: "BRA",
    callingCodes: ["55"],
    callingCode: "+55",
  },
  {
    flag: "https://restcountries.eu/data/iot.svg",
    name: "British Indian Ocean Territory",
    alpha3Code: "IOT",
    callingCodes: ["246"],
    callingCode: "+246",
  },
  {
    flag: "https://restcountries.eu/data/umi.svg",
    name: "United States Minor Outlying Islands",
    alpha3Code: "UMI",
    callingCodes: [""],
    callingCode: "+",
  },
  {
    flag: "https://restcountries.eu/data/vgb.svg",
    name: "Virgin Islands (British)",
    alpha3Code: "VGB",
    callingCodes: ["1284"],
    callingCode: "+1284",
  },
  {
    flag: "https://restcountries.eu/data/vir.svg",
    name: "Virgin Islands (U.S.)",
    alpha3Code: "VIR",
    callingCodes: ["1 340"],
    callingCode: "+1 340",
  },
  {
    flag: "https://restcountries.eu/data/brn.svg",
    name: "Brunei Darussalam",
    alpha3Code: "BRN",
    callingCodes: ["673"],
    callingCode: "+673",
  },
  {
    flag: "https://restcountries.eu/data/bgr.svg",
    name: "Bulgaria",
    alpha3Code: "BGR",
    callingCodes: ["359"],
    callingCode: "+359",
  },
  {
    flag: "https://restcountries.eu/data/bfa.svg",
    name: "Burkina Faso",
    alpha3Code: "BFA",
    callingCodes: ["226"],
    callingCode: "+226",
  },
  {
    flag: "https://restcountries.eu/data/bdi.svg",
    name: "Burundi",
    alpha3Code: "BDI",
    callingCodes: ["257"],
    callingCode: "+257",
  },
  {
    flag: "https://restcountries.eu/data/khm.svg",
    name: "Cambodia",
    alpha3Code: "KHM",
    callingCodes: ["855"],
    callingCode: "+855",
  },
  {
    flag: "https://restcountries.eu/data/cmr.svg",
    name: "Cameroon",
    alpha3Code: "CMR",
    callingCodes: ["237"],
    callingCode: "+237",
  },
  {
    flag: "https://restcountries.eu/data/can.svg",
    name: "Canada",
    alpha3Code: "CAN",
    callingCodes: ["1"],
    callingCode: "+1",
  },
  {
    flag: "https://restcountries.eu/data/cpv.svg",
    name: "Cabo Verde",
    alpha3Code: "CPV",
    callingCodes: ["238"],
    callingCode: "+238",
  },
  {
    flag: "https://restcountries.eu/data/cym.svg",
    name: "Cayman Islands",
    alpha3Code: "CYM",
    callingCodes: ["1345"],
    callingCode: "+1345",
  },
  {
    flag: "https://restcountries.eu/data/caf.svg",
    name: "Central African Republic",
    alpha3Code: "CAF",
    callingCodes: ["236"],
    callingCode: "+236",
  },
  {
    flag: "https://restcountries.eu/data/tcd.svg",
    name: "Chad",
    alpha3Code: "TCD",
    callingCodes: ["235"],
    callingCode: "+235",
  },
  {
    flag: "https://restcountries.eu/data/chl.svg",
    name: "Chile",
    alpha3Code: "CHL",
    callingCodes: ["56"],
    callingCode: "+56",
  },
  {
    flag: "https://restcountries.eu/data/chn.svg",
    name: "China",
    alpha3Code: "CHN",
    callingCodes: ["86"],
    callingCode: "+86",
  },
  {
    flag: "https://restcountries.eu/data/cxr.svg",
    name: "Christmas Island",
    alpha3Code: "CXR",
    callingCodes: ["61"],
    callingCode: "+61",
  },
  {
    flag: "https://restcountries.eu/data/cck.svg",
    name: "Cocos (Keeling) Islands",
    alpha3Code: "CCK",
    callingCodes: ["61"],
    callingCode: "+61",
  },
  {
    flag: "https://restcountries.eu/data/col.svg",
    name: "Colombia",
    alpha3Code: "COL",
    callingCodes: ["57"],
    callingCode: "+57",
  },
  {
    flag: "https://restcountries.eu/data/com.svg",
    name: "Comoros",
    alpha3Code: "COM",
    callingCodes: ["269"],
    callingCode: "+269",
  },
  {
    flag: "https://restcountries.eu/data/cog.svg",
    name: "Congo",
    alpha3Code: "COG",
    callingCodes: ["242"],
    callingCode: "+242",
  },
  {
    flag: "https://restcountries.eu/data/cod.svg",
    name: "Congo (Democratic Republic of the)",
    alpha3Code: "COD",
    callingCodes: ["243"],
    callingCode: "+243",
  },
  {
    flag: "https://restcountries.eu/data/cok.svg",
    name: "Cook Islands",
    alpha3Code: "COK",
    callingCodes: ["682"],
    callingCode: "+682",
  },
  {
    flag: "https://restcountries.eu/data/cri.svg",
    name: "Costa Rica",
    alpha3Code: "CRI",
    callingCodes: ["506"],
    callingCode: "+506",
  },
  {
    flag: "https://restcountries.eu/data/hrv.svg",
    name: "Croatia",
    alpha3Code: "HRV",
    callingCodes: ["385"],
    callingCode: "+385",
  },
  {
    flag: "https://restcountries.eu/data/cub.svg",
    name: "Cuba",
    alpha3Code: "CUB",
    callingCodes: ["53"],
    callingCode: "+53",
  },
  {
    flag: "https://restcountries.eu/data/cuw.svg",
    name: "Curaçao",
    alpha3Code: "CUW",
    callingCodes: ["599"],
    callingCode: "+599",
  },
  {
    flag: "https://restcountries.eu/data/cyp.svg",
    name: "Cyprus",
    alpha3Code: "CYP",
    callingCodes: ["357"],
    callingCode: "+357",
  },
  {
    flag: "https://restcountries.eu/data/cze.svg",
    name: "Czech Republic",
    alpha3Code: "CZE",
    callingCodes: ["420"],
    callingCode: "+420",
  },
  {
    flag: "https://restcountries.eu/data/dnk.svg",
    name: "Denmark",
    alpha3Code: "DNK",
    callingCodes: ["45"],
    callingCode: "+45",
  },
  {
    flag: "https://restcountries.eu/data/dji.svg",
    name: "Djibouti",
    alpha3Code: "DJI",
    callingCodes: ["253"],
    callingCode: "+253",
  },
  {
    flag: "https://restcountries.eu/data/dma.svg",
    name: "Dominica",
    alpha3Code: "DMA",
    callingCodes: ["1767"],
    callingCode: "+1767",
  },
  {
    flag: "https://restcountries.eu/data/dom.svg",
    name: "Dominican Republic",
    alpha3Code: "DOM",
    callingCodes: ["1809", "1829", "1849"],
    callingCode: "+1809",
  },
  {
    flag: "https://restcountries.eu/data/ecu.svg",
    name: "Ecuador",
    alpha3Code: "ECU",
    callingCodes: ["593"],
    callingCode: "+593",
  },
  {
    flag: "https://restcountries.eu/data/egy.svg",
    name: "Egypt",
    alpha3Code: "EGY",
    callingCodes: ["20"],
    callingCode: "+20",
  },
  {
    flag: "https://restcountries.eu/data/slv.svg",
    name: "El Salvador",
    alpha3Code: "SLV",
    callingCodes: ["503"],
    callingCode: "+503",
  },
  {
    flag: "https://restcountries.eu/data/gnq.svg",
    name: "Equatorial Guinea",
    alpha3Code: "GNQ",
    callingCodes: ["240"],
    callingCode: "+240",
  },
  {
    flag: "https://restcountries.eu/data/eri.svg",
    name: "Eritrea",
    alpha3Code: "ERI",
    callingCodes: ["291"],
    callingCode: "+291",
  },
  {
    flag: "https://restcountries.eu/data/est.svg",
    name: "Estonia",
    alpha3Code: "EST",
    callingCodes: ["372"],
    callingCode: "+372",
  },
  {
    flag: "https://restcountries.eu/data/eth.svg",
    name: "Ethiopia",
    alpha3Code: "ETH",
    callingCodes: ["251"],
    callingCode: "+251",
  },
  {
    flag: "https://restcountries.eu/data/flk.svg",
    name: "Falkland Islands (Malvinas)",
    alpha3Code: "FLK",
    callingCodes: ["500"],
    callingCode: "+500",
  },
  {
    flag: "https://restcountries.eu/data/fro.svg",
    name: "Faroe Islands",
    alpha3Code: "FRO",
    callingCodes: ["298"],
    callingCode: "+298",
  },
  {
    flag: "https://restcountries.eu/data/fji.svg",
    name: "Fiji",
    alpha3Code: "FJI",
    callingCodes: ["679"],
    callingCode: "+679",
  },
  {
    flag: "https://restcountries.eu/data/fin.svg",
    name: "Finland",
    alpha3Code: "FIN",
    callingCodes: ["358"],
    callingCode: "+358",
  },
  {
    flag: "https://restcountries.eu/data/fra.svg",
    name: "France",
    alpha3Code: "FRA",
    callingCodes: ["33"],
    callingCode: "+33",
  },
  {
    flag: "https://restcountries.eu/data/guf.svg",
    name: "French Guiana",
    alpha3Code: "GUF",
    callingCodes: ["594"],
    callingCode: "+594",
  },
  {
    flag: "https://restcountries.eu/data/pyf.svg",
    name: "French Polynesia",
    alpha3Code: "PYF",
    callingCodes: ["689"],
    callingCode: "+689",
  },
  {
    flag: "https://restcountries.eu/data/atf.svg",
    name: "French Southern Territories",
    alpha3Code: "ATF",
    callingCodes: [""],
    callingCode: "+",
  },
  {
    flag: "https://restcountries.eu/data/gab.svg",
    name: "Gabon",
    alpha3Code: "GAB",
    callingCodes: ["241"],
    callingCode: "+241",
  },
  {
    flag: "https://restcountries.eu/data/gmb.svg",
    name: "Gambia",
    alpha3Code: "GMB",
    callingCodes: ["220"],
    callingCode: "+220",
  },
  {
    flag: "https://restcountries.eu/data/geo.svg",
    name: "Georgia",
    alpha3Code: "GEO",
    callingCodes: ["995"],
    callingCode: "+995",
  },
  {
    flag: "https://restcountries.eu/data/deu.svg",
    name: "Germany",
    alpha3Code: "DEU",
    callingCodes: ["49"],
    callingCode: "+49",
  },
  {
    flag: "https://restcountries.eu/data/gha.svg",
    name: "Ghana",
    alpha3Code: "GHA",
    callingCodes: ["233"],
    callingCode: "+233",
  },
  {
    flag: "https://restcountries.eu/data/gib.svg",
    name: "Gibraltar",
    alpha3Code: "GIB",
    callingCodes: ["350"],
    callingCode: "+350",
  },
  {
    flag: "https://restcountries.eu/data/grc.svg",
    name: "Greece",
    alpha3Code: "GRC",
    callingCodes: ["30"],
    callingCode: "+30",
  },
  {
    flag: "https://restcountries.eu/data/grl.svg",
    name: "Greenland",
    alpha3Code: "GRL",
    callingCodes: ["299"],
    callingCode: "+299",
  },
  {
    flag: "https://restcountries.eu/data/grd.svg",
    name: "Grenada",
    alpha3Code: "GRD",
    callingCodes: ["1473"],
    callingCode: "+1473",
  },
  {
    flag: "https://restcountries.eu/data/glp.svg",
    name: "Guadeloupe",
    alpha3Code: "GLP",
    callingCodes: ["590"],
    callingCode: "+590",
  },
  {
    flag: "https://restcountries.eu/data/gum.svg",
    name: "Guam",
    alpha3Code: "GUM",
    callingCodes: ["1671"],
    callingCode: "+1671",
  },
  {
    flag: "https://restcountries.eu/data/gtm.svg",
    name: "Guatemala",
    alpha3Code: "GTM",
    callingCodes: ["502"],
    callingCode: "+502",
  },
  {
    flag: "https://restcountries.eu/data/ggy.svg",
    name: "Guernsey",
    alpha3Code: "GGY",
    callingCodes: ["44"],
    callingCode: "+44",
  },
  {
    flag: "https://restcountries.eu/data/gin.svg",
    name: "Guinea",
    alpha3Code: "GIN",
    callingCodes: ["224"],
    callingCode: "+224",
  },
  {
    flag: "https://restcountries.eu/data/gnb.svg",
    name: "Guinea-Bissau",
    alpha3Code: "GNB",
    callingCodes: ["245"],
    callingCode: "+245",
  },
  {
    flag: "https://restcountries.eu/data/guy.svg",
    name: "Guyana",
    alpha3Code: "GUY",
    callingCodes: ["592"],
    callingCode: "+592",
  },
  {
    flag: "https://restcountries.eu/data/hti.svg",
    name: "Haiti",
    alpha3Code: "HTI",
    callingCodes: ["509"],
    callingCode: "+509",
  },
  {
    flag: "https://restcountries.eu/data/hmd.svg",
    name: "Heard Island and McDonald Islands",
    alpha3Code: "HMD",
    callingCodes: [""],
    callingCode: "+",
  },
  {
    flag: "https://restcountries.eu/data/vat.svg",
    name: "Holy See",
    alpha3Code: "VAT",
    callingCodes: ["379"],
    callingCode: "+379",
  },
  {
    flag: "https://restcountries.eu/data/hnd.svg",
    name: "Honduras",
    alpha3Code: "HND",
    callingCodes: ["504"],
    callingCode: "+504",
  },
  {
    flag: "https://restcountries.eu/data/hkg.svg",
    name: "Hong Kong",
    alpha3Code: "HKG",
    callingCodes: ["852"],
    callingCode: "+852",
  },
  {
    flag: "https://restcountries.eu/data/hun.svg",
    name: "Hungary",
    alpha3Code: "HUN",
    callingCodes: ["36"],
    callingCode: "+36",
  },
  {
    flag: "https://restcountries.eu/data/isl.svg",
    name: "Iceland",
    alpha3Code: "ISL",
    callingCodes: ["354"],
    callingCode: "+354",
  },
  {
    flag: "https://restcountries.eu/data/ind.svg",
    name: "India",
    alpha3Code: "IND",
    callingCodes: ["91"],
    callingCode: "+91",
  },
  {
    flag: "https://restcountries.eu/data/idn.svg",
    name: "Indonesia",
    alpha3Code: "IDN",
    callingCodes: ["62"],
    callingCode: "+62",
  },
  {
    flag: "https://restcountries.eu/data/civ.svg",
    name: "Côte d'Ivoire",
    alpha3Code: "CIV",
    callingCodes: ["225"],
    callingCode: "+225",
  },
  {
    flag: "https://restcountries.eu/data/irn.svg",
    name: "Iran (Islamic Republic of)",
    alpha3Code: "IRN",
    callingCodes: ["98"],
    callingCode: "+98",
  },
  {
    flag: "https://restcountries.eu/data/irq.svg",
    name: "Iraq",
    alpha3Code: "IRQ",
    callingCodes: ["964"],
    callingCode: "+964",
  },
  {
    flag: "https://restcountries.eu/data/irl.svg",
    name: "Ireland",
    alpha3Code: "IRL",
    callingCodes: ["353"],
    callingCode: "+353",
  },
  {
    flag: "https://restcountries.eu/data/imn.svg",
    name: "Isle of Man",
    alpha3Code: "IMN",
    callingCodes: ["44"],
    callingCode: "+44",
  },
  {
    flag: "https://restcountries.eu/data/isr.svg",
    name: "Israel",
    alpha3Code: "ISR",
    callingCodes: ["972"],
    callingCode: "+972",
  },
  {
    flag: "https://restcountries.eu/data/ita.svg",
    name: "Italy",
    alpha3Code: "ITA",
    callingCodes: ["39"],
    callingCode: "+39",
  },
  {
    flag: "https://restcountries.eu/data/jam.svg",
    name: "Jamaica",
    alpha3Code: "JAM",
    callingCodes: ["1876"],
    callingCode: "+1876",
  },
  {
    flag: "https://restcountries.eu/data/jpn.svg",
    name: "Japan",
    alpha3Code: "JPN",
    callingCodes: ["81"],
    callingCode: "+81",
  },
  {
    flag: "https://restcountries.eu/data/jey.svg",
    name: "Jersey",
    alpha3Code: "JEY",
    callingCodes: ["44"],
    callingCode: "+44",
  },
  {
    flag: "https://restcountries.eu/data/jor.svg",
    name: "Jordan",
    alpha3Code: "JOR",
    callingCodes: ["962"],
    callingCode: "+962",
  },
  {
    flag: "https://restcountries.eu/data/kaz.svg",
    name: "Kazakhstan",
    alpha3Code: "KAZ",
    callingCodes: ["76", "77"],
    callingCode: "+76",
  },
  {
    flag: "https://restcountries.eu/data/ken.svg",
    name: "Kenya",
    alpha3Code: "KEN",
    callingCodes: ["254"],
    callingCode: "+254",
  },
  {
    flag: "https://restcountries.eu/data/kir.svg",
    name: "Kiribati",
    alpha3Code: "KIR",
    callingCodes: ["686"],
    callingCode: "+686",
  },
  {
    flag: "https://restcountries.eu/data/kwt.svg",
    name: "Kuwait",
    alpha3Code: "KWT",
    callingCodes: ["965"],
    callingCode: "+965",
  },
  {
    flag: "https://restcountries.eu/data/kgz.svg",
    name: "Kyrgyzstan",
    alpha3Code: "KGZ",
    callingCodes: ["996"],
    callingCode: "+996",
  },
  {
    flag: "https://restcountries.eu/data/lao.svg",
    name: "Lao People's Democratic Republic",
    alpha3Code: "LAO",
    callingCodes: ["856"],
    callingCode: "+856",
  },
  {
    flag: "https://restcountries.eu/data/lva.svg",
    name: "Latvia",
    alpha3Code: "LVA",
    callingCodes: ["371"],
    callingCode: "+371",
  },
  {
    flag: "https://restcountries.eu/data/lbn.svg",
    name: "Lebanon",
    alpha3Code: "LBN",
    callingCodes: ["961"],
    callingCode: "+961",
  },
  {
    flag: "https://restcountries.eu/data/lso.svg",
    name: "Lesotho",
    alpha3Code: "LSO",
    callingCodes: ["266"],
    callingCode: "+266",
  },
  {
    flag: "https://restcountries.eu/data/lbr.svg",
    name: "Liberia",
    alpha3Code: "LBR",
    callingCodes: ["231"],
    callingCode: "+231",
  },
  {
    flag: "https://restcountries.eu/data/lby.svg",
    name: "Libya",
    alpha3Code: "LBY",
    callingCodes: ["218"],
    callingCode: "+218",
  },
  {
    flag: "https://restcountries.eu/data/lie.svg",
    name: "Liechtenstein",
    alpha3Code: "LIE",
    callingCodes: ["423"],
    callingCode: "+423",
  },
  {
    flag: "https://restcountries.eu/data/ltu.svg",
    name: "Lithuania",
    alpha3Code: "LTU",
    callingCodes: ["370"],
    callingCode: "+370",
  },
  {
    flag: "https://restcountries.eu/data/lux.svg",
    name: "Luxembourg",
    alpha3Code: "LUX",
    callingCodes: ["352"],
    callingCode: "+352",
  },
  {
    flag: "https://restcountries.eu/data/mac.svg",
    name: "Macao",
    alpha3Code: "MAC",
    callingCodes: ["853"],
    callingCode: "+853",
  },
  {
    flag: "https://restcountries.eu/data/mkd.svg",
    name: "Macedonia (the former Yugoslav Republic of)",
    alpha3Code: "MKD",
    callingCodes: ["389"],
    callingCode: "+389",
  },
  {
    flag: "https://restcountries.eu/data/mdg.svg",
    name: "Madagascar",
    alpha3Code: "MDG",
    callingCodes: ["261"],
    callingCode: "+261",
  },
  {
    flag: "https://restcountries.eu/data/mwi.svg",
    name: "Malawi",
    alpha3Code: "MWI",
    callingCodes: ["265"],
    callingCode: "+265",
  },
  {
    flag: "https://restcountries.eu/data/mys.svg",
    name: "Malaysia",
    alpha3Code: "MYS",
    callingCodes: ["60"],
    callingCode: "+60",
  },
  {
    flag: "https://restcountries.eu/data/mdv.svg",
    name: "Maldives",
    alpha3Code: "MDV",
    callingCodes: ["960"],
    callingCode: "+960",
  },
  {
    flag: "https://restcountries.eu/data/mli.svg",
    name: "Mali",
    alpha3Code: "MLI",
    callingCodes: ["223"],
    callingCode: "+223",
  },
  {
    flag: "https://restcountries.eu/data/mlt.svg",
    name: "Malta",
    alpha3Code: "MLT",
    callingCodes: ["356"],
    callingCode: "+356",
  },
  {
    flag: "https://restcountries.eu/data/mhl.svg",
    name: "Marshall Islands",
    alpha3Code: "MHL",
    callingCodes: ["692"],
    callingCode: "+692",
  },
  {
    flag: "https://restcountries.eu/data/mtq.svg",
    name: "Martinique",
    alpha3Code: "MTQ",
    callingCodes: ["596"],
    callingCode: "+596",
  },
  {
    flag: "https://restcountries.eu/data/mrt.svg",
    name: "Mauritania",
    alpha3Code: "MRT",
    callingCodes: ["222"],
    callingCode: "+222",
  },
  {
    flag: "https://restcountries.eu/data/mus.svg",
    name: "Mauritius",
    alpha3Code: "MUS",
    callingCodes: ["230"],
    callingCode: "+230",
  },
  {
    flag: "https://restcountries.eu/data/myt.svg",
    name: "Mayotte",
    alpha3Code: "MYT",
    callingCodes: ["262"],
    callingCode: "+262",
  },
  {
    flag: "https://restcountries.eu/data/mex.svg",
    name: "Mexico",
    alpha3Code: "MEX",
    callingCodes: ["52"],
    callingCode: "+52",
  },
  {
    flag: "https://restcountries.eu/data/fsm.svg",
    name: "Micronesia (Federated States of)",
    alpha3Code: "FSM",
    callingCodes: ["691"],
    callingCode: "+691",
  },
  {
    flag: "https://restcountries.eu/data/mda.svg",
    name: "Moldova (Republic of)",
    alpha3Code: "MDA",
    callingCodes: ["373"],
    callingCode: "+373",
  },
  {
    flag: "https://restcountries.eu/data/mco.svg",
    name: "Monaco",
    alpha3Code: "MCO",
    callingCodes: ["377"],
    callingCode: "+377",
  },
  {
    flag: "https://restcountries.eu/data/mng.svg",
    name: "Mongolia",
    alpha3Code: "MNG",
    callingCodes: ["976"],
    callingCode: "+976",
  },
  {
    flag: "https://restcountries.eu/data/mne.svg",
    name: "Montenegro",
    alpha3Code: "MNE",
    callingCodes: ["382"],
    callingCode: "+382",
  },
  {
    flag: "https://restcountries.eu/data/msr.svg",
    name: "Montserrat",
    alpha3Code: "MSR",
    callingCodes: ["1664"],
    callingCode: "+1664",
  },
  {
    flag: "https://restcountries.eu/data/mar.svg",
    name: "Morocco",
    alpha3Code: "MAR",
    callingCodes: ["212"],
    callingCode: "+212",
  },
  {
    flag: "https://restcountries.eu/data/moz.svg",
    name: "Mozambique",
    alpha3Code: "MOZ",
    callingCodes: ["258"],
    callingCode: "+258",
  },
  {
    flag: "https://restcountries.eu/data/mmr.svg",
    name: "Myanmar",
    alpha3Code: "MMR",
    callingCodes: ["95"],
    callingCode: "+95",
  },
  {
    flag: "https://restcountries.eu/data/nam.svg",
    name: "Namibia",
    alpha3Code: "NAM",
    callingCodes: ["264"],
    callingCode: "+264",
  },
  {
    flag: "https://restcountries.eu/data/nru.svg",
    name: "Nauru",
    alpha3Code: "NRU",
    callingCodes: ["674"],
    callingCode: "+674",
  },
  {
    flag: "https://restcountries.eu/data/npl.svg",
    name: "Nepal",
    alpha3Code: "NPL",
    callingCodes: ["977"],
    callingCode: "+977",
  },
  {
    flag: "https://restcountries.eu/data/nld.svg",
    name: "Netherlands",
    alpha3Code: "NLD",
    callingCodes: ["31"],
    callingCode: "+31",
  },
  {
    flag: "https://restcountries.eu/data/ncl.svg",
    name: "New Caledonia",
    alpha3Code: "NCL",
    callingCodes: ["687"],
    callingCode: "+687",
  },
  {
    flag: "https://restcountries.eu/data/nzl.svg",
    name: "New Zealand",
    alpha3Code: "NZL",
    callingCodes: ["64"],
    callingCode: "+64",
  },
  {
    flag: "https://restcountries.eu/data/nic.svg",
    name: "Nicaragua",
    alpha3Code: "NIC",
    callingCodes: ["505"],
    callingCode: "+505",
  },
  {
    flag: "https://restcountries.eu/data/ner.svg",
    name: "Niger",
    alpha3Code: "NER",
    callingCodes: ["227"],
    callingCode: "+227",
  },
  {
    flag: "https://restcountries.eu/data/nga.svg",
    name: "Nigeria",
    alpha3Code: "NGA",
    callingCodes: ["234"],
    callingCode: "+234",
  },
  {
    flag: "https://restcountries.eu/data/niu.svg",
    name: "Niue",
    alpha3Code: "NIU",
    callingCodes: ["683"],
    callingCode: "+683",
  },
  {
    flag: "https://restcountries.eu/data/nfk.svg",
    name: "Norfolk Island",
    alpha3Code: "NFK",
    callingCodes: ["672"],
    callingCode: "+672",
  },
  {
    flag: "https://restcountries.eu/data/prk.svg",
    name: "Korea (Democratic People's Republic of)",
    alpha3Code: "PRK",
    callingCodes: ["850"],
    callingCode: "+850",
  },
  {
    flag: "https://restcountries.eu/data/mnp.svg",
    name: "Northern Mariana Islands",
    alpha3Code: "MNP",
    callingCodes: ["1670"],
    callingCode: "+1670",
  },
  {
    flag: "https://restcountries.eu/data/nor.svg",
    name: "Norway",
    alpha3Code: "NOR",
    callingCodes: ["47"],
    callingCode: "+47",
  },
  {
    flag: "https://restcountries.eu/data/omn.svg",
    name: "Oman",
    alpha3Code: "OMN",
    callingCodes: ["968"],
    callingCode: "+968",
  },
  {
    flag: "https://restcountries.eu/data/pak.svg",
    name: "Pakistan",
    alpha3Code: "PAK",
    callingCodes: ["92"],
    callingCode: "+92",
  },
  {
    flag: "https://restcountries.eu/data/plw.svg",
    name: "Palau",
    alpha3Code: "PLW",
    callingCodes: ["680"],
    callingCode: "+680",
  },
  {
    flag: "https://restcountries.eu/data/pse.svg",
    name: "Palestine, State of",
    alpha3Code: "PSE",
    callingCodes: ["970"],
    callingCode: "+970",
  },
  {
    flag: "https://restcountries.eu/data/pan.svg",
    name: "Panama",
    alpha3Code: "PAN",
    callingCodes: ["507"],
    callingCode: "+507",
  },
  {
    flag: "https://restcountries.eu/data/png.svg",
    name: "Papua New Guinea",
    alpha3Code: "PNG",
    callingCodes: ["675"],
    callingCode: "+675",
  },
  {
    flag: "https://restcountries.eu/data/pry.svg",
    name: "Paraguay",
    alpha3Code: "PRY",
    callingCodes: ["595"],
    callingCode: "+595",
  },
  {
    flag: "https://restcountries.eu/data/per.svg",
    name: "Peru",
    alpha3Code: "PER",
    callingCodes: ["51"],
    callingCode: "+51",
  },
  {
    flag: "https://restcountries.eu/data/phl.svg",
    name: "Philippines",
    alpha3Code: "PHL",
    callingCodes: ["63"],
    callingCode: "+63",
  },
  {
    flag: "https://restcountries.eu/data/pcn.svg",
    name: "Pitcairn",
    alpha3Code: "PCN",
    callingCodes: ["64"],
    callingCode: "+64",
  },
  {
    flag: "https://restcountries.eu/data/pol.svg",
    name: "Poland",
    alpha3Code: "POL",
    callingCodes: ["48"],
    callingCode: "+48",
  },
  {
    flag: "https://restcountries.eu/data/prt.svg",
    name: "Portugal",
    alpha3Code: "PRT",
    callingCodes: ["351"],
    callingCode: "+351",
  },
  {
    flag: "https://restcountries.eu/data/pri.svg",
    name: "Puerto Rico",
    alpha3Code: "PRI",
    callingCodes: ["1787", "1939"],
    callingCode: "+1787",
  },
  {
    flag: "https://restcountries.eu/data/qat.svg",
    name: "Qatar",
    alpha3Code: "QAT",
    callingCodes: ["974"],
    callingCode: "+974",
  },
  {
    flag: "https://restcountries.eu/data/kos.svg",
    name: "Republic of Kosovo",
    alpha3Code: "KOS",
    callingCodes: ["383"],
    callingCode: "+383",
  },
  {
    flag: "https://restcountries.eu/data/reu.svg",
    name: "Réunion",
    alpha3Code: "REU",
    callingCodes: ["262"],
    callingCode: "+262",
  },
  {
    flag: "https://restcountries.eu/data/rou.svg",
    name: "Romania",
    alpha3Code: "ROU",
    callingCodes: ["40"],
    callingCode: "+40",
  },
  {
    flag: "https://restcountries.eu/data/rus.svg",
    name: "Russian Federation",
    alpha3Code: "RUS",
    callingCodes: ["7"],
    callingCode: "+7",
  },
  {
    flag: "https://restcountries.eu/data/rwa.svg",
    name: "Rwanda",
    alpha3Code: "RWA",
    callingCodes: ["250"],
    callingCode: "+250",
  },
  {
    flag: "https://restcountries.eu/data/blm.svg",
    name: "Saint Barthélemy",
    alpha3Code: "BLM",
    callingCodes: ["590"],
    callingCode: "+590",
  },
  {
    flag: "https://restcountries.eu/data/shn.svg",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha3Code: "SHN",
    callingCodes: ["290"],
    callingCode: "+290",
  },
  {
    flag: "https://restcountries.eu/data/kna.svg",
    name: "Saint Kitts and Nevis",
    alpha3Code: "KNA",
    callingCodes: ["1869"],
    callingCode: "+1869",
  },
  {
    flag: "https://restcountries.eu/data/lca.svg",
    name: "Saint Lucia",
    alpha3Code: "LCA",
    callingCodes: ["1758"],
    callingCode: "+1758",
  },
  {
    flag: "https://restcountries.eu/data/maf.svg",
    name: "Saint Martin (French part)",
    alpha3Code: "MAF",
    callingCodes: ["590"],
    callingCode: "+590",
  },
  {
    flag: "https://restcountries.eu/data/spm.svg",
    name: "Saint Pierre and Miquelon",
    alpha3Code: "SPM",
    callingCodes: ["508"],
    callingCode: "+508",
  },
  {
    flag: "https://restcountries.eu/data/vct.svg",
    name: "Saint Vincent and the Grenadines",
    alpha3Code: "VCT",
    callingCodes: ["1784"],
    callingCode: "+1784",
  },
  {
    flag: "https://restcountries.eu/data/wsm.svg",
    name: "Samoa",
    alpha3Code: "WSM",
    callingCodes: ["685"],
    callingCode: "+685",
  },
  {
    flag: "https://restcountries.eu/data/smr.svg",
    name: "San Marino",
    alpha3Code: "SMR",
    callingCodes: ["378"],
    callingCode: "+378",
  },
  {
    flag: "https://restcountries.eu/data/stp.svg",
    name: "Sao Tome and Principe",
    alpha3Code: "STP",
    callingCodes: ["239"],
    callingCode: "+239",
  },
  {
    flag: "https://restcountries.eu/data/sau.svg",
    name: "Saudi Arabia",
    alpha3Code: "SAU",
    callingCodes: ["966"],
    callingCode: "+966",
  },
  {
    flag: "https://restcountries.eu/data/sen.svg",
    name: "Senegal",
    alpha3Code: "SEN",
    callingCodes: ["221"],
    callingCode: "+221",
  },
  {
    flag: "https://restcountries.eu/data/srb.svg",
    name: "Serbia",
    alpha3Code: "SRB",
    callingCodes: ["381"],
    callingCode: "+381",
  },
  {
    flag: "https://restcountries.eu/data/syc.svg",
    name: "Seychelles",
    alpha3Code: "SYC",
    callingCodes: ["248"],
    callingCode: "+248",
  },
  {
    flag: "https://restcountries.eu/data/sle.svg",
    name: "Sierra Leone",
    alpha3Code: "SLE",
    callingCodes: ["232"],
    callingCode: "+232",
  },
  {
    flag: "https://restcountries.eu/data/sgp.svg",
    name: "Singapore",
    alpha3Code: "SGP",
    callingCodes: ["65"],
    callingCode: "+65",
  },
  {
    flag: "https://restcountries.eu/data/sxm.svg",
    name: "Sint Maarten (Dutch part)",
    alpha3Code: "SXM",
    callingCodes: ["1721"],
    callingCode: "+1721",
  },
  {
    flag: "https://restcountries.eu/data/svk.svg",
    name: "Slovakia",
    alpha3Code: "SVK",
    callingCodes: ["421"],
    callingCode: "+421",
  },
  {
    flag: "https://restcountries.eu/data/svn.svg",
    name: "Slovenia",
    alpha3Code: "SVN",
    callingCodes: ["386"],
    callingCode: "+386",
  },
  {
    flag: "https://restcountries.eu/data/slb.svg",
    name: "Solomon Islands",
    alpha3Code: "SLB",
    callingCodes: ["677"],
    callingCode: "+677",
  },
  {
    flag: "https://restcountries.eu/data/som.svg",
    name: "Somalia",
    alpha3Code: "SOM",
    callingCodes: ["252"],
    callingCode: "+252",
  },
  {
    flag: "https://restcountries.eu/data/zaf.svg",
    name: "South Africa",
    alpha3Code: "ZAF",
    callingCodes: ["27"],
    callingCode: "+27",
  },
  {
    flag: "https://restcountries.eu/data/sgs.svg",
    name: "South Georgia and the South Sandwich Islands",
    alpha3Code: "SGS",
    callingCodes: ["500"],
    callingCode: "+500",
  },
  {
    flag: "https://restcountries.eu/data/kor.svg",
    name: "Korea (Republic of)",
    alpha3Code: "KOR",
    callingCodes: ["82"],
    callingCode: "+82",
  },
  {
    flag: "https://restcountries.eu/data/ssd.svg",
    name: "South Sudan",
    alpha3Code: "SSD",
    callingCodes: ["211"],
    callingCode: "+211",
  },
  {
    flag: "https://restcountries.eu/data/esp.svg",
    name: "Spain",
    alpha3Code: "ESP",
    callingCodes: ["34"],
    callingCode: "+34",
  },
  {
    flag: "https://restcountries.eu/data/lka.svg",
    name: "Sri Lanka",
    alpha3Code: "LKA",
    callingCodes: ["94"],
    callingCode: "+94",
  },
  {
    flag: "https://restcountries.eu/data/sdn.svg",
    name: "Sudan",
    alpha3Code: "SDN",
    callingCodes: ["249"],
    callingCode: "+249",
  },
  {
    flag: "https://restcountries.eu/data/sur.svg",
    name: "Suriname",
    alpha3Code: "SUR",
    callingCodes: ["597"],
    callingCode: "+597",
  },
  {
    flag: "https://restcountries.eu/data/sjm.svg",
    name: "Svalbard and Jan Mayen",
    alpha3Code: "SJM",
    callingCodes: ["4779"],
    callingCode: "+4779",
  },
  {
    flag: "https://restcountries.eu/data/swz.svg",
    name: "Swaziland",
    alpha3Code: "SWZ",
    callingCodes: ["268"],
    callingCode: "+268",
  },
  {
    flag: "https://restcountries.eu/data/swe.svg",
    name: "Sweden",
    alpha3Code: "SWE",
    callingCodes: ["46"],
    callingCode: "+46",
  },
  {
    flag: "https://restcountries.eu/data/che.svg",
    name: "Switzerland",
    alpha3Code: "CHE",
    callingCodes: ["41"],
    callingCode: "+41",
  },
  {
    flag: "https://restcountries.eu/data/syr.svg",
    name: "Syrian Arab Republic",
    alpha3Code: "SYR",
    callingCodes: ["963"],
    callingCode: "+963",
  },
  {
    flag: "https://restcountries.eu/data/twn.svg",
    name: "Taiwan",
    alpha3Code: "TWN",
    callingCodes: ["886"],
    callingCode: "+886",
  },
  {
    flag: "https://restcountries.eu/data/tjk.svg",
    name: "Tajikistan",
    alpha3Code: "TJK",
    callingCodes: ["992"],
    callingCode: "+992",
  },
  {
    flag: "https://restcountries.eu/data/tza.svg",
    name: "Tanzania, United Republic of",
    alpha3Code: "TZA",
    callingCodes: ["255"],
    callingCode: "+255",
  },
  {
    flag: "https://restcountries.eu/data/tha.svg",
    name: "Thailand",
    alpha3Code: "THA",
    callingCodes: ["66"],
    callingCode: "+66",
  },
  {
    flag: "https://restcountries.eu/data/tls.svg",
    name: "Timor-Leste",
    alpha3Code: "TLS",
    callingCodes: ["670"],
    callingCode: "+670",
  },
  {
    flag: "https://restcountries.eu/data/tgo.svg",
    name: "Togo",
    alpha3Code: "TGO",
    callingCodes: ["228"],
    callingCode: "+228",
  },
  {
    flag: "https://restcountries.eu/data/tkl.svg",
    name: "Tokelau",
    alpha3Code: "TKL",
    callingCodes: ["690"],
    callingCode: "+690",
  },
  {
    flag: "https://restcountries.eu/data/ton.svg",
    name: "Tonga",
    alpha3Code: "TON",
    callingCodes: ["676"],
    callingCode: "+676",
  },
  {
    flag: "https://restcountries.eu/data/tto.svg",
    name: "Trinidad and Tobago",
    alpha3Code: "TTO",
    callingCodes: ["1868"],
    callingCode: "+1868",
  },
  {
    flag: "https://restcountries.eu/data/tun.svg",
    name: "Tunisia",
    alpha3Code: "TUN",
    callingCodes: ["216"],
    callingCode: "+216",
  },
  {
    flag: "https://restcountries.eu/data/tur.svg",
    name: "Turkey",
    alpha3Code: "TUR",
    callingCodes: ["90"],
    callingCode: "+90",
  },
  {
    flag: "https://restcountries.eu/data/tkm.svg",
    name: "Turkmenistan",
    alpha3Code: "TKM",
    callingCodes: ["993"],
    callingCode: "+993",
  },
  {
    flag: "https://restcountries.eu/data/tca.svg",
    name: "Turks and Caicos Islands",
    alpha3Code: "TCA",
    callingCodes: ["1649"],
    callingCode: "+1649",
  },
  {
    flag: "https://restcountries.eu/data/tuv.svg",
    name: "Tuvalu",
    alpha3Code: "TUV",
    callingCodes: ["688"],
    callingCode: "+688",
  },
  {
    flag: "https://restcountries.eu/data/uga.svg",
    name: "Uganda",
    alpha3Code: "UGA",
    callingCodes: ["256"],
    callingCode: "+256",
  },
  {
    flag: "https://restcountries.eu/data/ukr.svg",
    name: "Ukraine",
    alpha3Code: "UKR",
    callingCodes: ["380"],
    callingCode: "+380",
  },
  {
    flag: "https://restcountries.eu/data/are.svg",
    name: "United Arab Emirates",
    alpha3Code: "ARE",
    callingCodes: ["971"],
    callingCode: "+971",
  },
  {
    flag: "https://restcountries.eu/data/gbr.svg",
    name: "United Kingdom of Great Britain and Northern Ireland",
    alpha3Code: "GBR",
    callingCodes: ["44"],
    callingCode: "+44",
  },
  {
    flag: "https://restcountries.eu/data/usa.svg",
    name: "United States of America",
    alpha3Code: "USA",
    callingCodes: ["1"],
    callingCode: "+1",
  },
  {
    flag: "https://restcountries.eu/data/ury.svg",
    name: "Uruguay",
    alpha3Code: "URY",
    callingCodes: ["598"],
    callingCode: "+598",
  },
  {
    flag: "https://restcountries.eu/data/uzb.svg",
    name: "Uzbekistan",
    alpha3Code: "UZB",
    callingCodes: ["998"],
    callingCode: "+998",
  },
  {
    flag: "https://restcountries.eu/data/vut.svg",
    name: "Vanuatu",
    alpha3Code: "VUT",
    callingCodes: ["678"],
    callingCode: "+678",
  },
  {
    flag: "https://restcountries.eu/data/ven.svg",
    name: "Venezuela (Bolivarian Republic of)",
    alpha3Code: "VEN",
    callingCodes: ["58"],
    callingCode: "+58",
  },
  {
    flag: "https://restcountries.eu/data/vnm.svg",
    name: "Viet Nam",
    alpha3Code: "VNM",
    callingCodes: ["84"],
    callingCode: "+84",
  },
  {
    flag: "https://restcountries.eu/data/wlf.svg",
    name: "Wallis and Futuna",
    alpha3Code: "WLF",
    callingCodes: ["681"],
    callingCode: "+681",
  },
  {
    flag: "https://restcountries.eu/data/esh.svg",
    name: "Western Sahara",
    alpha3Code: "ESH",
    callingCodes: ["212"],
    callingCode: "+212",
  },
  {
    flag: "https://restcountries.eu/data/yem.svg",
    name: "Yemen",
    alpha3Code: "YEM",
    callingCodes: ["967"],
    callingCode: "+967",
  },
  {
    flag: "https://restcountries.eu/data/zmb.svg",
    name: "Zambia",
    alpha3Code: "ZMB",
    callingCodes: ["260"],
    callingCode: "+260",
  },
  {
    flag: "https://restcountries.eu/data/zwe.svg",
    name: "Zimbabwe",
    alpha3Code: "ZWE",
    callingCodes: ["263"],
    callingCode: "+263",
  },
];
