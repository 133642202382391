import { AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/utils/parseError";
import toast from "app/utils/toast";
import {
  fetchNotifications,
  updateNotifications,
  UpdateNotificationsAction,
} from "./types";
import {
  FetchNotificationsResponse,
  UpdateNotificationsResponse,
} from "app/api/notifications/types";

function* fetchNotificationsSaga() {
  try {
    yield put({ type: fetchNotifications.pending });

    const res: AxiosResponse<FetchNotificationsResponse> = yield call(
      api.notificationsService.fetchNotifications
    );
    const data = res.data;
    yield put({ type: fetchNotifications.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: fetchNotifications.rejected, payload: errorMessage });
  }
}

function* updateNotificationsSaga(action: UpdateNotificationsAction) {
  try {
    const { payload } = action;
    yield put({ type: updateNotifications.pending });
    const res: AxiosResponse<UpdateNotificationsResponse> = yield call(
      api.notificationsService.updateNotifications,
      payload
    );
    const data = res.data;
    yield put({ type: updateNotifications.fulfilled, payload: data });
    yield put({ type: fetchNotifications.default });
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({ type: updateNotifications.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchNotifications.default, fetchNotificationsSaga),
    takeLatest(updateNotifications.default, updateNotificationsSaga),
  ]);
}
