import request from "../request";
import { GenericResponse } from "../types";
import {
  FinalizeSecurityServicePayload,
  FinalizeSecurityServiceResponse,
  SecurityServices,
  FetchSecurityResponse,
  ChangePasswordPayload,
  InitializeSecurityServicePayload,
  InitializeSecurityServiceResponse,
} from "./types";

const fetchSecurity = async () => {
  return await request.get<FetchSecurityResponse>(`/v3/me/security`);
};

const changePassword = async (payload: ChangePasswordPayload) => {
  return await request.put<GenericResponse>(
    `/v3/me/security/password`,
    payload
  );
};

const initializeSecurityService = async (
  service: SecurityServices,
  payload: InitializeSecurityServicePayload
) => {
  return await request.post<InitializeSecurityServiceResponse>(
    `/v3/me/security/${service}/initialize`,
    payload
  );
};

const finalizeSecurityService = async (
  service: SecurityServices,
  payload: FinalizeSecurityServicePayload
) => {
  return await request.post<FinalizeSecurityServiceResponse>(
    `/v3/me/security/${service}/finalize`,
    payload
  );
};

const deactivateTwoStepAuth = async () => {
  return await request.post<GenericResponse>(
    `/v3/me/security/two-step/deactivate`
  );
};

export default {
  fetchSecurity,
  changePassword,
  initializeSecurityService,
  finalizeSecurityService,
  deactivateTwoStepAuth,
};
