import styled from "styled-components";

import P from "app/styles/elements/P";

export const Title = styled.div`
  margin-bottom: 56px;
`;

export const InfoText = styled(P)`
  margin-top: 36px;
  display: flex;

  & > a {
    text-decoration: none;
    color: #18981d;
    font-size: 0.8rem;
  }
  & > button {
    border: none;
    outline: none;
    color: #18981d;
    font-size: 0.8rem;
    font-family: "Aribau-Grotesk";
  }
`;

export const EmailBlock = styled.div`
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -5px;

  & > * {
    margin-top: 5px;
  }

  & > button {
    border: none;
    outline: none;
    color: #18981d;
    font-size: 0.8rem;
    font-family: "Aribau-Grotesk";
  }
`;
