import styled from "styled-components";

export const Container = styled.div`
  margin-top: 8px;
  .title {
    margin-bottom: 24px;
    color: #7b8794;
  }
`;

type ItemProps = {
  valid?: boolean;
};
export const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  color: ${props => (props.valid ? "#18981D" : "#E12D39")};

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  & > svg {
    width: 12px;
    height: 12px;
  }

  & > p {
    margin-left: 8px;
  }
`;
