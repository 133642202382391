import {
  fetchSecurity,
  changePassword,
  initializeSecurityService,
  finalizeSecurityService,
  deactivateTwoStepAuth,
  cleanSecurityActionState,
} from "./types";
import {
  ChangePasswordPayload,
  InitializeSecurityServicePayload,
  SecurityServices,
  FinalizeSecurityServicePayload,
} from "app/api/security/types";

const fetchUserSecurity = () => {
  return { type: fetchSecurity.default };
};

const changeUserPassword = (payload: ChangePasswordPayload) => {
  return { type: changePassword.default, payload };
};

const initializeUserSecurityService = (
  service: SecurityServices,
  payload: InitializeSecurityServicePayload
) => {
  return { type: initializeSecurityService.default, payload, service };
};

const finalizeUserSecurityService = (
  service: SecurityServices,
  payload: FinalizeSecurityServicePayload
) => {
  return { type: finalizeSecurityService.default, payload, service };
};

const deactivateUserTwoStepAuth = () => {
  return { type: deactivateTwoStepAuth.default };
};

const cleanupSecurityActionState = () => {
  return { type: cleanSecurityActionState.default };
};

export {
  fetchUserSecurity,
  changeUserPassword,
  initializeUserSecurityService,
  finalizeUserSecurityService,
  deactivateUserTwoStepAuth,
  cleanupSecurityActionState,
};
