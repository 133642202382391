import styled, { css } from "styled-components";

export interface BadgeProps {
  status: "accepted" | "pending" | "rejected";
}
export const Badge = styled.div<BadgeProps>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 100px;
  & > svg {
    margin-right: 5px;
  }

  & > span {
    font-size: 0.6rem;
    text-transform: capitalize;
  }
  border: 2px solid #ffffff;

  ${({ status }) => {
    switch (status) {
      case "accepted":
        return css`
          background: #dfe3e8;
          color: #454f5b;
        `;
      case "pending":
        return css`
          background: #ffea8a;
          color: #595130;
        `;
      case "rejected":
        return css`
          background: #facdcd;
          color: #780a0a;
        `;
    }
  }};
`;
