import React from "react";
import { ItemsContainer } from "./styles";
import DeviceItem from "./DeviceItem";
import { PaginatorType } from "app/api/types";
import Pagination from "app/components/Pagination";
import { SessionType } from "../../../api/sessions/types";

interface Props {
  sessions: SessionType[];
  paginator: PaginatorType | null;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  handleSignoutSession: (id: string | number) => void;
  selectedSessionId?: any;
  updating: boolean;
}

const WebSessions: React.FC<Props> = ({
  sessions,
  paginator,
  handleNextPage,
  handlePreviousPage,
  handleSignoutSession,
  selectedSessionId,
  updating,
}) => {
  return (
    <ItemsContainer>
      {sessions.map((session, index) => (
        <DeviceItem
          key={index}
          {...session}
          loading={updating && session.id === selectedSessionId}
          onRemove={() => handleSignoutSession(session?.id || "")}
        />
      ))}
      {sessions.length > 0 && (
        <Pagination
          paginator={paginator}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      )}
    </ItemsContainer>
  );
};

export default WebSessions;
