import {
  fetchProfile,
  updateProfile,
  updateUsername,
  updateAvatar,
  resetProfileAction,
} from "./types";
import {
  UpdateProfilePayload,
  UpdateUsernamePayload,
  UpdateAvatarPayload,
} from "app/api/profile/types";

const fetchUserProfile = () => {
  return { type: fetchProfile.default };
};

const updateUserProfile = (payload: UpdateProfilePayload) => {
  return { type: updateProfile.default, payload };
};

const updateUserUsername = (payload: UpdateUsernamePayload) => {
  return { type: updateUsername.default, payload };
};

const updateUserAvatar = (payload: UpdateAvatarPayload) => {
  return { type: updateAvatar.default, payload };
};

const clearProfileActionState = () => {
  return { type: resetProfileAction.default };
};

export {
  fetchUserProfile,
  updateUserProfile,
  updateUserUsername,
  updateUserAvatar,
  clearProfileActionState,
};
