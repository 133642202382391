import styled from "styled-components";
import media from "app/styles/utils/media";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(211, 213, 216, 0.5);
  padding: 20px 0px;

  /* & > *:not(:last-child) {
    margin-right: 86px;
  } */

  & > button {
    font-size: 0.7rem;
  }
  ${media.mobile`
    align-items: flex-start;
  `};

  & > .info {
    flex-basis: 66.6667%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${media.mobile`
      flex-wrap: wrap;
    `};
  }
`;

export const ItemName = styled.div`
  text-align: left;

  & > p {
    font-size: 0.8rem;
  }
`;

export const ItemDate = styled.div`
  color: #3e4c59;
  font-size: 0.7rem;
  text-align: left;
  ${media.mobile`
      margin-top: 11px;
  `};
`;

export const ItemLocation = styled.div`
  color: #3e4c59;
  text-align: left;

  & > p {
    font-size: 0.8rem;
  }

  .ip-address {
    color: #7b8794;
    font-size: 0.7rem;
    margin-top: 8px;
  }
`;
