import React, { useState, useRef } from "react";
import { useFormik } from "formik";

import ReCAPTCHA from "react-google-recaptcha";
import { Form } from "./styles";
import Input from "app/components/Input";
import Button from "app/components/Button";
import { Title, EmailBlock, RecaptchaBlock } from "../styles";
import H2 from "app/styles/elements/H2";
import P from "app/styles/elements/P";
import { SignupPayload } from "app/api/auth/types";
import PasswordConditions from "./PasswordConditions";
import { passwordConditions } from "app/utils/regex";

interface Props {
  onChangeUsername: () => void;
  onSubmitForm: (password: string, token: string | null) => void;
  clearError: () => void;
  formState: SignupPayload;
  submitting?: boolean;
  error?: any;
}

type PasswordFormType = {
  password: string;
};

const PasswordForm: React.FC<Props> = (props) => {
  const { onChangeUsername, onSubmitForm, submitting, formState } = props;

  const recaptchaRef = useRef(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const passwordIsValid = (password: string) => {
    return (
      passwordConditions.minLengthRegex.test(password) &&
      passwordConditions.minOneUpperCaseRegex.test(password) &&
      passwordConditions.minOneSpecialRegex.test(password) &&
      passwordConditions.minOneNumberRegex.test(password)
    );
  };

  const validate = (values: PasswordFormType) => {
    const errors: any = {};

    if (!values.password) {
      errors.password = "Password is required";
    } else if (!passwordIsValid(values.password)) {
      errors.password = "Password is not strong enough";
    }
    return errors;
  };
  const form = useFormik<PasswordFormType>({
    initialValues: {
      password: "",
    },
    onSubmit: (values) => {
      if (!recaptchaToken) return;
      onSubmitForm(values.password, recaptchaToken);
      //@ts-ignore
      recaptchaRef.current.reset();
    },
    validate,
    validateOnChange: true,
  });

  const onInputFocus = (name: string) => () => {
    form.setFieldError(name, undefined);
    props.clearError();
  };

  const onRecapthaTokenChange = (token: any) => {
    setRecaptchaToken(token);
  };

  return (
    <>
      <Title>
        <H2>Personal Information</H2>
        <P small>
          Please make sure the details are correct as they will be used to
          verify your identity.
        </P>
      </Title>
      <EmailBlock>
        <P small>{formState.username}</P>
        <button onClick={onChangeUsername}>Change username</button>
      </EmailBlock>
      <Form onSubmit={form.handleSubmit}>
        <Input
          fullwidth
          placeholder="Enter your password"
          label="Password"
          type="password"
          name="password"
          id="password"
          value={form.values.password}
          onChange={form.handleChange}
          autoFocus
          error={!!form.errors.password || !!props.error}
          errorMessage={form.errors.password || props?.error || ""}
          onFocus={onInputFocus("password")}
        />
        <PasswordConditions
          password={form.values.password}
          show={form.values.password.length !== 0}
        />
        <RecaptchaBlock>
          <ReCAPTCHA
            sitekey={process?.env?.REACT_APP_RECAPTHA_SITE_KEY || ""}
            onChange={onRecapthaTokenChange}
            ref={recaptchaRef}
          />
        </RecaptchaBlock>
        <Button
          type="submit"
          className="submit-button"
          text="Next"
          variant="green"
          loading={submitting}
        />
      </Form>
    </>
  );
};

export default PasswordForm;
