import React from "react";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";

import PrevPageLink from "app/components/PrevPageLink";
import PageHeader from "app/styles/blocks/PageHeader";
import BVNPage from "./BVN";
import BVNEnablePage from "./BVNEnable";

interface LocationState {
  securityPageAction?: boolean;
}

const BVNRoutes: React.FC = () => {
  const match = useRouteMatch();
  const location = useLocation<LocationState>();

  return (
    <div className="page-content">
      <PrevPageLink
        to={
          location.state?.securityPageAction
            ? "/security"
            : "/limits-and-levels"
        }
        text={
          location.state?.securityPageAction ? "Security" : "Limits and levels"
        }
      />
      <PageHeader.Main>
        <PageHeader.Title big>Verify BVN</PageHeader.Title>
      </PageHeader.Main>
      <Switch>
        <Route path={`${match.path}`} component={BVNPage} exact />

        <Route path={`${match.path}/enable`} component={BVNEnablePage} exact />
      </Switch>
    </div>
  );
};

export default BVNRoutes;
