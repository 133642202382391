import React from "react";
import { format } from "date-fns";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { Section, SectionAction, SectionInfo } from "./styles";
import P from "app/styles/elements/P";
import Button from "app/styles/elements/Button";
import { RootState } from "app/redux/reducers";

const PasswordSection: React.FC = (props) => {
  const match = useRouteMatch();
  const { data } = useSelector((state: RootState) => state.security);

  const datePasswordLastChanged = data?.password_last_changed
    ? format(new Date(data?.password_last_changed), "MMMM dd, yyyy")
    : "";

  return (
    <Section>
      <SectionInfo>
        <P medium semiBold>
          Password
        </P>
        <P small className="sub-title">
          {datePasswordLastChanged && `Last changed ${datePasswordLastChanged}`}
        </P>
      </SectionInfo>
      <SectionAction>
        <Button as={Link} to={`${match.path}/password`} variant="outline">
          Change password
        </Button>
      </SectionAction>
    </Section>
  );
};

export default PasswordSection;
