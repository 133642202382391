import React from "react";

import PageHeader from "app/styles/blocks/PageHeader";
import DocumentsSection from "./components/DocumentsSection";
import DocumentRequestsSection from "./components/DocumentRequestsSection";

const Documents: React.FC = (props) => {
  return (
    <div className="page-content">
      <PageHeader.Main>
        <PageHeader.Title big>Documents</PageHeader.Title>
        <PageHeader.Description small>
          Upload important documents and review past documents
        </PageHeader.Description>
      </PageHeader.Main>
      <DocumentsSection />
      <DocumentRequestsSection />
    </div>
  );
};

export default Documents;
