import createActionType from "../util/createActionType";
import { PaginatorType, GenericResponse } from "app/api/types";
import { ActivityType } from "app/api/activities/types";
import { GenericParamsPayload } from "../../api/types";

export interface ActivitiesState {
  data: ActivityType[];
  paginator: PaginatorType | null;
  pending: boolean;
  error: GenericResponse extends string
    ? string | null
    : GenericResponse | null;
  success: boolean;
}

export const fetchActivities = createActionType("fetch_activities");

export interface FetchActivitiesAction {
  type: string;
  meta: GenericParamsPayload;
}
