import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PrevPageLink from "app/components/PrevPageLink";
import PageHeader from "app/styles/blocks/PageHeader";
import PhoneNumberForm from "./components/PhoneNumberForm";
import { Container, Info } from "./styles";
import P from "app/styles/elements/P";
import {
  initializeUserSecurityService,
  cleanupSecurityActionState,
} from "app/redux/security/actions";
import { RootState } from "app/redux/reducers";
import { InitializeSecurityServicePayload } from "app/api/security/types";

const ChangePhoneNumberPage: React.FC = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { action } = useSelector((state: RootState) => state.security);
  const [formData, setFormData] = React.useState<
    InitializeSecurityServicePayload
  >({
    phone: "",
    country_call_code: "",
  });

  const handleSubmit = (values: InitializeSecurityServicePayload) => {
    setFormData(values);
    dispatch(initializeUserSecurityService("phone", values));
  };

  React.useEffect(() => {
    dispatch(cleanupSecurityActionState());
  }, [dispatch]);

  React.useEffect(() => {
    if (action.success && action.initialized) {
      const { phone, country_call_code } = formData;
      history.push({
        pathname: `${match.path}/confirm`,
        state: {
          phone,
          country_call_code,
        },
      });
    }
  }, [action, formData, history, match.path]);

  return (
    <div className="page-content">
      <PrevPageLink to="/security" text="Security" />
      <PageHeader.Main>
        <PageHeader.Title big>Add phone number</PageHeader.Title>
      </PageHeader.Main>
      <Container>
        <Info>
          <P medium semiBold>
            Let’s set up your phone
          </P>
          <P small className="description">
            Enter the phone number of the mobile device you’d like to associate
            with your Busha account
          </P>
        </Info>
        <PhoneNumberForm loading={action.pending} handleSubmit={handleSubmit} />
      </Container>
    </div>
  );
};

export default ChangePhoneNumberPage;
