import createActionType from "../util/createActionType";
import {
  InitializeSecurityServiceResponse,
  FetchSecurityResponse,
  InitializeSecurityServicePayload,
  SecurityServices,
  FinalizeSecurityServicePayload,
  ChangePasswordPayload,
} from "app/api/security/types";

export interface SecurityState {
  data: FetchSecurityResponse | null;
  action: {
    data: InitializeSecurityServiceResponse | null;
    pending: boolean;
    error: string | null;
    success: boolean;
    initialized: boolean;
    finalized: boolean;
  };
  pending: boolean;
  error: string | null;
  success: boolean;
}

export const fetchSecurity = createActionType("fetch_security");

export const initializeSecurityService = createActionType(
  "intialize_security_service"
);

export const finalizeSecurityService = createActionType(
  "finalize_security_service"
);

export const deactivateTwoStepAuth = createActionType(
  "deactivate_two_step_auth"
);

export const changePassword = createActionType("change_password");

export const cleanSecurityActionState = createActionType(
  "clean_security_action_state"
);

export interface InitializeSecurityServiceAction {
  type: string;
  payload: InitializeSecurityServicePayload;
  service: SecurityServices;
}

export interface FinalizeSecurityServiceAction {
  type: string;
  payload: FinalizeSecurityServicePayload;
  service: SecurityServices;
}

export interface ChangePasswordAction {
  type: string;
  payload: ChangePasswordPayload;
}
