import { fetchApplications, removeApplication } from "./types";

const fetchUserApplications = (page: number | string = 1) => {
  return { type: fetchApplications.default, meta: { page } };
};

const removeUserApplication = (id: number | string) => {
  return { type: removeApplication.default, payload: { id } };
};

export { fetchUserApplications, removeUserApplication };
