import { TokensState, setTokens } from "./types";
import { logoutUser } from "app/redux/auth/types";
import storage from "app/utils/storage";

const cachedTokens = storage.get("tokens");

const INITIAL_STATE: TokensState = {
  ...cachedTokens,
};

export default function user(state = INITIAL_STATE, action: any): TokensState {
  switch (action.type) {
    case setTokens.fulfilled:
      return {
        ...state,
        ...action.payload,
      };
    case logoutUser.fulfilled:
      return {};
    default:
      return state;
  }
}
