import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "app/styles/blocks/PageHeader";
import MobileSection from "./components/MobileSection";
import WebSessionSection from "./components/WebSessionSection";
import ConfirmedDevicesSection from "./components/ConfirmedDevicesSection";
import { fetchUserApplications } from "app/redux/applications/actions";
import { RootState } from "app/redux/reducers";

const DevicesPage: React.FC = (props) => {
  const dispatch = useDispatch();

  const { paginator } = useSelector((state: RootState) => state.applications);

  useEffect(() => {
    dispatch(fetchUserApplications());
  }, [dispatch]);

  return (
    <div className="page-content">
      <PageHeader.Main>
        <PageHeader.Title big>Your devices</PageHeader.Title>
        <PageHeader.Description small>
          You’re currently signed in to your Busha Account on these devices
        </PageHeader.Description>
      </PageHeader.Main>
      <MobileSection totalDevices={paginator?.total_entries_size || 0} />
      <WebSessionSection />
      <ConfirmedDevicesSection />
    </div>
  );
};

export default DevicesPage;
