import React from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

import { Form } from "./styles";
import Input from "app/components/Input";
import Button from "app/components/Button";
import { ValidateEmailPayload } from "app/api/auth/types";
import { emailIsValid } from "app/utils/regex";
import { Title, InfoText } from "../styles";
import H2 from "app/styles/elements/H2";
import Checkbox from "app/components/Checkbox";
import api from "app/api";

interface Props {
  onEmailVerified: (email: string, hasRefCode?: boolean) => void;
  loading?: boolean;
}

const EmailForm: React.FC<Props> = (props) => {
  const validate = async (values: ValidateEmailPayload) => {
    const errors: any = {};

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailIsValid(values.email)) {
      errors.email = "Email is not valid";
    }

    return errors;
  };
  const form = useFormik<ValidateEmailPayload>({
    initialValues: {
      email: "",
      hasRef: false,
    },
    onSubmit: async (values) => {
      try {
        await api.authService.validateCredential({
          name: "email",
          value: values.email,
        });
        // the request returing a response means that the email is already in use
        form.errors.email = "Email already exists";
      } catch (error) {
        // the request failing means that the email is free
        props.onEmailVerified(values.email, values.hasRef);
      }
    },
    validate,
    validateOnChange: true,
  });

  const onInputFocus = (name: string) => () =>
    form.setFieldError(name, undefined);

  return (
    <>
      <Title>
        <H2>Create your account</H2>
      </Title>
      <Form onSubmit={form.handleSubmit}>
        <Input
          fullwidth
          placeholder="Email address"
          label="Email"
          name="email"
          id="email"
          autoFocus
          value={form.values.email}
          onChange={form.handleChange}
          error={!!form.errors.email && form.touched.email}
          errorMessage={form.touched.email ? form.errors.email : undefined}
          onFocus={onInputFocus("email")}
        />
        <Checkbox
          containerClassname="form-group"
          label="I have a referral code"
          name="hasRef"
          checked={form.values.hasRef}
          onChange={form.handleChange}
        />
        <Button
          type="submit"
          className="submit-button"
          text="Next"
          variant="green"
          disabled={form.isSubmitting}
        />
      </Form>
      <InfoText small>
        Have an account?&nbsp;<Link to="/login">Log in</Link>
      </InfoText>
    </>
  );
};

export default EmailForm;
