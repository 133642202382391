import styled from "styled-components";

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 330px;
  color: #616e7c;
  font-size: 0.8rem;
`;
