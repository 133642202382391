import React, { useState, useEffect, useCallback } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";
import { ReactComponent as Logo } from "app/assets/img/logo.svg";
import AuthLayout from "app/styles/blocks/AuthLayout";
import { Title, InfoText, EmailBlock } from "./styles";
import H2 from "app/styles/elements/H2";
import EmailForm from "./components/EmailForm";
import LoginForm from "./components/LoginForm";
import P from "app/styles/elements/P";
import TwoFactorForm from "./components/TwoFactorForm";
import { RootState } from "app/redux/reducers";
import {
  login,
  cleanupLoginState,
  cleanupLoginErrors,
  confirmLogin,
  resendUserLoginCode,
  cleanupResendLoginCodeState,
} from "app/redux/auth/actions";
import { isUrl } from "app/utils/regex";

const Login: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    login: LoginState,
    resendLoginCode: ResendLoginCodeState,
  } = useSelector((state: RootState) => state.auth);

  const [email, setEmail] = useState<string>("");
  const onEmailVerified = (email: string) => setEmail(email);

  let continueFromApp: any;
  if (location.search) {
    const queryValues = queryString.parse(location.search);
    continueFromApp = queryValues.redirect;
  }

  const clearEmail = () => {
    dispatch(cleanupLoginState());
    setEmail("");
  };

  const onSubmitForm = (
    password: string,
    remember_me: boolean,
    token: string | null
  ) => {
    dispatch(login({ email, password, remember_me, token, continueFromApp }));
  };

  const onSubmitCode = useCallback(
    (code: string) => {
      dispatch(
        confirmLogin({ code, token: LoginState.data?.confirmation_token || "" })
      );
    },
    [LoginState, dispatch]
  );

  const onResendCodeCode = useCallback(() => {
    const token = LoginState.data?.confirmation_token || "";
    dispatch(resendUserLoginCode(token));
  }, [LoginState, dispatch]);

  const cleanLoginErrors = () => dispatch(cleanupLoginErrors());

  useEffect(
    useCallback(() => {
      // if user has successfully logged in, send them to the app where they came from or their profile page
      if (LoginState.success && LoginState.data?.status === "success") {
        if (
          (LoginState.data?.redirect_uri ||
            (continueFromApp && isUrl(continueFromApp))) &&
          LoginState.data?.access_token
        ) {
          let redirectUrl = `${
            LoginState.data?.redirect_uri || continueFromApp
          }/oauth/${LoginState.data.access_token}`;

          if (LoginState?.data?.refresh_token) {
            redirectUrl = `${redirectUrl}?refreshToken=${LoginState?.data?.refresh_token}`;
          }

          window.location.replace(redirectUrl);
        } else {
          history.push("/profile");
        }
      }

      if (LoginState.error?.status === "unverified_user_confirmation") {
        history.push({
          pathname: "/signup/success",
          state: { email, continueFromApp },
        });
      }
    }, [LoginState, continueFromApp, email, history]),
    [LoginState.success, LoginState.error]
  );

  // clean up login state on page load
  useEffect(() => {
    dispatch(cleanupLoginState());
    dispatch(cleanupResendLoginCodeState());
  }, [dispatch]);

  return (
    <AuthLayout.Main>
      <AuthLayout.Body>
        <AuthLayout.Header>
          <Link to="/login">
            <Logo className="logo" />
          </Link>
        </AuthLayout.Header>
        <Title>
          <H2>
            {LoginState.data?.status === "two_step"
              ? "Two step authentication"
              : "Log in"}
          </H2>
        </Title>
        {!(LoginState.data?.status === "two_step") ? (
          <>
            {!!email && (
              <EmailBlock>
                <P small>{email}</P>
                <button onClick={clearEmail}>Change email</button>
              </EmailBlock>
            )}
            {!email ? (
              <EmailForm onEmailVerified={onEmailVerified} />
            ) : (
              <LoginForm
                onSubmitForm={onSubmitForm}
                email={email}
                loading={LoginState.pending}
                error={LoginState.error?.message}
                clearError={cleanLoginErrors}
              />
            )}
            <InfoText small>
              {!email ? (
                <>
                  New to Busha?&nbsp;<Link to="/signup">Get Started</Link>
                </>
              ) : (
                <Link to="/forgot-password">Forgot password?</Link>
              )}
            </InfoText>
          </>
        ) : (
          <TwoFactorForm
            onSubmitCode={onSubmitCode}
            loading={LoginState.pending}
            resendCode={LoginState.data?.resend}
            onResendCode={onResendCodeCode}
            resendingCode={ResendLoginCodeState.pending}
            message={LoginState.data?.message}
          />
        )}
      </AuthLayout.Body>
    </AuthLayout.Main>
  );
};

export default Login;
