import React from "react";
import { FetchDocumentTypeResponse } from "app/api/documents/types";
import { UploadDocumentPayload } from "app/api/documents/types";

interface StepRequirement {
  formValue: string;
  type: "document" | "id";
  name?: string;
  requirement?: string;
  sample?: string;
  warning?: string;
}

interface StepRequirements {
  [key: string]: StepRequirement;
}

interface State {
  currentStep: number;
  stepsRequirements: StepRequirements;
  submittedData: UploadDocumentPayload;
  completed: boolean;
}

interface Props extends State {
  onSubmitData: (field: string, value: string) => void;
}

export default function useUploadSteps(
  docType: FetchDocumentTypeResponse | null
): Props {
  const [state, setState] = React.useState<State>({
    currentStep: 0,
    stepsRequirements: {},
    submittedData: {},
    completed: false,
  });

  const onStateUpdate = (key: string, value: any) => {
    setState((previousState) => ({ ...previousState, [key]: value }));
  };

  const onSubmitData = (field: string, value: string) => {
    onStateUpdate("submittedData", { ...state.submittedData, [field]: value });
    onStateUpdate("currentStep", state.currentStep + 1);
  };

  React.useEffect(() => {
    let STEP = 0;
    let _stepsRequirements = {};

    if (docType?.id_number_required) {
      _stepsRequirements = {
        ..._stepsRequirements,
        [STEP]: { formValue: "id_number", type: "id" },
      };
      STEP += 1;
    }

    _stepsRequirements = {
      ..._stepsRequirements,
      [STEP]: {
        formValue: "file",
        type: "document",
        requirement: docType?.requirement,
        sample: docType?.sample,
        warning: docType?.warning,
      },
    };
    STEP += 1;

    if (docType?.extra && docType?.extra.length > 0) {
      const { extra } = docType;
      for (let _extra of extra) {
        let formValue = "";
        if (_extra?.name?.toLowerCase().startsWith("selfie")) {
          formValue = "with_selfie";
        }
        if (_extra?.name?.toLowerCase().endsWith("busha verification text")) {
          formValue = "with_written_text";
        }
        _stepsRequirements = {
          ..._stepsRequirements,
          [STEP]: {
            formValue,
            type: "document",
            name: _extra?.name,
            requirement: _extra?.requirement,
            sample: docType?.sample,
            warning: docType?.warning,
          },
        };
        STEP += 1;
      }
    }
    onStateUpdate("stepsRequirements", _stepsRequirements);
  }, [docType]);

  React.useEffect(() => {
    const steps = Object.keys(state.stepsRequirements).length;
    if (state.currentStep >= steps && steps !== 0) {
      onStateUpdate("completed", true);
    }
  }, [state.currentStep, state.stepsRequirements]);

  return {
    ...state,
    onSubmitData,
  };
}
