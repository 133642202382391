import request from "../request";
import {
  FetchNotificationsResponse,
  UpdateNotificationsPayload,
  UpdateNotificationsResponse,
} from "./types";

const fetchNotifications = async () => {
  return await request.get<FetchNotificationsResponse>(`/v3/me/notifications`);
};

const updateNotifications = async (payload: UpdateNotificationsPayload) => {
  return await request.put<UpdateNotificationsResponse>(
    `/v3/me/notifications`,
    payload
  );
};

export default { fetchNotifications, updateNotifications };
