import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import PrevPageLink from "app/components/PrevPageLink";
import PageHeader from "app/styles/blocks/PageHeader";
import AuthAppPage from "./AuthApp";
import EnableAuthAppPage from "./EnableAuthApp";

const AuthApp: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div className="page-content">
      <PrevPageLink to="/security" text="Security" />
      <PageHeader.Main>
        <PageHeader.Title big>Enable authenticator app</PageHeader.Title>
      </PageHeader.Main>
      <Switch>
        <Route path={`${match.path}`} component={AuthAppPage} exact />

        <Route
          path={`${match.path}/enable`}
          component={EnableAuthAppPage}
          exact
        />
      </Switch>
    </div>
  );
};

export default AuthApp;
