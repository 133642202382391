import styled from "styled-components";

const PaginationBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  button {
    width: 36px;
    height: 36px;
    background: #fff;
    border: 1px solid #cbd2d9;
    box-sizing: border-box;
    margin: 0;
    svg {
      path {
        fill: #000;
      }
    }
    &:disabled {
      opacity: 0.8;
      svg {
        path {
          fill: #cbd2d9;
        }
      }
    }
    &:focus {
      outline: none;
      border: 2px solid #18981d;
    }
  }
  .button-left {
    border-radius: 3px 0px 0px 3px;
    border-right: none;
  }
  .divider {
    height: 36px;
    width: 1px;
    background: #cbd2d9;
  }
  .button-right {
    border-radius: 0px 3px 3px 0px;
    border-left: none;
  }
`;

export { PaginationBlock };
