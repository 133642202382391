import React from "react";
import { ItemsContainer } from "./styles";
import DeviceItem from "./DeviceItem";
import { ApplicationType } from "app/api/applications/types";
import { PaginatorType } from "app/api/types";
import Pagination from "app/components/Pagination";

interface Props {
  applications: ApplicationType[];
  paginator: PaginatorType | null;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  handleRemoveApplication: (id: string | number) => void;
  selectedApplicationId?: any;
  updating: boolean;
}

const MobileApps: React.FC<Props> = ({
  applications,
  paginator,
  handleNextPage,
  handlePreviousPage,
  handleRemoveApplication,
  selectedApplicationId,
  updating,
}) => {
  return (
    <ItemsContainer>
      {applications.map((application, index) => (
        <DeviceItem
          key={index}
          {...application}
          loading={updating && application.id === selectedApplicationId}
          onRemove={() => handleRemoveApplication(application?.id || "")}
        />
      ))}
      {applications.length > 0 && (
        <Pagination
          paginator={paginator}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      )}
    </ItemsContainer>
  );
};

export default MobileApps;
