import axios, { AxiosError } from "axios";
import store from "../redux";
import api from "app/api";
import { asyncWrap } from "app/utils/helpers";
import { setTokens } from "../redux/tokens/types";
import { logoutUser } from "../redux/auth/types";

const request = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
});

request.interceptors.request.use((config) => {
  const {
    tokens: { access_token },
  } = store.getState();

  const requiresNoToken = config.headers["noToken"];

  const newConfig = { ...config };
  delete newConfig.headers["noToken"];

  if (!access_token || requiresNoToken) return newConfig;

  newConfig.headers = {
    ...newConfig.headers,
    Authorization: `Bearer ${access_token}`,
  };

  return newConfig;
});

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  async function (error: AxiosError) {
    const {
      tokens: { access_token, refresh_token },
    } = store.getState();

    console.error({ error });

    // if user's token has expired or has been blacklisted
    if (error.response?.status === 401 && access_token) {
      // if a refresh token exists, try and refresh the user token
      if (refresh_token) {
        const [, response] = await asyncWrap(
          api.authService.refreshAccessToken(refresh_token)
        );

        if (response?.access_token) {
          store.dispatch({ type: setTokens.default, payload: response });

          // retry the failed request
          error.config.headers = {
            ...error.config.headers,
            Authorization: `Bearer ${response.access_token}`,
          };

          const [newError, newResponse] = await asyncWrap(
            axios.request(error.config)
          );

          if (newResponse) return newResponse;

          if (newError) return Promise.reject(error);
        } else {
          store.dispatch({ type: logoutUser.default });
        }
      } else {
        store.dispatch({ type: logoutUser.default });
      }
    }
    return Promise.reject(error);
  }
);

export default request;
