import styled from "styled-components";

import errorIcon from "app/assets/img/icons/error-info.svg";

const ErrorMsg = styled.p`
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: #e12d39;
  font-size: 0.8rem;

  &::before {
    content: "";
    display: block;
    height: 20px;
    width: 20px;
    background: url(${errorIcon}) no-repeat;
    background-size: contain;
    margin-right: 4px;
  }
`;

export default ErrorMsg;
