import React, { useCallback, useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Container, FileDropZone, ProgressBar, UploadBlock } from "./styles";
import P from "app/styles/elements/P";
import { ReactComponent as UploadImage } from "app/assets/img/upload.svg";
import { ReactComponent as PDFImage } from "app/assets/img/pdf.svg";
import Button from "app/components/Button";
import { fileIsImage } from "./utils";
import toast from "app/utils/toast";
import { getFileSizeInKB } from "app/utils/helpers";
import api from "app/api";

interface Props {
  onFileUploaded: (url: string) => void;
  onShowReviewChange: (show: boolean) => void;
  requirement?: string;
  warning?: string;
  sample?: string;
}

const DocUploadForm: React.FC<Props> = ({
  onFileUploaded,
  onShowReviewChange,
  requirement,
  warning,
  sample,
}) => {
  const previewImgRef = useRef<HTMLImageElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadProgress, setUploadProgress] = useState("0");

  const renderRequirement = (_requirement: string | undefined) => {
    const formatedRequirement = _requirement
      ?.replace(/\\{2}n/g, "{br}")
      ?.replace(/\\"/g, "");

    const paragraphs = formatedRequirement?.split("{br}");

    const node = paragraphs?.map((paragraph, index) => (
      <React.Fragment key={index}>
        {index > 0 ? <b>{paragraph}</b> : paragraph}
        <br />
      </React.Fragment>
    ));

    return node;
  };

  const showImagePreview = (image: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (previewImgRef.current && e.target) {
        previewImgRef.current.src = e.target.result as any;
      }
    };
    reader.readAsDataURL(image);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const MAX_FILE_SIZE = 5000;

    // Do something with the files
    if (!acceptedFiles.length) return;
    const file = acceptedFiles[0];

    const fileSize = getFileSizeInKB(file.size);

    if (fileSize > MAX_FILE_SIZE) {
      toast(`“${file.name}” is too large. Try a file size less than 5 MB.`);
    } else {
      setSelectedFile(file);
      if (fileIsImage(file)) {
        showImagePreview(file);
      }
    }
  }, []);

  const updateFileUploadProgress = (event: ProgressEvent) => {
    const percentComplete = String((event.loaded / event.total) * 100);
    setUploadProgress(percentComplete);
  };

  const onFileUploadComplete = (url: string) => () => {
    setUploadingFile(false);
    onFileUploaded(url);
    setUploadProgress("0");
    setSelectedFile(undefined);
  };

  const onFileUploadFailed = () => {
    setUploadingFile(false);
    toast("Error occurred while uploading file");
    if (!!selectedFile && fileIsImage(selectedFile)) {
      showImagePreview(selectedFile);
    }
  };

  const onUploadFile = async () => {
    if (selectedFile) {
      setUploadingFile(true);

      const res = await api.amazonS3.getPresignedLink({
        filename: selectedFile.name,
        category: "document",
      });

      const request = new XMLHttpRequest();
      // upload progress event
      request.upload.addEventListener("progress", updateFileUploadProgress);
      // upload completed
      request.addEventListener(
        "load",
        onFileUploadComplete(res.data.public_link)
      );
      // upload error
      request.addEventListener("error", onFileUploadFailed);
      // send POST request
      request.open("put", res.data.presigned_url);
      request.setRequestHeader("Content-Type", "multipart/form-data");
      request.send(selectedFile);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  useEffect(() => {
    if (selectedFile) {
      onShowReviewChange(true);
    } else {
      onShowReviewChange(false);
    }
  }, [onShowReviewChange, selectedFile]);

  return (
    <Container>
      {selectedFile && (
        <>
          <P medium semiBold className="requirement">
            Check photo
          </P>
          <P small className="warning">
            Is all the writing easy to read and are all four corners visible?
          </P>
        </>
      )}
      {!selectedFile && (
        <>
          <P small className="requirement">
            {renderRequirement(requirement)}
          </P>
          <P small className="warning">
            {warning}{" "}
            {!!sample?.trim() && (
              <a href={sample} target="_blank" rel="noopener noreferrer">
                View Sample
              </a>
            )}
          </P>
        </>
      )}
      <FileDropZone {...getRootProps()} active={isDragActive}>
        <input {...getInputProps()} />
        {!selectedFile && (
          <>
            <UploadImage />
            <Button variant="outline" text="Choose file" type="button" />
            <P little className="help-text">
              or drop files to upload
            </P>
          </>
        )}
        {!uploadingFile && selectedFile && (
          <>
            {fileIsImage(selectedFile) ? (
              <img alt="file preview" className="preview" ref={previewImgRef} />
            ) : (
              <PDFImage className="preview" />
            )}
          </>
        )}
        {selectedFile && uploadingFile && (
          <UploadBlock>
            <P small className="label">
              Uploading...
            </P>
            <ProgressBar value={uploadProgress}>
              <div />
            </ProgressBar>
          </UploadBlock>
        )}
      </FileDropZone>
      {selectedFile && (
        <Button
          variant="green"
          text="Yes, continue"
          onClick={onUploadFile}
          loading={uploadingFile}
        />
      )}
    </Container>
  );
};

export default DocUploadForm;
