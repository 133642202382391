import styled from "styled-components";

export const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 330px;
  color: #616e7c;
  font-size: 0.8rem;
  text-align: center;
  & > * {
    margin: 5px;
  }
`;
