import { AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/utils/parseError";
import {
  fetchDocuments,
  fetchDocumentTypes,
  fetchDocumentType,
  FetchDocumentTypeAction,
  uploadDocument,
  UploadDocumentAction,
} from "./types";
import {
  FetchDocumentsResponse,
  FetchDocumentTypeResponse,
  FetchDocumentTypesResponse,
} from "app/api/documents/types";
import { GenericResponse } from "app/api/types";
import toast from "app/utils/toast";

function* fetchDocumentsSaga() {
  try {
    yield put({ type: fetchDocuments.pending });

    const res: AxiosResponse<FetchDocumentsResponse> = yield call(
      api.documentsService.fetchDocuments
    );
    const data = res.data;
    yield put({ type: fetchDocuments.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: fetchDocuments.rejected, payload: errorMessage });
  }
}
function* fetchDocumentTypesSaga() {
  try {
    yield put({ type: fetchDocumentTypes.pending });

    const res: AxiosResponse<FetchDocumentTypesResponse> = yield call(
      api.documentsService.fetchDocumentTypes
    );
    const data = res.data;
    yield put({ type: fetchDocumentTypes.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: fetchDocumentTypes.rejected, payload: errorMessage });
  }
}

function* fetchDocumentTypeSaga(action: FetchDocumentTypeAction) {
  try {
    yield put({ type: fetchDocumentType.pending });

    const { payload } = action;

    const res: AxiosResponse<FetchDocumentTypeResponse> = yield call(
      api.documentsService.fetchDocumentType,
      payload
    );
    const data = res.data;
    yield put({ type: fetchDocumentType.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: fetchDocumentType.rejected, payload: errorMessage });
  }
}

function* UploadDocumentSaga(action: UploadDocumentAction) {
  try {
    yield put({ type: uploadDocument.pending });

    const { payload } = action;

    const res: AxiosResponse<GenericResponse> = yield call(
      api.documentsService.uploadDocument,
      payload
    );
    const data = res.data;
    toast(
      "Your document was successfully updated. Expect a reply from us in 24 hours.",
      {
        variant: "success",
      }
    );
    yield put({ type: uploadDocument.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    toast(errorMessage, { variant: "error" });
    yield put({ type: uploadDocument.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchDocuments.default, fetchDocumentsSaga),
    takeLatest(fetchDocumentTypes.default, fetchDocumentTypesSaga),
    takeLatest(fetchDocumentType.default, fetchDocumentTypeSaga),
    takeLatest(uploadDocument.default, UploadDocumentSaga),
  ]);
}
