import styled from "styled-components";

import media from "app/styles/utils/media";
import { SectionAction as SSectionAction } from "../components/styles";

export const SectionAction = styled(SSectionAction)`
  a {
    width: auto;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${media.mobile`
      margin-top: 24px;
    `};
  }
  button {
    width: auto;
    height: 40px;
    font-size: 0.8rem;

    ${media.mobile`
      margin-top: 24px;
    `};
  }
`;

export const AuthTypeBox = styled.div`
  display: flex;
  align-items: center;

  ${media.mobile`
    flex-wrap: wrap;
    & > * {
      flex: 100%;
    }
  `};
`;
export const AuthTypeImg = styled.div`
  margin-right: 21px;
  & > svg {
    height: 48px;
  }
  ${media.mobile`
      margin-bottom: 16px;
    `};
`;
export const AuthTypeInfo = styled.div`
  width: 480px;
  max-width: 100%;
  text-align: left;

  ${media.mobile`
    width: auto;
  `};

  & > .description {
    margin-top: 8px;
    color: #3e4c59;
  }
`;
