import styled from "styled-components";

export const Container = styled.div`
  padding-top: 25px;

  .help-text {
    color: #616e7c;
    margin-top: 16px;
  }

  & > form {
    width: 380px;
    max-width: 100%;

    & > .form-group {
      margin-bottom: 16px;
    }

    & > button {
      width: 320px;
      max-width: 100%;
      margin-top: 37px;
    }
  }
`;

export const Info = styled.div`
  & > .description {
    margin-top: 10px;
    color: #3e4c59;
    width: 478px;
    max-width: 100%;
  }
  margin-bottom: 24px;
`;

export const PhoneInputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;

  border: 1px solid #cbd2d9;
  height: 54px;
  border-radius: 5px;

  &:focus-within {
    border: 2px solid #18981d;
  }

  & > input {
    flex: 1;
    border: none;
    height: 100%;

    &:focus {
      border: none;
    }
  }

  & > .prefix {
    padding: 16px 17px;
    background: #f5f7fa;
    border-radius: 4px 0px 0px 4px;
  }
`;

export const PhoneContainer = styled.div`
  width: 478px;
  max-width: 100%;
  padding: 12px 0px;

  & > .link {
    margin-top: 37px;
    display: inline-flex;
    /* width: 129px; */
  }
`;

export const Section = styled.div`
  padding-top: 13px;
  text-align: left;

  & > .description {
    margin-top: 10px;
    color: #3e4c59;
  }
  & .description.bold {
    font-weight: bold;
  }
`;

export const InputBox = styled.div`
  padding-top: 32px;

  width: 380px;
  max-width: 100%;

  & > .help-text {
    margin-top: 16px;
    color: #616e7c;
  }
`;

export const CodeInputBox = styled.div`
  margin: 42px 0 11px;
`;
