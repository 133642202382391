import React from "react";
import queryString from "query-string";
import Lottie from "lottie-web";
import useAuth from "app/hooks/useAuth";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import api from "app/api";
import { asyncWrap } from "app/utils/helpers";
import decodeJWT from "app/utils/decodeJWT";
import { logoutUser } from "../redux/auth/types";
import { setAppTokens } from "app/redux/tokens/actions";

import WarningAnimation from "app/assets/animations/warning.json";

import LoaderBox from "./LoaderBox";
import AuthLayout from "app/styles/blocks/AuthLayout";
import H2 from "app/styles/elements/H2";
import P from "app/styles/elements/P";
import { SuccessPageBody } from "app/views/Signup/styles";

interface Props {}

export const MobileAppContext = React.createContext({
  isWebView: false,
});

const MobileAppAuth: React.FC<Props> = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useAuth();

  const animationRef = React.useRef<HTMLDivElement>(null);

  const [loading, setLoading] = React.useState(true);
  const [mobileAuth, setMobileAuth] = React.useState(false);

  React.useEffect(
    React.useCallback(() => {
      async function validateMobileAuth() {
        setLoading(true);
        const queryValues = queryString.parse(location.search);
        const mobileToken = queryValues.mobile_token;
        if (mobileToken) {
          setMobileAuth(true);
          dispatch({ type: logoutUser.fulfilled });
          const { jti: jwtIdentifier } = decodeJWT(String(mobileToken));

          if (jwtIdentifier) {
            const [, response] = await asyncWrap(
              api.authService.validateCredential({
                name: "token_valid",
                value: jwtIdentifier,
              })
            );

            if (response) {
              dispatch(setAppTokens({ access_token: String(mobileToken) }));
            }
          }
        }
        setLoading(false);
        if (animationRef.current) {
          Lottie.loadAnimation({
            container: animationRef.current,
            renderer: "svg",
            loop: false,
            autoplay: true,
            animationData: WarningAnimation,
          }).setSpeed(2);
        }
      }

      validateMobileAuth();
    }, [dispatch, location.search]),
    []
  );

  if (loading) return <LoaderBox />;

  if (!mobileAuth)
    return (
      <MobileAppContext.Provider value={{ isWebView: mobileAuth }}>
        {props.children}
      </MobileAppContext.Provider>
    );

  return !isLoggedIn && mobileAuth ? (
    <AuthLayout.Main>
      <SuccessPageBody>
        <div className="icon" ref={animationRef} />
        <H2 medium semiBold className="title">
          An error occurred
        </H2>
        <P small className="description">
          Please close this web page, and reopen it from the app to continue
        </P>
      </SuccessPageBody>
    </AuthLayout.Main>
  ) : (
    <MobileAppContext.Provider value={{ isWebView: mobileAuth }}>
      {props.children}
    </MobileAppContext.Provider>
  );
};

export default MobileAppAuth;
