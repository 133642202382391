import styled from "styled-components";

const Avatar = styled.div`
  background: rgba(154, 165, 177, 0.3);
  height: 36px;
  width: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > p {
    font-weight: 500;
    font-size: 0.9rem;
  }

  img {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    object-fit: cover;
  }
`;

export default Avatar;
