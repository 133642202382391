import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import ProfileVerfication from "./Profile";
import BVNRoutes from "./BVN";
import PhoneRoutes from "./Phone";
import { RootState } from "app/redux/reducers";

const routes = [
  {
    param: "profile",
    component: ProfileVerfication,
    exact: true,
  },
  {
    param: "bvn",
    component: BVNRoutes,
    exact: false,
  },
  {
    param: "phone",
    component: PhoneRoutes,
    exact: false,
  },
];

export const IndicatorContext = React.createContext({
  currentStep: 1,
  setCurrentStep: (step: number) => {},
  goToNextStep: (currentStep: number) => {},
  totalSteps: 1,
});

const STEPS = {
  "/profile": 1,
  "/phone": 2,
  "/bvn": 3,
};

const getStep = (pathname: string) => {
  const step = Object.keys(STEPS).find((_step) => pathname.includes(_step));
  return step ? STEPS[step] : 1;
};

const getStepRoute = (step: number) => {
  const currentStep = Object.entries(STEPS).find(([, value]) => value === step);
  if (currentStep) {
    const [route] = currentStep;
    return route;
  }
  return currentStep;
};

const TOTAL_STEPS = 3;

const LevelOneVerificationRoutes: React.FC = (props) => {
  const match = useRouteMatch();
  const history = useHistory();

  const { data: userData } = useSelector((state: RootState) => state.profile);
  const { data: securityData } = useSelector(
    (state: RootState) => state.security
  );

  const [indicatorStep, setIndicatorStep] = useState(
    getStep(history.location.pathname)
  );

  const setCurrentStep = useCallback((step: number) => {
    setIndicatorStep(step);
  }, []);

  const goToNextStep = (currentStep: number) => {
    const nextStep = currentStep + 1;

    if (nextStep > TOTAL_STEPS) {
      history.push("/limits-and-levels");
    } else {
      const route = getStepRoute(nextStep);
      if (route) {
        if (
          (route === "/profile" && securityData?.complete_profile) ||
          (route === "/phone" && !!userData?.phone) ||
          (route === "/bvn" && !!userData?.bvn)
        ) {
          goToNextStep(nextStep);
        }
        history.push(`${match.url}${route}`);
      } else {
        history.push("/limits-and-levels");
      }
    }
  };

  React.useEffect(() => {
    const step = getStep(history.location.pathname);
    if (step !== indicatorStep) {
      setCurrentStep(step);
    }
  }, [history.location.pathname, indicatorStep, setCurrentStep]);

  return (
    <IndicatorContext.Provider
      value={{
        currentStep: indicatorStep,
        setCurrentStep,
        goToNextStep,
        totalSteps: TOTAL_STEPS,
      }}
    >
      <Switch>
        <Redirect path={match.url} to={`${match.url}/profile`} exact />
        {routes.map((route) => (
          <Route
            key={route.param}
            path={`${match.url}/${route.param}`}
            component={route.component}
            exact={route.exact}
          />
        ))}
      </Switch>
    </IndicatorContext.Provider>
  );
};

export default LevelOneVerificationRoutes;
