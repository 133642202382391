import styled from "styled-components";

export const Form = styled.form`
  width: 100%;

  .submit-button {
    margin-top: 32px;
  }

  .form-group:not(:first-child) {
    margin-top: 16px;
  }
`;
