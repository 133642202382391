import React from "react";
import { ActivitiesContainer } from "./styles";
import ActivityItem from "./ActivityItem";
import { ActivityType } from "app/api/activities/types";
import { PaginatorType } from "app/api/types";
import Pagination from "app/components/Pagination";

interface Props {
  activities: ActivityType[];
  paginator: PaginatorType | null;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
}

const Activities: React.FC<Props> = ({
  activities,
  paginator,
  handleNextPage,
  handlePreviousPage,
}) => {
  return (
    <ActivitiesContainer>
      {activities.map((activity, index) => (
        <ActivityItem key={index} {...activity} />
      ))}
      {activities.length > 0 && (
        <Pagination
          paginator={paginator}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      )}
    </ActivitiesContainer>
  );
};

export default Activities;
