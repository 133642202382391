import React, { useState, useRef } from "react";
import { useFormik } from "formik";

import ReCAPTCHA from "react-google-recaptcha";
import { Form, RecaptchaBlock } from "./styles";
import { emailIsValid } from "app/utils/regex";
import Input from "app/components/Input";
import Button from "app/components/Button";
import { ForgotPasswordPayload } from "app/api/auth/types";

interface Props {
  onSubmitForm: (password: string, token: string | null) => void;
  loading?: boolean;
  error?: string | null;
}

const ForgotPasswordForm: React.FC<Props> = (props) => {
  const recaptchaRef = useRef(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const validate = (values: ForgotPasswordPayload) => {
    const errors: any = {};

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailIsValid(values.email)) {
      errors.email = "Email is not valid";
    }
    return errors;
  };
  const form = useFormik<ForgotPasswordPayload>({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      if (!recaptchaToken) return;
      props.onSubmitForm(values.email, recaptchaToken);
      //@ts-ignore
      recaptchaRef.current.reset();
    },
    validate,
    validateOnChange: false,
  });

  const onInputFocus = (name: string) => () =>
    form.setFieldError(name, undefined);

  const onRecapthaTokenChange = (token: any) => {
    setRecaptchaToken(token);
  };

  return (
    <Form onSubmit={form.handleSubmit}>
      <Input
        fullwidth
        placeholder="Enter your email address"
        label="Email"
        name="email"
        id="email"
        type="email"
        autoFocus
        value={form.values.email}
        onChange={form.handleChange}
        error={!!form.errors.email || !!props.error}
        errorMessage={form.errors?.email || props?.error || ""}
        onFocus={onInputFocus("email")}
      />
      <RecaptchaBlock>
        <ReCAPTCHA
          sitekey={process?.env?.REACT_APP_RECAPTHA_SITE_KEY || ""}
          onChange={onRecapthaTokenChange}
          ref={recaptchaRef}
        />
      </RecaptchaBlock>
      <Button
        type="submit"
        className="submit-button"
        text="Next"
        variant="green"
        loading={props.loading}
      />
    </Form>
  );
};

export default ForgotPasswordForm;
