import { logoutUser } from "../auth/types";
import { ActivitiesState, fetchActivities } from "./types";

const INITIAL_STATE: ActivitiesState = {
  pending: false,
  error: null,
  success: false,
  paginator: null,
  data: [],
};

export default function auth(
  state = INITIAL_STATE,
  action: any
): ActivitiesState {
  switch (action.type) {
    case fetchActivities.pending:
      return {
        ...state,
        success: false,
        pending: true,
        error: null,
      };

    case fetchActivities.rejected:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.payload,
      };

    case fetchActivities.fulfilled:
      return {
        ...state,
        success: true,
        pending: false,
        error: null,
        data: action.payload.data,
        paginator: action.payload.paginator,
      };

    case logoutUser.fulfilled:
      return INITIAL_STATE;

    default:
      return state;
  }
}
