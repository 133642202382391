import {
  fetchDocuments,
  fetchDocumentTypes,
  fetchDocumentType,
  uploadDocument,
  resetDocumentActionState,
} from "./types";
import { UploadDocumentPayload } from "app/api/documents/types";

const fetchUserDocuments = () => {
  return { type: fetchDocuments.default };
};

const fetchUserDocumentsTypes = () => {
  return { type: fetchDocumentTypes.default };
};

const fetchUserDocumentType = (id: string) => {
  return { type: fetchDocumentType.default, payload: { id } };
};

const createUserDocument = (payload: UploadDocumentPayload) => {
  return { type: uploadDocument.default, payload };
};

const clearDocumentAction = () => {
  return { type: resetDocumentActionState.default };
};

export {
  fetchUserDocuments,
  fetchUserDocumentsTypes,
  fetchUserDocumentType,
  uploadDocument,
  createUserDocument,
  clearDocumentAction,
};
