import React from "react";
import { toast as _toast, ToastId } from "react-toastify";
import Notification from "app/components/Notification";
import { NotificationComponentProps } from "../components/Notification/index";

function toast(
  message: string,
  { variant = "info", ...rest }: NotificationComponentProps = {}
): ToastId {
  return _toast(<Notification variant={variant} {...rest} message={message} />);
}

export default toast;
