import React from "react";
import { PaginationBlock } from "./styles";
import { PaginatorType } from "app/api/types";
import { ReactComponent as LeftIcon } from "app/assets/img/icons/left-icon.svg";
import { ReactComponent as RightIcon } from "app/assets/img/icons/right-icon.svg";

interface Props {
  paginator: PaginatorType | null;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
}

const Pagination: React.FC<Props> = ({
  paginator,
  handleNextPage,
  handlePreviousPage,
}) => {
  return (
    <PaginationBlock>
      <button
        className="button-left"
        onClick={handlePreviousPage}
        disabled={!((paginator?.page || 0) > 1)}
      >
        <LeftIcon />
      </button>
      <div className="divider" />
      <button
        className="button-right"
        onClick={handleNextPage}
        disabled={!((paginator?.page || 0) < (paginator?.total_pages || 0))}
      >
        <RightIcon />
      </button>
    </PaginationBlock>
  );
};

export default Pagination;
