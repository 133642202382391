import request from "../request";
import store from "app/redux";

import {
  ValidatePayload,
  LoginPayload,
  LoginConfirmPayload,
  SignupPayload,
  ResendVerificationEmailPayload,
  ResetPasswordPayload,
  VerifyEmailAddressPayload,
  ResendLoginCodePayload,
  ForgotPasswordPayload,
  LoginResponse,
  ValidateResponse,
  VerifyEmailAddressResponse,
  SignupResponse,
  ResendVerificationEmailResponse,
  ForgotPasswordResponse,
  ResendLoginCodeResponse,
  ResetPasswordResponse,
} from "./types";
import {
  getDeviceFingerprintHash,
  getOsAndBrowsername,
} from "app/utils/deviceSignature";

const login = async (payload: LoginPayload) => {
  // generate an id and get the name of the device the user is currently on
  // which in this case will be the name of the browser and the OS
  const DeviceId: string = await getDeviceFingerprintHash();
  const DeviceName: string = getOsAndBrowsername();

  return await request.post<LoginResponse>("/v3/login", payload, {
    headers: {
      noToken: true,
      // this header is to enable resending of email verification if the user hasn't verified their email
      Resend: "t",
      "Device-Id": DeviceId,
      "Device-Name": DeviceName,
    },
  });
};

const loginConfirm = async (payload: LoginConfirmPayload) => {
  const { code, token } = payload;
  // generate an id and get the name of the device the user is currently on
  // which in this case will be the name of the browser and the OS
  const DeviceId: string = await getDeviceFingerprintHash();
  const DeviceName: string = getOsAndBrowsername();

  return await request.post<LoginResponse>(
    "/v3/login/confirm",
    { code },
    {
      headers: {
        noToken: true,
        Authorization: `Bearer ${token}`,
        "Device-Id": DeviceId,
        "Device-Name": DeviceName,
      },
    }
  );
};

const validateCredential = async (payload: ValidatePayload) => {
  return await request.get<ValidateResponse>(
    `/v3/verify/${payload.name}/${payload.value}`,
    {
      headers: {
        // use this to exempt authorization token
        noToken: true,
      },
    }
  );
};

const verifyEmailAddress = async (payload: VerifyEmailAddressPayload) => {
  return await request.get<VerifyEmailAddressResponse>(
    "/v3/register/verify/email",
    {
      headers: {
        noToken: true,
        Authorization: `Bearer ${payload.code}`,
      },
    }
  );
};

const signup = async ({
  firstname,
  lastname,
  continueFromApp,
  ...rest
}: SignupPayload) => {
  const query = continueFromApp ? `?continue=${continueFromApp}` : "";
  return await request.post<SignupResponse>(
    `/v3/register${query}`,
    {
      first_name: firstname,
      last_name: lastname,
      ...rest,
    },
    {
      headers: {
        noToken: true,
      },
    }
  );
};

const resendLoginCode = async (payload: ResendLoginCodePayload) => {
  return await request.get<ResendLoginCodeResponse>(`/v3/login/resend/code`, {
    headers: {
      noToken: true,
      Authorization: `Bearer ${payload.token}`,
    },
  });
};

const resendVerificationEmail = async (
  payload: ResendVerificationEmailPayload
) => {
  const { email, continueFromApp } = payload;
  const query = continueFromApp ? `?continue=${continueFromApp}` : "";
  return await request.post<ResendVerificationEmailResponse>(
    `/v3/register/resend/email${query}`,
    {
      email,
    },
    {
      headers: {
        // use this to exempt authorization token
        noToken: true,
      },
    }
  );
};

const forgotPassword = async (payload: ForgotPasswordPayload) => {
  return await request.post<ForgotPasswordResponse>(
    "/v3/password/forgot",
    payload,
    {
      headers: {
        noToken: true,
      },
    }
  );
};

const resetPassword = async (payload: ResetPasswordPayload) => {
  return await request.put<ResetPasswordResponse>(
    "/v3/password/reset",
    payload,
    {
      headers: {
        noToken: true,
        Authorization: `Bearer ${payload.headerToken}`,
      },
    }
  );
};

const invalidateToken = async () => {
  const {
    tokens: { access_token },
  } = store.getState();

  return fetch(`${process.env.REACT_APP_AUTH_URL}/v3/logout`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
};

const refreshAccessToken = async (
  refreshToken: string
): Promise<LoginResponse> => {
  return (
    await fetch(
      `${process.env.REACT_APP_AUTH_URL}/v3/login/token?refresh_token=${refreshToken}`
    )
  ).json();
};

export default {
  login,
  loginConfirm,
  forgotPassword,
  resetPassword,
  signup,
  resendLoginCode,
  resendVerificationEmail,
  validateCredential,
  verifyEmailAddress,
  invalidateToken,
  refreshAccessToken,
};
