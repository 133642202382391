import media from "app/styles/utils/media";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: "description device location date";
  grid-row-gap: 20px;
  border-bottom: 1px solid rgba(211, 213, 216, 0.5);
  padding: 20px 0px;
  align-items: start;
  & > button {
    font-size: 0.7rem;
  }

  ${media.mobile`
    grid-row-gap: 8px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas: 'description'
                          'location'
                          'date'
                          'device';
  `}
`;

export const ItemName = styled.div`
  grid-area: description;
  text-align: left;
  & > p {
    font-size: 0.8rem;
    text-transform: capitalize;
  }
`;

export const DeviceBadge = styled.p`
  grid-area: device;
  background: #e4e7eb;
  border: 2px solid #ffffff;
  border-radius: 100px;
  padding: 2px 10px;
  color: #3e4c59;
  text-align: center;
  font-size: 0.65rem;
  width: fit-content;
  ${media.mobile`
    /* float: right; */
  `}
`;

export const ItemDate = styled.div`
  grid-area: date;
  color: #616e7c;
  font-size: 0.8rem;
  text-align: left;
`;

export const ItemLocation = styled.div`
  grid-area: location;
  color: #3e4c59;
  text-align: left;

  & > p {
    font-size: 0.8rem;
  }

  .ip-address {
    color: #7b8794;
    font-size: 0.7rem;
    margin-top: 8px;
  }

  ${media.mobile`
    display: flex;
    align-items: center;
    .ip-address {
      margin-top: 0;
      margin-left: 8px;
    }
  `}
`;
