import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "app/styles/blocks/PageHeader";
import PrevPageLink from "app/components/PrevPageLink";
import PersonalDetailsForm from "./components/PersonalDetailsForm";
import { EditProfileTitleBox } from "./styles";
import P from "app/styles/elements/P";
import { RootState } from "app/redux/reducers";
import {
  updateUserProfile,
  clearProfileActionState,
} from "app/redux/profile/actions";
import { UpdateProfilePayload } from "app/api/profile/types";

const EditProfilePage: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, action } = useSelector((state: RootState) => state.profile);

  const onUpdateProfile = React.useCallback(
    (payload: UpdateProfilePayload) => {
      dispatch(updateUserProfile(payload));
    },
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(clearProfileActionState());
  }, [dispatch]);

  React.useEffect(() => {
    if (action === "updated") {
      dispatch(clearProfileActionState());
      history.push("/profile");
    }
  }, [action, history, dispatch]);

  return (
    <div className="page-content">
      <PrevPageLink to="/profile" text="General" />
      <PageHeader.Main>
        <PageHeader.Title big>Edit Profile</PageHeader.Title>
      </PageHeader.Main>
      <EditProfileTitleBox>
        <P semiBold medium>
          Personal Details
        </P>
      </EditProfileTitleBox>
      <PersonalDetailsForm
        initialValues={data}
        loading={action === "updating"}
        onUpdateProfile={onUpdateProfile}
      />
    </div>
  );
};

export default EditProfilePage;
