import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { Section, SectionAction, SectionInfo } from "./styles";
import P from "app/styles/elements/P";
import Button from "app/styles/elements/Button";
import { RootState } from "app/redux/reducers";

const PhoneSection: React.FC = (props) => {
  const match = useRouteMatch();
  const { data } = useSelector((state: RootState) => state.security);

  return (
    <Section>
      <SectionInfo>
        <P medium semiBold>
          {data?.phone || "Primary phone"}
        </P>
        <P small className="sub-title">
          Keep your primary phone number up to date
        </P>
      </SectionInfo>
      <SectionAction>
        <Button as={Link} to={`${match.path}/phone`} variant="outline">
          Manage Phone
        </Button>
      </SectionAction>
    </Section>
  );
};

export default PhoneSection;
