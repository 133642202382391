import request from "../request";
import {
  FetchDocumentTypesResponse,
  FetchDocumentsResponse,
  UploadDocumentPayload,
} from "./types";
import { GenericFetchSingleItemPayload, GenericResponse } from "../types";

const fetchDocuments = async () => {
  return await request.get<FetchDocumentsResponse>(`/v3/me/documents`);
};

const fetchDocumentTypes = async () => {
  return await request.get<FetchDocumentTypesResponse>(
    `/v3/me/documents/types`
  );
};

const fetchDocumentType = async (payload: GenericFetchSingleItemPayload) => {
  return await request.get<FetchDocumentTypesResponse>(
    `/v3/me/documents/types/${payload.id}`
  );
};

const uploadDocument = async (payload: UploadDocumentPayload) => {
  return await request.post<GenericResponse>(`/v3/me/documents`, payload);
};

export default {
  fetchDocuments,
  fetchDocumentTypes,
  fetchDocumentType,
  uploadDocument,
};
