import React from "react";
import { useFormik } from "formik";
import { Form } from "./styles";
import Input from "app/components/Input";
import Button from "app/components/Button";
import { ValidateEmailPayload } from "app/api/auth/types";
import { emailIsValid } from "app/utils/regex";
import api from "app/api";

interface Props {
  onEmailVerified: (e: string) => void;
}

const EmailForm: React.FC<Props> = (props) => {
  const validate = (values: ValidateEmailPayload) => {
    const errors: any = {};

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailIsValid(values.email)) {
      errors.email = "Email is not valid";
    }

    return errors;
  };
  const form = useFormik<ValidateEmailPayload>({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      try {
        await api.authService.validateCredential({
          name: "email",
          value: values.email,
        });
        props.onEmailVerified(values.email);
      } catch (error) {
        // the request failing means that no user exists with that email
        form.errors.email = "Couldn't find your Busha account";
      }
    },
    validate,
    validateOnChange: false,
  });

  const onInputFocus = (name: string) => () =>
    form.setFieldError(name, undefined);

  return (
    <Form onSubmit={form.handleSubmit}>
      <Input
        fullwidth
        placeholder="Email address"
        label="Email"
        name="email"
        id="email"
        autoFocus
        value={form.values.email}
        onChange={form.handleChange}
        error={!!form.errors.email}
        errorMessage={form.errors.email}
        disabled={form.isSubmitting}
        onFocus={onInputFocus("email")}
      />
      <Button
        type="submit"
        className="submit-button"
        text="Next"
        variant="green"
        loading={form.isSubmitting}
      />
    </Form>
  );
};

export default EmailForm;
