import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ReactComponent as Logo } from "app/assets/img/logo.svg";
import AuthLayout from "app/styles/blocks/AuthLayout";
import { Title } from "./styles";
import H2 from "app/styles/elements/H2";
import { Link } from "react-router-dom";
import ResetPasswordForm from "./components/ResetPasswordForm";
import P from "app/styles/elements/P";
import { RootState } from "app/redux/reducers";
import {
  resetUserPassword,
  cleanupResetPasswordState,
} from "app/redux/auth/actions";

interface ParamsTypes {
  token: string;
}

const ResetPassword: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<ParamsTypes>();

  const { resetPassword: ResetPasswordState } = useSelector(
    (state: RootState) => state.auth
  );

  const onSubmitForm = (password: string, token: string | null) => {
    dispatch(
      resetUserPassword({ password, token, headerToken: match.params.token })
    );
  };

  // clean up reset password state on page load
  useEffect(
    useCallback(() => {
      dispatch(cleanupResetPasswordState());
    }, [dispatch]),
    []
  );

  useEffect(
    useCallback(() => {
      if (ResetPasswordState.success) {
        history.push(`/change-password/response/success`);
      }

      if (ResetPasswordState.error?.status === "Unauthorized") {
        history.push(`/change-password/response/expired`);
      }
    }, [ResetPasswordState, history]),
    [ResetPasswordState]
  );

  return (
    <AuthLayout.Main>
      <AuthLayout.Body>
        <AuthLayout.Header>
          <Link to="/">
            <Logo className="logo" />
          </Link>
        </AuthLayout.Header>
        <Title>
          <H2>Create a new password</H2>
          <P small subdued>
            Choose a password that’s hard to guess and unique to this account.
          </P>
        </Title>
        <ResetPasswordForm
          onSubmitForm={onSubmitForm}
          loading={ResetPasswordState.pending}
          error={ResetPasswordState.error?.message}
        />
      </AuthLayout.Body>
    </AuthLayout.Main>
  );
};

export default ResetPassword;
