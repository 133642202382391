import createActionType from "../util/createActionType";
import {
  LoginPayload,
  LoginConfirmPayload,
  ResendLoginCodePayload,
  SignupPayload,
  ResendVerificationEmailPayload,
  VerifyEmailAddressPayload,
  ForgotPasswordPayload,
  ResetPasswordPayload,
  LoginResponse,
  SignupResponse,
  ResendLoginCodeResponse,
  ResendVerificationEmailResponse,
  ResetPasswordResponse,
} from "app/api/auth/types";

export interface AuthFormState<T = any> {
  data?: T;
  pending: boolean;
  error: T extends string ? string | null : T | null;
  success: boolean;
}

export interface AuthState {
  login: AuthFormState<LoginResponse>;
  signup: AuthFormState<SignupResponse>;
  resetPassword: AuthFormState<ResetPasswordResponse>;
  forgotPassword: AuthFormState;
  resendLoginCode: AuthFormState<ResendLoginCodeResponse>;
  resendVerificationEmail: AuthFormState<ResendVerificationEmailResponse>;
  verifyEmailAddress: AuthFormState;
  logout: AuthFormState;
}

export const loginUser = createActionType("login_user");
export const loginConfirm = createActionType("login_confirm");
export const clearLoginData = createActionType("clear_login_data");
export const clearLoginErrors = createActionType("clear_login_errors");

export const signupUser = createActionType("signup_user");
export const clearSignupData = createActionType("clear_signup_data");
export const clearSignupErrors = createActionType("clear_signup_errors");

export const resendLoginCode = createActionType("resend_login_code");
export const clearResendLoginCodeData = createActionType(
  "clear_resend_login_code_data"
);

export const resendVerificationEmail = createActionType(
  "resend_verification_email"
);
export const clearResendVerificationEmailData = createActionType(
  "clear_resend_verification_email_data"
);

export const verifyEmailAddress = createActionType("verify_email_address");
export const clearVerifyEmailAddressData = createActionType(
  "clear_verify_email_address_data"
);

export const resetPassword = createActionType("reset_password");
export const clearResetPasswordData = createActionType(
  "clear_reset_password_data"
);

export const forgotPassword = createActionType("forgot_password");
export const clearForgotPasswordData = createActionType(
  "clear_forgot_password_data"
);

export const logoutUser = createActionType("logout_user");

export interface LoginAction {
  type: string;
  payload: LoginPayload;
}

export interface LoginConfirmAction {
  type: string;
  payload: LoginConfirmPayload;
}

export interface ResetPasswordAction {
  type: string;
  payload: ResetPasswordPayload;
}

export interface ForgotPasswordAction {
  type: string;
  payload: ForgotPasswordPayload;
}

export interface SignupAction {
  type: string;
  payload: SignupPayload;
}

export interface ResendVerificationEmailAction {
  type: string;
  payload: ResendVerificationEmailPayload;
}
export interface ResendLoginCodeAction {
  type: string;
  payload: ResendLoginCodePayload;
}
export interface VerifyEmailAddressAction {
  type: string;
  payload: VerifyEmailAddressPayload;
}
