function decodeJWT(token: string) {
  const tokenArray = token.split(".");
  if (tokenArray.length > 2) {
    const decodedData = JSON.parse(atob(tokenArray[1]));
    return decodedData;
  }
  return {};
}

export default decodeJWT;
