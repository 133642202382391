import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Routes from "app/routes";
import store from "app/redux";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ToastContainer
        className="toast__container"
        toastClassName="toast__toast"
        bodyClassName="toast__body"
        closeButton={false}
        hideProgressBar={true}
        position="bottom-center"
      />
      <Routes />
    </Provider>
  );
};

export default App;
