import React from "react";
import { useFormik } from "formik";
import { Container } from "./styles";
import CommunicationItem from "./CommunicationItem";
import { UpdateNotificationsPayload } from "../../../api/notifications/types";

interface Props {
  initialValues: UpdateNotificationsPayload | null;
  updating: boolean;
  onUpdateNotifications: (payload: UpdateNotificationsPayload) => void;
}

const CommunicationItems: React.FC<Props> = (props) => {
  const { initialValues, onUpdateNotifications } = props;

  const form = useFormik({
    initialValues: {
      deposits: !!initialValues?.deposits,
      security: !!initialValues?.security,
      trades: !!initialValues?.trades,
      transfers: !!initialValues?.transfers,
    },
    onSubmit: (values) => {
      onUpdateNotifications(values);
    },
    enableReinitialize: true,
  });

  return (
    <Container>
      <CommunicationItem
        title="Deposits"
        description="Receive a notification for all deposits."
        active={form.values.deposits}
        onChange={() => {
          form.setFieldValue("deposits", !form.values.deposits);
          form.handleSubmit();
        }}
      />
      <CommunicationItem
        title="Security"
        description="Receive a notification for all security actions."
        active={form.values.security}
        onChange={() => {
          form.setFieldValue("security", !form.values.security);
          form.handleSubmit();
        }}
      />
      <CommunicationItem
        title="Trades"
        description="Receive a notification for all trades."
        active={form.values.trades}
        onChange={() => {
          form.setFieldValue("trades", !form.values.trades);
          form.handleSubmit();
        }}
      />
      <CommunicationItem
        title="Transfers"
        description="Receive a notification for all transfers."
        active={form.values.transfers}
        onChange={() => {
          form.setFieldValue("transfers", !form.values.transfers);
          form.handleSubmit();
        }}
      />
    </Container>
  );
};

export default CommunicationItems;
