import styled, { keyframes, css } from "styled-components";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Link = styled(NavLink)`
  display: inline-block;
  width: 100%;
  height: 44px;
  padding: 0px 17px;
  border-radius: 3px;
  text-decoration: none;
  color: #3e4c59;
  font-size: 0.8rem;
  display: flex;
  align-items: center;

  transition: background 300ms linear;

  &:not(:first-child) {
    margin-top: 10px;
  }

  &.active {
    background: #e3f9e5;
    color: #014807;
    font-weight: 500;
  }
`;

interface Props {
  show?: boolean;
}
const fadeIn = keyframes`
from {
    opacity: 0;
}
to {
    opacity: 1;
}
`;
export const MobileContainer = styled.section<Props>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: rgba(62, 76, 89, 0.4);
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 300ms ease-in;

  ${(props) =>
    !props.show &&
    css`
      display: none;
    `};
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  /* right: 20px; */
  left: 320px;
  border: none;
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Sidenav = styled.aside`
  height: 100%;
  width: 300px;
  background: white;

  .logo {
    padding: 12px 24px;
    & > svg {
      height: 26px;
    }
  }
`;

export const Links = styled.section`
  padding: 5px 0px;
`;

export const MobileLink = styled(NavLink)`
  display: inline-block;
  width: 100%;
  height: 66px;
  padding: 0px 24px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 0.9rem;
  color: #3e4c59;
  border-bottom: 1px solid rgba(211, 213, 216, 0.5);

  &.active {
    color: #18981d;
  }
`;
