import { AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/utils/parseError";
import {
  fetchSessions,
  FetchSessionsAction,
  signoutSession,
  SignoutSessionAction,
} from "./types";
import { FetchSessionsResponse } from "app/api/sessions/types";

function* fetchSessionsSaga(action: FetchSessionsAction) {
  try {
    const { meta } = action;

    yield put({ type: fetchSessions.pending });

    const res: AxiosResponse<FetchSessionsResponse> = yield call(
      api.sessionsService.fetchSessions,
      meta
    );
    const data = res.data;
    yield put({ type: fetchSessions.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: fetchSessions.rejected, payload: errorMessage });
  }
}

function* signoutSessionSaga(action: SignoutSessionAction) {
  try {
    const { payload } = action;

    yield put({ type: signoutSession.pending });

    const res: AxiosResponse<FetchSessionsResponse> = yield call(
      api.sessionsService.signoutSession,
      payload
    );
    const data = res.data;
    yield put({ type: signoutSession.fulfilled, payload: data });
    yield put({ type: fetchSessions.default, meta: { page: 1 } });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: signoutSession.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchSessions.default, fetchSessionsSaga),
    takeLatest(signoutSession.default, signoutSessionSaga),
  ]);
}
