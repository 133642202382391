import React from "react";

import {
  MobileContainer,
  Sidenav,
  Links,
  MobileLink,
  CloseButton
} from "./styles";
import routes from "./routes";
import { ReactComponent as LogoImg } from "app/assets/img/logo.svg";
import { ReactComponent as CloseIcon } from "app/assets/img/icons/close.svg";

interface Props {
  show: boolean;
  onMobileCloseSidebar: () => void;
}

export const MobileSidebar: React.FC<Props> = props => {
  const onLinkClicked = () => props.onMobileCloseSidebar();
  return (
    <MobileContainer show={props.show}>
      <CloseButton>
        <CloseIcon onClick={props.onMobileCloseSidebar} />
      </CloseButton>
      <Sidenav>
        <div className="logo">
          <LogoImg />
        </div>
        <Links>
          {routes.map(r => (
            <MobileLink key={r.name} to={r.path} onClick={onLinkClicked}>
              {r.name}
            </MobileLink>
          ))}
        </Links>
      </Sidenav>
    </MobileContainer>
  );
};

export default MobileSidebar;
