import React from "react";

import { DocumentsBox } from "./styles";
import DocumentItem from "./DocumentItem";
import { Document } from "app/api/documents/types";

interface Props {
  documents: Document[];
}

const Documents: React.FC<Props> = ({ documents }) => {
  return (
    <DocumentsBox>
      {documents.map((document) => (
        <DocumentItem key={document.id} {...document} />
      ))}
    </DocumentsBox>
  );
};

export default Documents;
