import { logoutUser } from "../auth/types";
import {
  DocumentsState,
  fetchDocuments,
  fetchDocumentTypes,
  fetchDocumentType,
  uploadDocument,
  resetDocumentActionState,
} from "./types";

const INITIAL_STATE: DocumentsState = {
  status: "fetching",
  action: "idle",
  error: null,
  documentTypes: [],
  countryInfo: null,
  selectedDocumentType: null,
  data: [],
};

export default function auth(
  state = INITIAL_STATE,
  action: any
): DocumentsState {
  switch (action.type) {
    case fetchDocuments.pending:
    case fetchDocumentTypes.pending:
    case fetchDocumentType.pending:
      return {
        ...state,
        status: "fetching",
      };

    case fetchDocuments.rejected:
    case fetchDocumentTypes.rejected:
    case fetchDocumentType.rejected:
      return {
        ...state,
        status: "error",
        error: action.payload,
      };

    case fetchDocuments.fulfilled:
      return {
        ...state,
        error: null,
        data: action.payload.data,
        status: "fetched",
      };

    case fetchDocumentTypes.fulfilled: {
      const { document_types: documentTypes, ...countryInfo } = action.payload;
      return {
        ...state,
        error: null,
        documentTypes,
        countryInfo,
        status: "fetched",
      };
    }
    case fetchDocumentType.fulfilled:
      return {
        ...state,
        error: null,
        selectedDocumentType: action.payload,
        status: "fetched",
      };

    case uploadDocument.pending:
      return {
        ...state,
        action: "updating",
      };

    case uploadDocument.rejected:
      return {
        ...state,
        action: "error",
        error: action.payload,
      };

    case uploadDocument.fulfilled:
      return {
        ...state,
        error: null,
        data: action.payload.data,
        action: "updated",
      };

    case resetDocumentActionState.default:
      return {
        ...state,
        action: "idle",
      };

    case logoutUser.default:
      return INITIAL_STATE;

    default:
      return state;
  }
}
