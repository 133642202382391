import React from "react";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PrevPageLink from "app/components/PrevPageLink";
import PageHeader from "app/styles/blocks/PageHeader";
import { Container } from "./styles";
import LoaderBox from "app/components/LoaderBox";
import NumberIDForm from "./components/NumberIDForm";
import DocUploadForm from "./components/DocUploadForm";
import { RootState } from "app/redux/reducers";
import useUploadSteps from "app/hooks/useUploadSteps";
import {
  fetchUserDocumentType,
  createUserDocument,
  clearDocumentAction,
} from "app/redux/documents/actions";

interface State {
  selectedDocType: string;
}

const DocumentUploadPage: React.FC = (props) => {
  // 0 - enter id number (if reqiured) , 1 - upload doc

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<State | null>();

  const { status, selectedDocumentType, action } = useSelector(
    (state: RootState) => state.documents
  );

  const {
    currentStep,
    stepsRequirements,
    completed,
    submittedData,
    onSubmitData,
  } = useUploadSteps(selectedDocumentType);

  const [showReview, setShowReview] = React.useState(false);

  const onSubmitStep = (value: string) => {
    onSubmitData(stepsRequirements[currentStep].formValue, value);
  };

  const onShowReviewChange = (show: boolean) => {
    setShowReview(show);
  };

  React.useEffect(() => {
    const selectedDocumentId = location.state?.selectedDocType;
    if (selectedDocumentId) {
      dispatch(fetchUserDocumentType(selectedDocumentId));
    }
  }, [dispatch, location.state]);

  React.useEffect(() => {
    if (completed) {
      const selectedDocumentId = location.state?.selectedDocType;
      dispatch(
        createUserDocument({
          ...submittedData,
          document_type_id: selectedDocumentId,
        })
      );
    }
  }, [completed, dispatch, location.state, submittedData]);

  React.useEffect(() => {
    if (action === "updated" || action === "error") {
      dispatch(clearDocumentAction());
      history.push("/limits-and-levels");
    }
  }, [action, dispatch, history]);

  if (!location.state?.selectedDocType) {
    return <Redirect to="/limits-and-levels/documents" />;
  }

  return (
    <div className="page-content">
      <PrevPageLink to="/limits-and-levels/documents" text="Back" />
      {status === "fetching" ? (
        <LoaderBox height={400} />
      ) : (
        <>
          <PageHeader.Main>
            <PageHeader.Title big>
              {showReview
                ? "Review Upload"
                : stepsRequirements[currentStep]?.name ||
                  selectedDocumentType?.name}
            </PageHeader.Title>
          </PageHeader.Main>
          <Container>
            {stepsRequirements[currentStep]?.type === "id" && (
              <NumberIDForm
                onIdSubmitted={onSubmitStep}
                country={selectedDocumentType?.country}
              />
            )}
            {stepsRequirements[currentStep]?.type === "document" && (
              <DocUploadForm
                onFileUploaded={onSubmitStep}
                requirement={stepsRequirements[currentStep]?.requirement}
                warning={stepsRequirements[currentStep]?.warning}
                sample={stepsRequirements[currentStep]?.sample}
                onShowReviewChange={onShowReviewChange}
              />
            )}
            {action === "updating" && (
              <LoaderBox height={300} text="Submitting documents..." />
            )}
          </Container>
        </>
      )}
    </div>
  );
};

export default DocumentUploadPage;
