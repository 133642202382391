import { AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/utils/parseError";
import toast from "app/utils/toast";
import session from "app/utils/session";
import {
  fetchProfile,
  updateProfile,
  UpdateProfileAction,
  UpdateUsernameAction,
  updateUsername,
  UpdateAvatarAction,
  updateAvatar,
} from "./types";
import {
  FetchProfileResponse,
  UpdateProfileResponse,
} from "app/api/profile/types";

function* fetchProfileSaga() {
  try {
    yield put({ type: fetchProfile.pending });

    const res: AxiosResponse<FetchProfileResponse> = yield call(
      api.profileService.fetchProfile
    );
    const data = res.data;
    yield call(session.set, "profile", data);
    yield put({ type: fetchProfile.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: fetchProfile.rejected, payload: errorMessage });
  }
}

function* updateProfileSaga(action: UpdateProfileAction) {
  try {
    const { payload } = action;
    yield put({ type: updateProfile.pending });
    const res: AxiosResponse<UpdateProfileResponse> = yield call(
      api.profileService.updateProfile,
      payload
    );
    const data = res.data;
    yield put({ type: updateProfile.fulfilled, payload: data });
    yield put({ type: fetchProfile.default });
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({ type: updateProfile.rejected, payload: errorMessage });
  }
}

function* updateUsernameSaga(action: UpdateUsernameAction) {
  try {
    const { payload } = action;
    yield put({ type: updateUsername.pending });
    const res: AxiosResponse<UpdateProfileResponse> = yield call(
      api.profileService.updateUsername,
      payload
    );
    const data = res.data;
    yield put({ type: updateUsername.fulfilled, payload: data });
    yield call(toast, data?.message || "Username updated", {
      variant: "success",
    });
    yield put({ type: fetchProfile.default });
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({ type: updateUsername.rejected, payload: errorMessage });
  }
}

function* updateAvatarSaga(action: UpdateAvatarAction) {
  try {
    const { payload } = action;
    yield put({ type: updateAvatar.pending });
    const res: AxiosResponse<UpdateProfileResponse> = yield call(
      api.profileService.updateAvatar,
      payload
    );
    const data = res.data;
    yield put({ type: updateAvatar.fulfilled, payload: data });
    yield put({ type: fetchProfile.default });
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({ type: updateAvatar.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchProfile.default, fetchProfileSaga),
    takeLatest(updateProfile.default, updateProfileSaga),
    takeLatest(updateUsername.default, updateUsernameSaga),
    takeLatest(updateAvatar.default, updateAvatarSaga),
  ]);
}
