import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "app/styles/blocks/PageHeader";
import ProfileImageSection from "./components/ProfileImageSection";
import PersonalDetails from "./components/PersonalDetails";
import { RootState } from "app/redux/reducers";
import { updateUserAvatar } from "app/redux/profile/actions";
import UsernameSection from "./components/UsernameSection";

const ProfilePage: React.FC = (props) => {
  const dispatch = useDispatch();
  const { data, pending, action } = useSelector(
    (state: RootState) => state.profile
  );

  const handleAvatarUrlUpdate = (avatar: string) => {
    dispatch(updateUserAvatar({ avatar }));
  };

  return (
    <div className="page-content">
      <PageHeader.Main>
        <PageHeader.Title big>General</PageHeader.Title>
        <PageHeader.Description small>
          View your personal profile information
        </PageHeader.Description>
      </PageHeader.Main>
      <ProfileImageSection
        userProfile={data}
        updating={action === "updating"}
        handleAvatarUrlUpdate={handleAvatarUrlUpdate}
      />
      <UsernameSection userProfile={data} />
      <PersonalDetails userProfile={data} fetching={pending} />
    </div>
  );
};

export default ProfilePage;
