import React, { useContext } from "react";
import { format } from "date-fns";
import { Container, ItemName, ItemDate, ItemLocation } from "./styles";
import P from "app/styles/elements/P";
import Button from "app/components/Button";
import { ReactComponent as ValidIcon } from "app/assets/img/icons/2fa-on.svg";
import { ApplicationType } from "app/api/applications/types";
import { DeviceType } from "app/api/devices/types";
import { SessionType } from "app/api/sessions/types";
import { DeviceContext } from "../../index";

type ItemProps = ApplicationType & DeviceType & SessionType;

interface Props extends ItemProps {
  type?: "session" | "application" | "web";
  loading?: boolean;
  onRemove?: () => void;
}

const DeviceItem: React.FC<Props> = (props) => {
  const currentDevice = useContext(DeviceContext);

  const date = props?.created_at
    ? format(new Date(props.created_at), "MMMM dd, yyyy 'at' hh:mm a")
    : "-";

  const isCurrentDevice = !!(
    props?.device_name?.toLowerCase() === currentDevice.name.toLowerCase() &&
    props?.device_id?.toLowerCase() === currentDevice.id?.toLowerCase()
  );
  return (
    <Container>
      <ItemName>
        <P>{props.device_name}</P>
        {isCurrentDevice && (
          <P className="active-device">
            <ValidIcon />
            This Device
          </P>
        )}
      </ItemName>
      <ItemDate>{date}</ItemDate>
      <ItemLocation>
        <P>{props?.location || "Location unavailable"}</P>
        <P className="ip-address">{props?.ip || "IP address unavailable"}</P>
      </ItemLocation>
      {!isCurrentDevice && (
        <Button
          variant="danger"
          loading={props.loading}
          text={props.type === "session" ? "Log out" : "Remove"}
          onClick={props.onRemove}
        />
      )}
    </Container>
  );
};

export default DeviceItem;
