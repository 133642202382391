import styled from "styled-components";
import media from "app/styles/utils/media";

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  ${media.mobile`
    flex-wrap: wrap;
    & > * {
      flex: 100%;
    }
  `};
`;

export const SectionInfo = styled.div`
  width: 387px;
  max-width: 100%;
  & > p.sub-title {
    margin-top: 10px;
    color: #616e7c;
    display: flex;
    align-items: center;
    & > svg {
      margin-right: 8px;
      height: 14px;
      width: 14px;
    }
  }
`;

export const SectionAction = styled.div`
  & > a {
    width: 335px;
    height: 46px;
    font-size: 0.8rem;

    ${media.mobile`
      margin-top: 24px;
      width: 100%;
    `};
  }
  & > a.two-step-link {
    width: auto;
    height: 40px;
  }
`;
