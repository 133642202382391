import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { SmsContainer, Section, InputBox } from "./styles";
import P from "app/styles/elements/P";
import Button from "app/components/Button";
import Input from "app/components/Input";

const SmsPage: React.FC = props => {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <SmsContainer>
      <Section>
        <P medium semiBold>
          Let’s set up your phone
        </P>
        <P small className="description">
          Enter the phone number of the mobile device you’d like your
          authentication codes sent to each time you log in.
        </P>
      </Section>
      <InputBox>
        <Input label="Phone number" />
        <P little className="help-text">
          We’ll send a 6-digit authentication code to verify you own the number.
        </P>
      </InputBox>
      <Button
        variant="green"
        text="Send authentication code"
        className="link"
        onClick={() => history.push(`${match.path}/enable`)}
      />
    </SmsContainer>
  );
};

export default SmsPage;
