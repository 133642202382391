import styled from "styled-components";

import media from "app/styles/utils/media";

export const Container = styled.div``;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  ${media.mobile`
    flex-wrap: wrap;
    & > * {
      flex: 100%;
    }
  `};
`;

export const ItemInfo = styled.div`
  & > p.sub-title {
    margin-top: 10px;
    color: #3e4c59;
  }
`;

export const ItemAction = styled.div`
  ${media.mobile`
    margin-top: 21px;
  `};
`;
