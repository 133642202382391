import createActionType from "../util/createActionType";
import {
  FetchNotificationsResponse,
  UpdateNotificationsResponse,
  NotificationsType,
} from "app/api/notifications/types";

export interface NotificationsState {
  data: FetchNotificationsResponse | null;
  pending: boolean;
  updating: boolean;
  error: UpdateNotificationsResponse extends string
    ? string | null
    : UpdateNotificationsResponse | null;
  success: boolean;
}

export const fetchNotifications = createActionType("fetch_notifications");
export const updateNotifications = createActionType("update_notifications");

export interface UpdateNotificationsAction {
  type: string;
  payload: NotificationsType;
}
