import React from "react";
import { Link } from "react-router-dom";

import AuthLayout from "app/styles/blocks/AuthLayout";
import { SuccessPageBody } from "../Signup/styles";
import { ReactComponent as Logo } from "app/assets/img/logo.svg";
import { ReactComponent as EmailIcon } from "app/assets/img/icons/email.svg";
import H2 from "app/styles/elements/H2";
import P from "app/styles/elements/P";

const SuccessPage: React.FC = (props) => {
  return (
    <AuthLayout.Main>
      <SuccessPageBody>
        <AuthLayout.Header>
          <Link to="/">
            <Logo className="logo" />
          </Link>
        </AuthLayout.Header>
        <div className="icon">
          <EmailIcon />
        </div>
        <H2 medium semiBold className="title">
          You’re almost there!
        </H2>
        <P small className="description">
          Click on the link we sent to your email address to change your
          password.
        </P>
      </SuccessPageBody>
    </AuthLayout.Main>
  );
};

export default SuccessPage;
