import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import DocumemtsPage from "./Documents";
import DocumentUploadPage from "./DocumentUpload";

const DocumentsRoutes: React.FC = (props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={DocumemtsPage} exact />
      <Route
        path={`${match.path}/upload`}
        component={DocumentUploadPage}
        exact
      />
    </Switch>
  );
};

export default DocumentsRoutes;
