function mapKeys(items: object[], key: string, exclude: string[]) {
  return items.reduce((obj: object, currentObj: object) => {
    const newObject = { ...currentObj };
    for (let item of exclude) {
      delete newObject[item];
    }

    obj[currentObj[key]] = newObject;
    return obj;
  }, {});
}

const asyncWrap = (promise: Promise<any>) =>
  promise.then((result: any) => [null, result]).catch((err: Error) => [err]);

const getFileSizeInKB = (size: number) => parseInt(String(size / 1024));

const encodeString = (str: string) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );

const decodeString = (str: string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(
        atob(str),
        (c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      )
      .join("")
  );
export { mapKeys, asyncWrap, getFileSizeInKB, encodeString, decodeString };
