import createActionType from "../util/createActionType";
import { ReducerStateType } from "../reducers";
import {
  FetchProfileResponse,
  UserProfileType,
  UpdateUsernamePayload,
  UpdateAvatarPayload,
} from "app/api/profile/types";

export interface ProfileState {
  data: FetchProfileResponse | null;
  pending: boolean;
  action: ReducerStateType;
  error: FetchProfileResponse extends string
    ? string | null
    : FetchProfileResponse | null;
  success: boolean;
}

export const fetchProfile = createActionType("fetch_profile");
export const updateProfile = createActionType("update_profile");
export const updateUsername = createActionType("update_username");
export const updateAvatar = createActionType("update_avatar");
export const resetProfileAction = createActionType("reset_profile_action");
export interface FetchProfileAction {
  type: string;
}
export interface UpdateProfileAction {
  type: string;
  payload: UserProfileType;
}
export interface UpdateUsernameAction {
  type: string;
  payload: UpdateUsernamePayload;
}
export interface UpdateAvatarAction {
  type: string;
  payload: UpdateAvatarPayload;
}
