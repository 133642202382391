import request from "../request";
import {
  GetPresignedLinkPayload,
  GetPresignedLinkResponse,
  UploadFilePayload,
} from "./types";

const getPresignedLink = async (payload: GetPresignedLinkPayload) => {
  const query = `?category=${payload.category}&filename=${payload.filename}`;
  return await request.get<GetPresignedLinkResponse>(
    `/v3/me/upload_link${query}`
  );
};

const uploadFile = async (payload: UploadFilePayload) => {
  const { presigned_url, file } = payload;

  const options = {
    method: "PUT",
    body: file,
    processData: false,
    contentType: false,
    headers: { "Content-Type": "multipart/form-data" },
  };

  return fetch(presigned_url, options);
};

export default { getPresignedLink, uploadFile };
