import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-top: 24px;

  & > h2 {
    margin-top: 16px;
    color: #3e4c59;
  }
  & > p {
    margin-top: 6px;
    color: #7b8794;
  }
`;

export const AvatarBox = styled.div`
  display: flex;
  align-items: center;

  & > button {
    margin-left: 16px;
    font-size: 0.8rem;
  }

  input,
  label {
    display: none;
  }
`;
