import React from "react";
import { Container, Link } from "./styles";
import routes from "./routes";

const Sidebar: React.FC = props => {
  return (
    <Container>
      {routes.map(r => (
        <Link key={r.name} to={r.path}>
          {r.name}
        </Link>
      ))}
    </Container>
  );
};

export default Sidebar;
