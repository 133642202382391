import styled from "styled-components";

const Header = styled.header`
  width: 100%;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
`;

export default Header;
