import styled, { css } from "styled-components";

export interface ButtonProps {
  fullwidth?: boolean;
  variant?: "green" | "clear" | "outline" | "danger";
}

const Button = styled.button<ButtonProps>`
  height: 54px;
  padding: 0px 44px;
  border-radius: 3px;
  transition: border 300ms ease-in, box-shadow 300ms ease-in,
    color 300ms ease-in, background-color 300ms ease-in;
  text-decoration: none;
  font-family: "Aribau-Grotesk";
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `};
  ${(props) => {
    switch (props.variant) {
      case "green":
        return css`
          background-color: #18981d;
          border: 1px solid #18981d;
          box-shadow: 0px 1px 0px rgba(227, 249, 229, 0.1),
            inset 0px 1px 0px rgba(255, 255, 255, 0.06);
          color: #fff;

          &:hover {
            border-color: #07600e;
            box-shadow: 0px 1px 2px rgba(22, 29, 37, 0.1),
              inset 0px 1px 0px rgba(255, 255, 255, 0.06);
          }

          &:active {
            background-color: #07600e;
          }

          &:disabled {
            background-color: #a7e2aa;
            border-color: transparent;
            cursor: not-allowed;
          }
        `;
      case "outline":
        return css`
          background-color: #fff;
          border: 1px solid #cbd2d9;
          box-shadow: 0px 1px 0px rgba(227, 249, 229, 0.1),
            inset 0px 1px 0px rgba(255, 255, 255, 0.06);
          color: #1f2933;

          &:hover {
            border-color: #18981d;
            box-shadow: 0px 1px 0px rgba(227, 249, 229, 0.1),
              inset 0px 1px 0px rgba(255, 255, 255, 0.06);
            color: #18981d;
          }

          &:disabled {
            cursor: not-allowed;
          }
        `;
      case "clear":
        return css`
          background-color: transparent;
          padding: 0px;
          color: #18981d;
          border: none;
          height: auto;

          &:hover {
            color: #07600e;
          }
        `;
      case "danger":
        return css`
          background-color: transparent;
          padding: 0px;
          color: #dc1e36;
          border: none;
          height: auto;

          &:hover {
            color: #ab091e;
          }
        `;
    }
  }};
`;

export default Button;
