import React, { useState, useRef } from "react";
import { useFormik } from "formik";

import ReCAPTCHA from "react-google-recaptcha";
import { Form, RecaptchaBlock } from "./styles";
import Input from "app/components/Input";
import Button from "app/components/Button";
import PasswordConditions from "../../Signup/components/PasswordConditions/index";
import { ResetPasswordPayload } from "app/api/auth/types";
import { passwordConditions } from "app/utils/regex";

interface Props {
  onSubmitForm: (password: string, token: string | null) => void;
  loading?: boolean;
  error?: string | null;
}

const ForgotPasswordForm: React.FC<Props> = (props) => {
  const recaptchaRef = useRef(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const passwordIsValid = (password: string) => {
    return (
      passwordConditions.minLengthRegex.test(password) &&
      passwordConditions.minOneUpperCaseRegex.test(password) &&
      passwordConditions.minOneSpecialRegex.test(password) &&
      passwordConditions.minOneNumberRegex.test(password)
    );
  };

  const validate = (values: ResetPasswordPayload) => {
    const errors: any = {};

    if (!values.password) {
      errors.password = "Password is required";
    } else if (!passwordIsValid(values.password)) {
      errors.password = "Password is not strong enough";
    }
    return errors;
  };
  const form = useFormik<ResetPasswordPayload>({
    initialValues: {
      password: "",
    },
    onSubmit: (values) => {
      if (!recaptchaToken) return;
      props.onSubmitForm(values.password, recaptchaToken);
      //@ts-ignore
      recaptchaRef.current.reset();
    },
    validate,
    validateOnChange: false,
  });

  const onInputFocus = (name: string) => () =>
    form.setFieldError(name, undefined);

  const onRecapthaTokenChange = (token: any) => {
    setRecaptchaToken(token);
  };

  return (
    <Form onSubmit={form.handleSubmit}>
      <Input
        fullwidth
        placeholder="Enter your password"
        label="New Password"
        type="password"
        name="password"
        id="password"
        value={form.values.password}
        onChange={form.handleChange}
        autoFocus
        error={!!form.errors.password || !!props.error}
        errorMessage={form.errors?.password || props?.error || ""}
        onFocus={onInputFocus("password")}
      />
      <PasswordConditions
        password={form.values.password}
        show={form.values.password.length !== 0}
      />
      <RecaptchaBlock>
        <ReCAPTCHA
          sitekey={process?.env?.REACT_APP_RECAPTHA_SITE_KEY || ""}
          onChange={onRecapthaTokenChange}
          ref={recaptchaRef}
        />
      </RecaptchaBlock>
      <Button
        type="submit"
        className="submit-button"
        text="Next"
        variant="green"
        loading={props.loading}
      />
    </Form>
  );
};

export default ForgotPasswordForm;
