import styled from "styled-components";

export const Text = styled.span`
  margin-left: 4px;
  font-size: 0.8rem;
`;

export const Checkmark = styled.span`
  position: relative;
  display: block;
  height: 18px;
  width: 18px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.02) 3.12%,
      rgba(97, 121, 133, 0.02) 100%
    ),
    #ffffff;
  border: 1px solid #7b8794;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(64, 82, 88, 0.032),
    0px 1px 1px rgba(97, 121, 133, 0.0528);
  border-radius: 3px;

  transition: background 100ms linear, box-shadow 100ms linear;

  &::after {
    content: "";
    display: none;
    position: absolute;
    left: 4px;
    top: 1px;
    width: 3px;
    height: 8px;
    border-radius: 2px;
    border: solid #18981d;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Container = styled.label`
  display: flex;
  align-items: center;

  & > input {
    display: none;
  }

  & > input:checked ~ ${Checkmark} {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.02) 3.12%,
        rgba(97, 121, 133, 0.02) 100%
      ),
      #ffffff;
    border: 1.5px solid #18981d;
    box-shadow: 0px 1px 1px rgba(64, 82, 88, 0.032),
      0px 1px 1px rgba(97, 121, 133, 0.0528);
  }

  & > input:checked ~ ${Checkmark}::after {
    display: block;
  }
`;
