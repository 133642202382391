import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PrevPageLink from "app/components/PrevPageLink";
import PageHeader from "app/styles/blocks/PageHeader";
import StepIndicator from "app/views/Signup/components/StepIndicator";
import { Info, Container } from "../styles";
import { IndicatorContext } from "..";
import ConfirmPhoneNumberForm from "app/views/Security/ChangePhoneNumber/components/ConfirmPhoneNumberForm";
import {
  finalizeUserSecurityService,
  cleanupSecurityActionState,
} from "app/redux/security/actions";
import { RootState } from "app/redux/reducers";

interface State {
  phone: string;
  country_call_code: string;
}

const ConfirmPage: React.FC = () => {
  const stepIndicatorCntxt = React.useContext(IndicatorContext);

  const dispatch = useDispatch();

  const location = useLocation<State | null>();
  const state = location.state;

  const { action } = useSelector((state: RootState) => state.security);

  const onCodeCompleted = (code: string) => {
    dispatch(
      finalizeUserSecurityService("phone", {
        code,
        phone: state?.phone,
        country_call_code: state?.country_call_code,
      })
    );
  };

  React.useEffect(() => {
    if (action.success && action.finalized) {
      dispatch(cleanupSecurityActionState());
      stepIndicatorCntxt.goToNextStep(stepIndicatorCntxt.currentStep);
    }
    return () => {
      dispatch(cleanupSecurityActionState());
    };
  }, [action, stepIndicatorCntxt, dispatch]);

  if (
    !action.initialized ||
    !action.data?.message ||
    !state?.phone ||
    !state?.country_call_code
  ) {
    return <Redirect to="/limits-and-levels" />;
  }

  return (
    <div className="page-content">
      <PrevPageLink to="/limits-and-levels" text="Limits and levels" />
      <PageHeader.Main>
        <PageHeader.Title big>Verify phone number</PageHeader.Title>
      </PageHeader.Main>
      <Container>
        <Info>
          <div className="step-indicator">
            <StepIndicator
              numberOfSteps={stepIndicatorCntxt.totalSteps}
              activeStep={stepIndicatorCntxt.currentStep}
            />
          </div>
        </Info>
        <ConfirmPhoneNumberForm
          message={action.data?.message}
          onCodeCompleted={onCodeCompleted}
          diableInputs={action.pending}
        />
      </Container>
    </div>
  );
};

export default ConfirmPage;
