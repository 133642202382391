import styled from "styled-components";

export const ErrorBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > button {
    width: auto;
  }
`;

export const Message = styled.div`
  margin-bottom: 66px;

  & > p {
    color: #616e7c;
  }
`;
