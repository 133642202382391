import React, { useEffect } from "react";
import { Redirect, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AuthLayout from "app/styles/blocks/AuthLayout";
import { SuccessPageBody } from "./styles";
import { ReactComponent as Logo } from "app/assets/img/logo.svg";
import { ReactComponent as EmailIcon } from "app/assets/img/icons/email.svg";
import Button from "app/components/Button";
import H2 from "app/styles/elements/H2";
import P from "app/styles/elements/P";
import { RootState } from "app/redux/reducers";
import {
  resendUserVerificationEmail,
  cleanupResendVerificationEmailState,
} from "app/redux/auth/actions";

const SuccessPage: React.FC = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { resendVerificationEmail: ResendVerificationEmailState } = useSelector(
    (state: RootState) => state.auth
  );

  const state: any = location.state;

  const onResendVerificationEmail = (email: string, continueFromApp: any) => {
    dispatch(resendUserVerificationEmail({ email, continueFromApp }));
  };

  // clean up resend verification email state on page load
  useEffect(() => {
    dispatch(cleanupResendVerificationEmailState());
  }, [dispatch]);

  console.log("signup success");

  if (!state || !state.email) return <Redirect to="/signup" />;

  return (
    <AuthLayout.Main>
      <SuccessPageBody>
        <AuthLayout.Header>
          <Link to="/">
            <Logo className="logo" />
          </Link>
        </AuthLayout.Header>
        <div className="icon">
          <EmailIcon />
        </div>
        <H2 medium semiBold className="title">
          You’re almost there!
        </H2>
        <P small className="description">
          Please click the link in the email sent to your email address to
          complete the registration process
        </P>
        <Button
          fullwidth
          variant="green"
          type="button"
          text="Resend verification email"
          onClick={() =>
            onResendVerificationEmail(state.email, state.continueFromApp)
          }
          loading={ResendVerificationEmailState.pending}
        />
      </SuccessPageBody>
    </AuthLayout.Main>
  );
};

export default SuccessPage;
