import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import useAuth from "app/hooks/useAuth";

const GuestRoute: React.FC<RouteProps> = (props) => {
  const isLoggedIn = useAuth();

  return (
    <Route
      {...props}
      component={() =>
        isLoggedIn ? <Redirect to="/profile" /> : <Route {...props} />
      }
    />
  );
};

export default GuestRoute;
