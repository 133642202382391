import React, { useEffect } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "app/styles/blocks/PageHeader";
import PrevPageLink from "app/components/PrevPageLink";
import ConfirmedDevices from "./components/ConfirmedDevices";
import { RootState } from "app/redux/reducers";
import LoaderBox from "app/components/LoaderBox";
import { fetchUserDevices, removeUserDevice } from "app/redux/devices/actions";
import EmptyList from "app/components/EmptyList";

const ConfirmDevicesPage: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    data,
    paginator,
    pending,
    success,
    updating,
    selectedDeviceId,
  } = useSelector((state: RootState) => state.devices);

  function fetchPreviousPage() {
    const page = paginator?.page ? paginator?.page - 1 : 1;
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  }

  function fetchNextPage() {
    const page = paginator?.page ? paginator?.page + 1 : 1;
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  }

  function handleRemoveDevice(id: string | number) {
    dispatch(removeUserDevice(id));
  }

  useEffect(() => {
    dispatch(fetchUserDevices());
  }, [dispatch]);

  useEffect(() => {
    if (location.search) {
      const queryValues = queryString.parse(location.search);
      const { page } = queryValues;
      if (page) dispatch(fetchUserDevices(page.toString()));
    }
  }, [location.search, dispatch]);

  return (
    <div className="page-content">
      <PrevPageLink text="Your devices" to="/devices" />
      <PageHeader.Main>
        <PageHeader.Title big>Confirmed devices</PageHeader.Title>
        <PageHeader.Description small>
          These devices are currently allowed to access your account
        </PageHeader.Description>
      </PageHeader.Main>
      {pending ? (
        <LoaderBox height={400} />
      ) : (
        <>
          {data.length < 1 && success ? (
            <EmptyList text="No mobile applications authorized" />
          ) : (
            <ConfirmedDevices
              devices={data}
              paginator={paginator}
              handleNextPage={fetchNextPage}
              handlePreviousPage={fetchPreviousPage}
              handleRemoveDevice={handleRemoveDevice}
              selectedDeviceId={selectedDeviceId}
              updating={updating}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ConfirmDevicesPage;
