import createActionType from "../util/createActionType";
import { GenericResponse, GenericFetchSingleItemPayload } from "app/api/types";
import { ReducerStateType } from "../reducers";
import {
  Document,
  UploadDocumentPayload,
  DocumentCountryType,
  DocumentType,
} from "app/api/documents/types";

export interface DocumentsState {
  data: Document[];
  documentTypes: DocumentType[];
  countryInfo: DocumentCountryType | null;
  selectedDocumentType: DocumentType | null;
  status: ReducerStateType;
  action: ReducerStateType;
  error: GenericResponse extends string
    ? string | null
    : GenericResponse | null;
}

export const fetchDocuments = createActionType("fetch_documents");
export const fetchDocumentTypes = createActionType("fetch_document_types");
export const fetchDocumentType = createActionType("fetch_document_type");
export const uploadDocument = createActionType("upload_document");
export const resetDocumentActionState = createActionType(
  "reset_document_action_state"
);

export interface FetchDocumentTypeAction {
  type: string;
  payload: GenericFetchSingleItemPayload;
}

export interface UploadDocumentAction {
  type: string;
  payload: UploadDocumentPayload;
}
