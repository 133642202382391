import React, { useEffect } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "app/styles/blocks/PageHeader";
import PrevPageLink from "app/components/PrevPageLink";
import MobileApps from "./components/MobileApps";
import { RootState } from "app/redux/reducers";
import LoaderBox from "app/components/LoaderBox";
import {
  fetchUserApplications,
  removeUserApplication,
} from "app/redux/applications/actions";
import EmptyList from "app/components/EmptyList";

const MobileAppsPage: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    data,
    paginator,
    pending,
    success,
    updating,
    selectedApplicationId,
  } = useSelector((state: RootState) => state.applications);

  function fetchPreviousPage() {
    const page = paginator?.page ? paginator?.page - 1 : 1;
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  }

  function fetchNextPage() {
    const page = paginator?.page ? paginator?.page + 1 : 1;
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  }

  function handleRemoveApplication(id: string | number) {
    dispatch(removeUserApplication(id));
  }

  useEffect(() => {
    if (location.search) {
      const queryValues = queryString.parse(location.search);
      const { page } = queryValues;
      if (page) dispatch(fetchUserApplications(page.toString()));
    }
  }, [location.search, dispatch]);

  return (
    <div className="page-content">
      <PrevPageLink text="Your devices" to="/devices" />
      <PageHeader.Main>
        <PageHeader.Title big>Mobile applications</PageHeader.Title>
        {paginator && (
          <PageHeader.Description small>
            You have authorized {paginator?.total_entries_size} mobile
            applications
          </PageHeader.Description>
        )}
      </PageHeader.Main>
      {pending ? (
        <LoaderBox height={400} />
      ) : (
        <>
          {data.length < 1 && success ? (
            <EmptyList text="No mobile applications authorized" />
          ) : (
            <MobileApps
              applications={data}
              paginator={paginator}
              handleNextPage={fetchNextPage}
              handlePreviousPage={fetchPreviousPage}
              handleRemoveApplication={handleRemoveApplication}
              selectedApplicationId={selectedApplicationId}
              updating={updating}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MobileAppsPage;
