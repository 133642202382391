import React from "react";

interface Props {
  onClickAway: () => void;
  className?: string;
  fullWidth?: boolean;
}
const ClickAway: React.FC<Props> = (props) => {
  const { onClickAway, className, fullWidth = false } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  const handleClick = (e: any) => {
    const refWrapper = ref.current;
    if (refWrapper && !refWrapper.contains(e.target)) {
      onClickAway();
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  });
  return (
    <div
      className={className}
      style={{
        width: fullWidth ? "100%" : "max-content",
        height: "auto",
      }}
      ref={ref}
    >
      {props.children}
    </div>
  );
};

export default ClickAway;
