import React from "react";
import { LinkProps } from "react-router-dom";

import { ButtonLink } from "./styles";
import { ReactComponent as Icon } from "app/assets/img/icons/chevron-left.svg";

interface Props extends LinkProps {
  text: string | JSX.Element;
}

const PrevPageLink: React.FC<Props> = props => {
  const { text, ...rest } = props;
  return (
    <ButtonLink {...(rest as any)}>
      <Icon />
      {typeof text === "string" ? <span>{text}</span> : text}
    </ButtonLink>
  );
};

export default PrevPageLink;
