import auth from "./auth";
import profile from "./profile";
import amazonS3 from "./amazonS3";
import notifications from "./notifications";
import activities from "./activities";
import applications from "./applications";
import devices from "./devices";
import sessions from "./sessions";
import security from "./security";
import documents from "./documents";

export default {
  amazonS3: amazonS3,
  authService: auth,
  profileService: profile,
  notificationsService: notifications,
  activitiesService: activities,
  applicationsService: applications,
  devicesService: devices,
  sessionsService: sessions,
  securityService: security,
  documentsService: documents,
};
