import React, { useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import PrevPageLink from "app/components/PrevPageLink";
import PageHeader from "app/styles/blocks/PageHeader";
import { Container } from "./styles";
import Input from "app/components/Input";
import Button from "app/components/Button";
import PasswordConditions from "app/views/Signup/components/PasswordConditions/index";
import { passwordConditions } from "app/utils/regex";
import { ChangePasswordPayload } from "app/api/security/types";
import {
  changeUserPassword,
  cleanupSecurityActionState,
} from "app/redux/security/actions";
import { RootState } from "app/redux/reducers";
import { logout } from "app/redux/auth/actions";

const ChangePasswordPage: React.FC = (props) => {
  const dispatch = useDispatch();
  const { action } = useSelector((state: RootState) => state.security);

  const passwordIsValid = (password: string) => {
    return (
      passwordConditions.minLengthRegex.test(password) &&
      passwordConditions.minOneUpperCaseRegex.test(password) &&
      passwordConditions.minOneSpecialRegex.test(password) &&
      passwordConditions.minOneNumberRegex.test(password)
    );
  };

  const validate = (values: ChangePasswordPayload) => {
    const errors: any = {};

    if (!values.current_password) {
      errors.current_password = "Current password is required";
    }

    if (!values.new_password) {
      errors.new_password = "New password is required";
    } else if (!passwordIsValid(values.new_password)) {
      errors.new_password = "Password is not strong enough";
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Please confirm your password";
    } else if (values.new_password !== values.confirm_password) {
      errors.new_password = "Password fields do not match";
      errors.confirm_password = "Password fields do not match";
    }
    return errors;
  };

  const form = useFormik<ChangePasswordPayload>({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    onSubmit: (values) => {
      delete values.confirm_password;
      dispatch(changeUserPassword(values));
    },
    validate,
    validateOnChange: false,
  });

  const onInputFocus = (name: string) => () =>
    form.setFieldError(name, undefined);

  useEffect(() => {
    dispatch(cleanupSecurityActionState());
    return () => {
      dispatch(cleanupSecurityActionState());
    };
  }, [dispatch]);

  useEffect(
    useCallback(() => {
      if (action.success) {
        form.resetForm();
        dispatch(logout());
      }
    }, [action.success, dispatch, form]),
    [action.success]
  );

  return (
    <div className="page-content">
      <PrevPageLink to="/security" text="Security" />
      <PageHeader.Main>
        <PageHeader.Title big>Change password</PageHeader.Title>
      </PageHeader.Main>
      <Container>
        <form onSubmit={form.handleSubmit}>
          <Input
            fullwidth
            containerClassName="form-group"
            placeholder="Enter your current password"
            label="Current Password"
            type="password"
            name="current_password"
            id="current_password"
            value={form.values.current_password}
            onChange={form.handleChange}
            autoFocus
            autoComplete="new-password"
            error={!!form.errors.current_password}
            errorMessage={form.errors.current_password}
            onFocus={onInputFocus("current_password")}
          />
          <Input
            fullwidth
            containerClassName="form-group"
            placeholder="Enter new password"
            label="New Password"
            type="password"
            name="new_password"
            id="new_password"
            value={form.values.new_password}
            onChange={form.handleChange}
            autoComplete="new-password"
            error={!!form.errors.new_password}
            errorMessage={form.errors.new_password}
            onFocus={onInputFocus("new_password")}
          />
          <PasswordConditions
            password={form.values.new_password}
            show={form.values.new_password.length !== 0}
          />
          <Input
            fullwidth
            containerClassName="form-group"
            label="Confirm new password"
            placeholder="Enter new password again"
            type="password"
            name="confirm_password"
            id="confirm_password"
            value={form.values.confirm_password}
            onChange={form.handleChange}
            autoComplete="new-password"
            error={!!form.errors.confirm_password}
            errorMessage={form.errors.confirm_password}
            onFocus={onInputFocus("confirm_password")}
          />
          <Button
            type="submit"
            variant="green"
            text="Change password"
            loading={action.pending}
          />
        </form>
      </Container>
    </div>
  );
};

export default ChangePasswordPage;
