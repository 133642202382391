import { logoutUser } from "../auth/types";
import {
  NotificationsState,
  fetchNotifications,
  updateNotifications,
} from "./types";

const INITIAL_STATE: NotificationsState = {
  pending: false,
  updating: false,
  error: null,
  success: false,
  data: null,
};

export default function auth(
  state = INITIAL_STATE,
  action: any
): NotificationsState {
  switch (action.type) {
    case fetchNotifications.pending:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case fetchNotifications.rejected:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };

    case fetchNotifications.fulfilled:
      return {
        ...state,
        pending: false,
        error: null,
        data: action.payload,
      };

    case updateNotifications.pending:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case updateNotifications.rejected:
      return {
        ...state,
        updating: false,
        error: action.payload,
      };

    case updateNotifications.fulfilled:
      return {
        ...state,
        updating: false,
        error: null,
      };

    case logoutUser.fulfilled:
      return INITIAL_STATE;

    default:
      return state;
  }
}
