import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import ResponsePage from "./Response";

const ResetPasswordRoutes: React.FC = (props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}/response/:message`}
        component={ResponsePage}
      />
      <Route path={`${match.path}/:token`} component={ResetPassword} />
      <Route component={() => <Redirect to="/not-found" />} exact />
    </Switch>
  );
};

export default ResetPasswordRoutes;
