import { AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import storage from "app/utils/storage";
import session from "app/utils/session";
import toast from "app/utils/toast";
import parseError from "app/utils/parseError";
import { setTokens } from "../tokens/types";
import {
  LoginResponse,
  SignupResponse,
  ResendVerificationEmailResponse,
  VerifyEmailAddressResponse,
  ResendLoginCodeResponse,
  ForgotPasswordResponse,
  ResetPasswordResponse,
} from "app/api/auth/types";

import {
  loginUser,
  LoginAction,
  loginConfirm,
  LoginConfirmAction,
  resendLoginCode,
  ResendLoginCodeAction,
  signupUser,
  SignupAction,
  resendVerificationEmail,
  ResendVerificationEmailAction,
  verifyEmailAddress,
  VerifyEmailAddressAction,
  logoutUser,
  ForgotPasswordAction,
  forgotPassword,
  ResetPasswordAction,
  resetPassword,
} from "./types";

function* loginSaga(action: LoginAction) {
  try {
    const { payload } = action;
    yield put({ type: loginUser.pending });

    const res: AxiosResponse<LoginResponse> = yield call(
      api.authService.login,
      payload
    );
    const data = res.data;
    if (data.access_token && data.status === "success") {
      yield put({ type: setTokens.default, payload: data });
    }
    yield put({ type: loginUser.fulfilled, payload: data });
  } catch (error) {
    const errorResponse = parseError(error, { useErrorResponse: true });
    yield put({ type: loginUser.rejected, payload: errorResponse });
  }
}

function* loginConfirmSaga(action: LoginConfirmAction) {
  try {
    const { payload } = action;
    yield put({ type: loginConfirm.pending });

    const res: AxiosResponse<LoginResponse> = yield call(
      api.authService.loginConfirm,
      payload
    );
    const data = res.data;
    yield put({ type: setTokens.default, payload: data });
    yield put({ type: loginConfirm.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({ type: loginConfirm.rejected, payload: errorMessage });
  }
}

function* signupSaga(action: SignupAction) {
  try {
    const { payload } = action;
    yield put({ type: signupUser.pending });

    const res: AxiosResponse<SignupResponse> = yield call(
      api.authService.signup,
      payload
    );
    yield put({ type: signupUser.fulfilled, payload: res.data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: signupUser.rejected, payload: errorMessage });
  }
}

function* resendLoginCodeSaga(action: ResendLoginCodeAction) {
  try {
    const { payload } = action;
    yield put({ type: resendLoginCode.pending });

    const res: AxiosResponse<ResendLoginCodeResponse> = yield call(
      api.authService.resendLoginCode,
      payload
    );
    const { data } = res;
    yield put({ type: resendLoginCode.fulfilled, payload: data });
    yield call(toast, data?.message || "Login code has been resent", {
      variant: "success",
    });
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({
      type: resendLoginCode.rejected,
      payload: errorMessage,
    });
  }
}

function* forgotPasswordSaga(action: ForgotPasswordAction) {
  try {
    const { payload } = action;
    yield put({ type: forgotPassword.pending });

    const res: AxiosResponse<ForgotPasswordResponse> = yield call(
      api.authService.forgotPassword,
      payload
    );
    yield put({ type: forgotPassword.fulfilled, payload: res.data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({
      type: forgotPassword.rejected,
      payload: errorMessage,
    });
  }
}

function* resetPasswordSaga(action: ResetPasswordAction) {
  try {
    const { payload } = action;
    yield put({ type: resetPassword.pending });

    const res: AxiosResponse<ResetPasswordResponse> = yield call(
      api.authService.resetPassword,
      payload
    );
    yield put({ type: resetPassword.fulfilled, payload: res.data });
  } catch (error) {
    const errorMessage = parseError(error, { useErrorResponse: true });
    yield put({
      type: resetPassword.rejected,
      payload: errorMessage,
    });
  }
}

function* resendVerificationEmailSaga(action: ResendVerificationEmailAction) {
  try {
    const { payload } = action;
    yield put({ type: resendVerificationEmail.pending });

    const res: AxiosResponse<ResendVerificationEmailResponse> = yield call(
      api.authService.resendVerificationEmail,
      payload
    );
    const { data } = res;
    yield put({ type: resendVerificationEmail.fulfilled, payload: data });
    yield call(
      toast,
      data?.message || `Verification link has been resent to ${payload.email}`,
      {
        variant: "success",
      }
    );
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({
      type: resendVerificationEmail.rejected,
      payload: errorMessage,
    });
  }
}

function* verifyEmailAddressSaga(action: VerifyEmailAddressAction) {
  try {
    const { payload } = action;
    yield put({ type: verifyEmailAddress.pending });

    const res: AxiosResponse<VerifyEmailAddressResponse> = yield call(
      api.authService.verifyEmailAddress,
      payload
    );
    yield put({ type: verifyEmailAddress.fulfilled, payload: res.data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({
      type: verifyEmailAddress.rejected,
      payload: errorMessage,
    });
  }
}

function* logoutSaga() {
  try {
    yield put({ type: logoutUser.pending });
    yield call(api.authService.invalidateToken);
    yield call(storage.clear);
    yield call(session.clear);
    yield put({ type: logoutUser.fulfilled });
  } catch (error) {
    yield call(session.clear);
    yield call(storage.clear);
    yield put({ type: logoutUser.fulfilled });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(loginUser.default, loginSaga),
    takeLatest(loginConfirm.default, loginConfirmSaga),
    takeLatest(resetPassword.default, resetPasswordSaga),
    takeLatest(forgotPassword.default, forgotPasswordSaga),
    takeLatest(signupUser.default, signupSaga),
    takeLatest(resendVerificationEmail.default, resendVerificationEmailSaga),
    takeLatest(resendLoginCode.default, resendLoginCodeSaga),
    takeLatest(verifyEmailAddress.default, verifyEmailAddressSaga),
    takeLatest(logoutUser.default, logoutSaga),
  ]);
}
