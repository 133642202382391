import React from "react";
import {
  getDeviceFingerprintHash,
  getOsAndBrowsername,
} from "app/utils/deviceSignature";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import DevicesPage from "./Devices";
import WebSessionsPage from "./WebSessionsPage";
import MobileAppsPage from "./MobileAppsPage";
import ConfirmDevicesPage from "./ConfirmedDevicesPage";

export const DeviceContext = React.createContext({
  name: "",
  id: "",
});

const Devices: React.FC = (props) => {
  const match = useRouteMatch();

  const [state, setState] = React.useState({
    currentDeviceId: "",
    currenctDeviceName: "",
  });

  React.useEffect(() => {
    async function getCurrentDeviceDetails() {
      const deviceName = getOsAndBrowsername();
      const deviceId = await getDeviceFingerprintHash();
      setState({
        currenctDeviceName: deviceName,
        currentDeviceId: deviceId,
      });
    }

    getCurrentDeviceDetails();
  }, []);

  return (
    <DeviceContext.Provider
      value={{
        name: state.currenctDeviceName,
        id: state.currentDeviceId,
      }}
    >
      <Switch>
        <Route path={`${match.path}`} component={DevicesPage} exact />
        <Route path={`${match.path}/mobile`} component={MobileAppsPage} exact />
        <Route path={`${match.path}/web`} component={WebSessionsPage} exact />
        <Route
          path={`${match.path}/devices`}
          component={ConfirmDevicesPage}
          exact
        />
      </Switch>
    </DeviceContext.Provider>
  );
};

export default Devices;
