import React, { useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SmsContainer, Section, CodeInputBox } from "./styles";
import P from "app/styles/elements/P";
import Button from "app/components/Button";
import CodeInput from "app/components/CodeInput";
import {
  finalizeUserSecurityService,
  cleanupSecurityActionState,
} from "app/redux/security/actions";
import { RootState } from "app/redux/reducers";

const EnableSmsPage: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { action } = useSelector((state: RootState) => state.security);

  const onCodeCompleted = (code: string) => {
    dispatch(
      finalizeUserSecurityService("two-step", {
        code,
        medium: "sms",
      })
    );
  };

  useEffect(() => {
    if (action.success && action.finalized) {
      dispatch(cleanupSecurityActionState());
      history.push("/security");
    }
    return () => {
      dispatch(cleanupSecurityActionState());
    };
  }, [action, history, dispatch]);

  if (!action.initialized || !action.data?.message) {
    return <Redirect to="/security" />;
  }

  return (
    <SmsContainer>
      <Section>
        <P medium semiBold>
          Enter authentication code
        </P>
        <P small className="description">
          {action.data?.message}
        </P>
      </Section>

      <CodeInputBox>
        <CodeInput onCodeCompleted={onCodeCompleted} />
      </CodeInputBox>

      <Button
        variant="green"
        className="link"
        text="Enable"
        loading={action.pending}
      />
    </SmsContainer>
  );
};

export default EnableSmsPage;
