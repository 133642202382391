import React from "react";
import { format } from "date-fns";
import { Container, ItemName, ItemDate } from "./styles";
import P from "app/styles/elements/P";
import StatusBadge from "app/components/StatusBadge";
import { Document } from "app/api/documents/types";

const DocumentItem: React.FC<Document> = ({ document, status, created_at }) => {
  const date = created_at
    ? format(new Date(created_at), "MMMM dd, yyyy 'at' hh:mm a")
    : "-";

  return (
    <Container>
      <div className="info">
        <ItemName>
          <P>{document}</P>
        </ItemName>
        <ItemDate>{date}</ItemDate>
      </div>
      {status && <StatusBadge status={status} />}
    </Container>
  );
};

export default DocumentItem;
