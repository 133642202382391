import styled, { css } from "styled-components";

interface Props {
  height?: number;
}

export const LoaderBoxContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #7b8794;
  font-size: 18px;
  text-align: center;
  & > * {
    margin: 5px;
  }
  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
`;
