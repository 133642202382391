import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import PhoneNumberPage from "./Phone";
import ConfirmPage from "./Confirm";

const PhoneRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={PhoneNumberPage} exact />
      <Route path={`${match.path}/confirm`} component={ConfirmPage} exact />
    </Switch>
  );
};

export default PhoneRoutes;
