import { AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/utils/parseError";
import toast from "app/utils/toast";
import session from "app/utils/session";
import {
  FetchSecurityResponse,
  InitializeSecurityServiceResponse,
} from "app/api/security/types";
import { GenericResponse } from "app/api/types";
import {
  fetchSecurity,
  changePassword,
  initializeSecurityService,
  finalizeSecurityService,
  deactivateTwoStepAuth,
  InitializeSecurityServiceAction,
  FinalizeSecurityServiceAction,
  ChangePasswordAction,
} from "./types";

function* fetchSecuritySaga() {
  try {
    yield put({ type: fetchSecurity.pending });

    const res: AxiosResponse<FetchSecurityResponse> = yield call(
      api.securityService.fetchSecurity
    );
    const data = res.data;
    yield call(session.set, "security", data);
    yield put({ type: fetchSecurity.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: fetchSecurity.rejected, payload: errorMessage });
  }
}

function* InitializeSecurityServiceSaga(
  action: InitializeSecurityServiceAction
) {
  try {
    yield put({ type: initializeSecurityService.pending });

    const { payload, service } = action;

    const res: AxiosResponse<InitializeSecurityServiceResponse> = yield call(
      api.securityService.initializeSecurityService,
      service,
      payload
    );
    const data = res.data;
    yield put({ type: initializeSecurityService.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({
      type: initializeSecurityService.rejected,
      payload: errorMessage,
    });
  }
}

function* finalizeSecurityServiceSaga(action: FinalizeSecurityServiceAction) {
  try {
    yield put({ type: finalizeSecurityService.pending });

    const { payload, service } = action;

    const res: AxiosResponse<GenericResponse> = yield call(
      api.securityService.finalizeSecurityService,
      service,
      payload
    );
    const data = res.data;
    yield put({ type: finalizeSecurityService.fulfilled, payload: data });
    yield call(toast, data?.message || "Process completed", {
      variant: "success",
    });
    yield put({ type: fetchSecurity.default });
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({
      type: finalizeSecurityService.rejected,
      payload: errorMessage,
    });
  }
}

function* deactivateTwoStepAuthSaga() {
  try {
    yield put({ type: deactivateTwoStepAuth.pending });

    const res: AxiosResponse<GenericResponse> = yield call(
      api.securityService.deactivateTwoStepAuth
    );
    const data = res.data;
    yield put({ type: deactivateTwoStepAuth.fulfilled, payload: data });
    yield call(toast, data?.message || "Two step authentication disabled", {
      variant: "success",
    });
    yield put({ type: fetchSecurity.default });
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({
      type: deactivateTwoStepAuth.rejected,
      payload: errorMessage,
    });
  }
}

function* changePasswordSaga(action: ChangePasswordAction) {
  try {
    yield put({ type: changePassword.pending });

    const { payload } = action;

    const res: AxiosResponse<GenericResponse> = yield call(
      api.securityService.changePassword,
      payload
    );
    const data = res.data;
    yield put({ type: changePassword.fulfilled });
    yield call(toast, data?.message || "Password changed successfully", {
      variant: "success",
    });
  } catch (error) {
    const errorMessage = parseError(error);
    yield call(toast, errorMessage, { variant: "error" });
    yield put({
      type: changePassword.rejected,
      payload: errorMessage,
    });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchSecurity.default, fetchSecuritySaga),
    takeLatest(
      initializeSecurityService.default,
      InitializeSecurityServiceSaga
    ),
    takeLatest(finalizeSecurityService.default, finalizeSecurityServiceSaga),
    takeLatest(deactivateTwoStepAuth.default, deactivateTwoStepAuthSaga),
    takeLatest(changePassword.default, changePasswordSaga),
  ]);
}
