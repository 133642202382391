import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Container, TitleBox, DetailItem, DetailsBox } from "./styles";
import P from "app/styles/elements/P";
import { UserProfileType } from "app/api/profile/types";

interface Props {
  userProfile: UserProfileType | null;
  fetching?: boolean;
}

const PersonalDetails: React.FC<Props> = ({ userProfile, fetching }) => {
  const dateOfBirth = userProfile?.date_of_birth
    ? format(new Date(userProfile.date_of_birth), "dd MMMM, yyyy")
    : "-";

  return (
    <Container>
      <TitleBox>
        <P medium semiBold>
          Personal details
        </P>
        <Link to="/profile/edit">Edit profile</Link>
      </TitleBox>
      <DetailsBox>
        <DetailItem>
          <P little className="title">
            First name
          </P>
          <P medium className="value">
            {userProfile?.first_name || "-"}
          </P>
        </DetailItem>
        <DetailItem>
          <P little className="title">
            Last name
          </P>
          <P medium className="value">
            {userProfile?.last_name || "-"}
          </P>
        </DetailItem>
      </DetailsBox>
      <DetailsBox>
        <DetailItem>
          <P little className="title">
            Gender
          </P>
          <P medium className="value">
            {userProfile?.gender || "-"}
          </P>
        </DetailItem>
        <DetailItem>
          <P little className="title">
            Date of birth
          </P>
          <P medium className="value">
            {dateOfBirth}
          </P>
        </DetailItem>
      </DetailsBox>
      <DetailsBox>
        <DetailItem>
          <P little className="title">
            Street Address 1
          </P>
          <P medium className="value">
            {userProfile?.street_address_1 || "-"}
          </P>
        </DetailItem>
        <DetailItem>
          <P little className="title">
            Street Address 2
          </P>
          <P medium className="value">
            {userProfile?.street_address_2 || "-"}
          </P>
        </DetailItem>
      </DetailsBox>
      <DetailsBox>
        <DetailItem>
          <P little className="title">
            City
          </P>
          <P medium className="value">
            {userProfile?.city || "-"}
          </P>
        </DetailItem>
        <DetailItem>
          <P little className="title">
            State
          </P>
          <P medium className="value">
            {userProfile?.state || "-"}
          </P>
        </DetailItem>
      </DetailsBox>
      <DetailsBox>
        <DetailItem>
          <P little className="title">
            Country
          </P>
          <P medium className="value">
            {userProfile?.country || "-"}
          </P>
        </DetailItem>
      </DetailsBox>
    </Container>
  );
};

export default PersonalDetails;
