import React, { InputHTMLAttributes } from "react";

import FormInput from "app/styles/blocks/FormInput";

interface Props extends InputHTMLAttributes<any> {
  fullwidth?: boolean;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  containerClassName?: string;
}

const Input: React.FC<Props> = props => {
  const { fullwidth, label, errorMessage, containerClassName, ...rest } = props;

  return (
    <FormInput.Main className={containerClassName} fullwidth>
      {!!label && <FormInput.Label htmlFor={rest.id}>{label}</FormInput.Label>}
      <FormInput.Input fullwidth {...rest} />
      {errorMessage && <FormInput.ErrorMsg>{errorMessage}</FormInput.ErrorMsg>}
    </FormInput.Main>
  );
};

export default Input;
