import styled from "styled-components";

import Input from "./Input";
import Label from "./Label";
import ErrorMsg from "./ErrorMsg";
import Select from "./Select";

interface Props {
  fullwidth?: boolean;
}

const Main = styled.div<Props>`
  width: ${props => (props.fullwidth ? "100%" : "100px")};
`;

const FormInput = {
  Main,
  Input,
  Label,
  ErrorMsg,
  Select
};

export default FormInput;
