import styled from "styled-components";
import media from "app/styles/utils/media";

const Sidebar = styled.aside`
  width: 243px;
  height: 100%;
  position: fixed;

  ${media.smallDesktop`
    display: none;
  `};
`;

export default Sidebar;
