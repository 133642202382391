import styled from "styled-components";

import Header from "./Header";
import Body from "./Body";

import media from "app/styles/utils/media";

export interface AuthLayoutProps {
  signup?: boolean;
}
const Main = styled.section<AuthLayoutProps>`
  width: 100%;
  height: 100%;
  padding: ${props => (!props.signup ? "160px 0px" : "52px 58px")};

  ${media.mobile`
    padding: 64px 20px;
  `};
`;

const AuthLayout = {
  Main,
  Header,
  Body
};

export default AuthLayout;
