import styled from "styled-components";

import media from "app/styles/utils/media";

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  /* padding-bottom: 15px; */
  /* margin-top: 24px; */

  .divider {
    background: #cbd2d9;
    border-radius: 6px;
    width: 16px;
    height: 3px;
    margin: 0 13px;
  }
`;

export const InputGroup = styled.div`
  display: flex;

  & > input:nth-child(2) {
    border-right: none;
    border-left: none;
    border-radius: 0;
  }
  & > input:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & > input:nth-child(3) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > input {
    width: 48px;

    &:focus {
      border: 2px solid #18981d;
    }
  }

  ${media.smallMobile`
    & > input {
      width: 40px;
      padding: 0px 10px;
    }
  `};
`;
