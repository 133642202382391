import React, { ButtonHTMLAttributes } from "react";

import Btn, { ButtonProps } from "app/styles/elements/Button";
import Loader from "./Loader";

type PartialButtonProps = ButtonProps & ButtonHTMLAttributes<any>;

export interface ButtonComponentProps extends PartialButtonProps {
  text?: string;
  loading?: boolean;
}

const Button: React.FC<ButtonComponentProps> = (props) => {
  const { text, loading, disabled, ...rest } = props;
  return (
    <Btn disabled={loading || disabled} {...rest}>
      {loading ? (
        <Loader
          color={
            ["clear", "danger"].includes(props?.variant || "")
              ? "#18981d"
              : undefined
          }
        />
      ) : (
        text || ""
      )}
    </Btn>
  );
};

export default Button;
