import React from "react";
import { useFormik } from "formik";

import { Form } from "./styles";
import Input from "app/components/Input";
import Button from "app/components/Button";
import { Title, EmailBlock } from "../styles";
import H2 from "app/styles/elements/H2";
import P from "app/styles/elements/P";
import api from "app/api";

interface Props {
  onDone: (u: string) => void;
  onChangeName: () => void;
  fullName: string;
}

type UsernameFormType = {
  username: string;
};

const UsernameForm: React.FC<Props> = props => {
  const { onDone, onChangeName, fullName } = props;

  const validate = (values: UsernameFormType) => {
    const errors: any = {};

    if (!values.username) {
      errors.username = "Username is required";
    }

    return errors;
  };
  const form = useFormik<UsernameFormType>({
    initialValues: {
      username: ""
    },
    onSubmit: async values => {
      try {
        await api.authService.validateCredential({
          name: "username",
          value: values.username
        });
        // if the request is successful that means the username exists
        form.errors.username = `${values.username} is not available`;
      } catch (error) {
        // the request failing means that the username is free
        onDone(values.username);
      }
    },
    validate,
    validateOnChange: true
  });
  return (
    <>
      <Title>
        <H2>Personal Information</H2>
        <P small>
          Please make sure the details are correct as they will be used to
          verify your identity.
        </P>
      </Title>
      <EmailBlock>
        <P small>{fullName}</P>
        <button onClick={onChangeName}>Change legal name</button>
      </EmailBlock>
      <Form onSubmit={form.handleSubmit}>
        <Input
          fullwidth
          placeholder="Enter your username"
          label="Username"
          name="username"
          id="username"
          value={form.values.username}
          onChange={form.handleChange}
          autoFocus
          error={!!form.errors.username}
          errorMessage={form.errors.username}
        />
        <Button
          type="submit"
          className="submit-button"
          text="Next"
          variant="green"
          disabled={form.isSubmitting}
        />
      </Form>
    </>
  );
};

export default UsernameForm;
