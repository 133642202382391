import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "app/styles/blocks/PageHeader";
import PrevPageLink from "app/components/PrevPageLink";
import { Section, SectionInfo } from "../components/styles";
import Button from "app/components/Button";
import ButtonLink from "app/styles/elements/Button";
import {
  AuthTypeBox,
  AuthTypeImg,
  AuthTypeInfo,
  SectionAction,
} from "./styles";
import P from "app/styles/elements/P";
import { ReactComponent as AuthAppIcon } from "app/assets/img/icons/auth-app.svg";
import { ReactComponent as SmsIcon } from "app/assets/img/icons/sms.svg";
import {
  initializeUserSecurityService,
  cleanupSecurityActionState,
} from "app/redux/security/actions";
import { RootState } from "app/redux/reducers";

const TwoStepAuthPage: React.FC = (props) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();

  const [medium, setMedium] = useState<string>("");

  const { action, data: securityData } = useSelector(
    (state: RootState) => state.security
  );

  const onActivateTwoStep = (medium: string) => {
    setMedium(medium);
    dispatch(initializeUserSecurityService("two-step", { medium }));
  };

  useEffect(() => {
    dispatch(cleanupSecurityActionState());
  }, [dispatch]);

  useEffect(() => {
    if (action.success && action.initialized) {
      if (medium === "authenticator") {
        history.push(`${match.path}/auth-app`);
      }
      if (medium === "sms") {
        history.push(`${match.path}/sms/enable`);
      }
    }
  }, [action, history, match.path, medium]);

  return (
    <div className="page-content">
      <PrevPageLink to="/security" text="Security" />
      <PageHeader.Main>
        <PageHeader.Title big>Add a two-step method</PageHeader.Title>
        <PageHeader.Description small>
          Review your accounts to make sure they are as secure as pssible.
        </PageHeader.Description>
      </PageHeader.Main>
      <Section>
        <SectionInfo>
          <AuthTypeBox>
            <AuthTypeImg>
              <AuthAppIcon />
            </AuthTypeImg>
            <AuthTypeInfo>
              <P medium semiBold>
                Authenticator app
              </P>
              <P small className="description">
                Use an app to generate an authentication code when you log in
              </P>
            </AuthTypeInfo>
          </AuthTypeBox>
        </SectionInfo>
        <SectionAction>
          <Button
            className="two-step-link"
            variant="green"
            text="Select"
            onClick={() => onActivateTwoStep("authenticator")}
            loading={action.pending && medium === "authenticator"}
          />
        </SectionAction>
      </Section>
      <Section>
        <SectionInfo>
          <AuthTypeBox>
            <AuthTypeImg>
              <SmsIcon />
            </AuthTypeImg>
            <AuthTypeInfo>
              <P medium semiBold>
                SMS authentication
              </P>
              <P small className="description">
                Receive an SMS/text message with an authentication code when you
                log in
              </P>
            </AuthTypeInfo>
          </AuthTypeBox>
        </SectionInfo>
        <SectionAction>
          {securityData?.phone ? (
            <Button
              className="two-step-link"
              variant="green"
              text="Select"
              onClick={() => onActivateTwoStep("sms")}
              loading={action.pending && medium === "sms"}
            />
          ) : (
            <ButtonLink
              as={Link}
              to="/security/phone"
              variant="green"
              className="two-step-link"
            >
              Select
            </ButtonLink>
          )}
        </SectionAction>
      </Section>
    </div>
  );
};

export default TwoStepAuthPage;
