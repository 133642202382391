import React from "react";

import PageHeader from "app/styles/blocks/PageHeader";
import PrevPageLink from "app/components/PrevPageLink";
import { EmptyContainer } from "../styles";

const DocumentsRequests: React.FC = (props) => {
  return (
    <div className="page-content">
      <PrevPageLink to="/documents" text="Documents" />
      <PageHeader.Main>
        <PageHeader.Title big>Documents Requests</PageHeader.Title>
      </PageHeader.Main>
      <EmptyContainer>No documents found.</EmptyContainer>
    </div>
  );
};

export default DocumentsRequests;
