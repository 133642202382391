export default [
  {
    name: "General",
    path: "/profile",
    exact: false,
  },
  {
    name: "Communications",
    path: "/communications",
    exact: false,
  },
  {
    name: "Security",
    path: "/security",
    exact: false,
  },
  {
    name: "Your Devices",
    path: "/devices",
    exact: false,
  },
  {
    name: "Limits and levels",
    path: "/limits-and-levels",
    exact: false,
  },
  {
    name: "Account activities",
    path: "/activities",
    exact: false,
  },
  {
    name: "Documents",
    path: "/documents",
    exact: false,
  },
];
