import styled, { css } from "styled-components";

export const LevelBox = styled.div`
  display: flex;
  align-items: stretch;
`;

export const PipeSection = styled.div`
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
interface PipeComponentProps {
  active: boolean;
  done?: boolean;
}
export const Circle = styled.div<PipeComponentProps>`
  width: 32px;
  height: 32px;
  border: 4px solid #cbd2d9;
  border-radius: 16px;
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    display: none;
  }

  ${(props) =>
    props.active &&
    css`
      border-color: #18981d;
    `};

  ${(props) =>
    props.done &&
    css`
      border-color: #18981d;
      background: #18981d;
      & > svg {
        display: inline-block;
      }
    `};
`;
export const Line = styled.div<PipeComponentProps>`
  width: 4px;
  height: calc(100% - 32px);
  background: #cbd2d9;

  ${(props) =>
    props.active &&
    css`
      background: #18981d;
    `};
`;

export const TextSection = styled.div`
  padding-bottom: 47px;
  .name {
    color: #3e4c59;
    margin-bottom: 24px;
  }
  .items {
    margin-top: 29px;
  }

  & > button {
    margin-top: 32px;
  }
`;
export const LimitValues = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;

  & > :first-child {
    margin-bottom: 22px;
  }

  .title {
    color: #616e7c;
    margin-bottom: 8px;
  }
  .value {
    color: #3e4c59;

    .per-month {
      color: #7b8794;
      font-size: 0.7rem;
      font-weight: normal;
    }
  }
`;
export const LevelItem = styled.div`
  display: flex;
  align-items: center;
  color: #616e7c;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & > svg {
    height: 14px;
    width: 14px;
    margin-right: 7px;
  }
`;
