import React from "react";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PrevPageLink from "app/components/PrevPageLink";
import PageHeader from "app/styles/blocks/PageHeader";
import ConfirmPhoneNumberForm from "./components/ConfirmPhoneNumberForm";
import {
  finalizeUserSecurityService,
  cleanupSecurityActionState,
} from "app/redux/security/actions";
import { RootState } from "app/redux/reducers";

interface State {
  phone: string;
  country_call_code: string;
}

const ConfirmPage: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation<State | null>();
  const state = location.state;

  const { action } = useSelector((state: RootState) => state.security);

  const onCodeCompleted = (code: string) => {
    dispatch(
      finalizeUserSecurityService("phone", {
        code,
        phone: state?.phone,
        country_call_code: state?.country_call_code,
      })
    );
  };

  React.useEffect(
    React.useCallback(() => {
      if (action.success && action.finalized) {
        dispatch(cleanupSecurityActionState());
        history.push("/security");
      }
      return () => {
        dispatch(cleanupSecurityActionState());
      };
    }, [action, history, dispatch]),
    [action.success, action.finalized]
  );

  if (
    !action.initialized ||
    !action.data?.message ||
    !state?.phone ||
    !state?.country_call_code
  ) {
    return <Redirect to="/security" />;
  }

  return (
    <div className="page-content">
      <PrevPageLink to="/security" text="Security" />
      <PageHeader.Main>
        <PageHeader.Title big>Verify phone number</PageHeader.Title>
      </PageHeader.Main>
      <ConfirmPhoneNumberForm
        message={action.data?.message}
        onCodeCompleted={onCodeCompleted}
        diableInputs={action.pending}
      />
    </div>
  );
};

export default ConfirmPage;
