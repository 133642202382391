import React from "react";
import { Link, useRouteMatch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import QRCode from "qrcode.react";
import { AuthAppContainer, Section, QRBox } from "./styles";
import P from "app/styles/elements/P";
import Button from "app/styles/elements/Button";
import { RootState } from "app/redux/reducers";

const AuthAppPage: React.FC = (props) => {
  const match = useRouteMatch();

  const { action } = useSelector((state: RootState) => state.security);

  if (!action.initialized && !action.data?.qr && !action.data?.secret) {
    return <Redirect to="/security" />;
  }

  return (
    <AuthAppContainer>
      <Section>
        <P medium semiBold>
          Install an authenticator app
        </P>
        <P small className="description">
          Receive a notification for all deposits.
        </P>
      </Section>
      <QRBox>
        <QRCode
          value={action.data?.qr || ""}
          renderAs="svg"
          className="qr-code"
        />
      </QRBox>
      <Section>
        <P medium semiBold>
          Configure the app
        </P>
        <P small className="description">
          Open the authenticator app on your mobile device and scan the QR Code.
        </P>
      </Section>
      <Section>
        <P small className="description">
          Can’t scan the QR Code? Configure your app with this key:
        </P>
      </Section>
      <Section>
        <P small className="description bold code">
          {action.data?.secret}
        </P>
      </Section>
      <Button
        as={Link}
        to={`${match.path}/enable`}
        variant="green"
        className="link"
      >
        Next
      </Button>
    </AuthAppContainer>
  );
};

export default AuthAppPage;
