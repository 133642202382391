import { combineReducers } from "redux";
import { StateType } from "typesafe-actions";

import auth from "./auth";
import tokens from "./tokens";
import profile from "./profile";
import notifications from "./notifications";
import activities from "./activities";
import applications from "./applications";
import devices from "./devices";
import sessions from "./sessions";
import security from "./security";
import documents from "./documents";

const RootReducer = combineReducers({
  activities,
  applications,
  auth,
  devices,
  documents,
  notifications,
  profile,
  security,
  sessions,
  tokens,
});

export default RootReducer;

export type RootState = StateType<typeof RootReducer>;

export type ReducerStateType =
  | "fetching"
  | "fetched"
  | "error"
  | "idle"
  | "updating"
  | "updated";
