import React from "react";

import { Container, Item } from "./styles";
import { passwordConditions } from "app/utils/regex";
import P from "app/styles/elements/P";
import { ReactComponent as ValidIcon } from "app/assets/img/icons/valid.svg";
import { ReactComponent as InValidIcon } from "app/assets/img/icons/invalid.svg";

const conditions = [
  {
    name: "At least 8 characters",
    regex: passwordConditions.minLengthRegex
  },
  {
    name: "At least one upper case character",
    regex: passwordConditions.minOneUpperCaseRegex
  },
  {
    name: "At least one number",
    regex: passwordConditions.minOneNumberRegex
  },
  {
    name: "At least one number or special character (!@#&$)",
    regex: passwordConditions.minOneSpecialRegex
  }
];

interface Props {
  password: string;
  show: boolean;
}

const PasswordConditions: React.FC<Props> = props => {
  if (!props.show) return null;
  return (
    <Container>
      <P small className="title">
        Password must contain
      </P>
      {conditions.map((_c, index) => {
        const _passesTest = _c.regex.test(props.password);
        return (
          <Item valid={_passesTest} key={index}>
            {_passesTest ? <ValidIcon /> : <InValidIcon />}
            <P little>{_c.name}</P>
          </Item>
        );
      })}
    </Container>
  );
};

export default PasswordConditions;
