import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "app/styles/blocks/PageHeader";
import PrevPageLink from "app/components/PrevPageLink";
import Documents from "./Documents";
import { RootState } from "app/redux/reducers";
import LoaderBox from "app/components/LoaderBox";
import { fetchUserDocuments } from "app/redux/documents/actions";
import EmptyList from "app/components/EmptyList";
import ErrorState from "app/components/ErrorState";

const AllDocuments: React.FC = (props) => {
  const dispatch = useDispatch();

  const { data, status, error } = useSelector(
    (state: RootState) => state.documents
  );

  const getUserDocs = useCallback(() => {
    dispatch(fetchUserDocuments());
  }, [dispatch]);

  useEffect(() => {
    getUserDocs();
  }, [getUserDocs]);

  return (
    <div className="page-content">
      <PrevPageLink to="/documents" text="Documents" />
      <PageHeader.Main>
        <PageHeader.Title big>Documents</PageHeader.Title>
      </PageHeader.Main>
      {status === "fetching" && <LoaderBox height={400} />}
      {status === "fetched" && data?.length > 0 && (
        <Documents documents={data} />
      )}
      {status === "fetched" && data?.length < 1 && (
        <EmptyList text="No documents found" />
      )}
      {!!error && (
        <ErrorState
          message={error as string}
          retryButtonProps={{
            onClick: getUserDocs,
            loading: status === "fetching",
          }}
        />
      )}
    </div>
  );
};

export default AllDocuments;
