import styled from "styled-components";
import { Link } from "react-router-dom";

export const ButtonLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #3e4c59;
  font-size: 0.8rem;
  margin-bottom: 16px;
  & > svg {
    margin-right: 9px;
    height: 12px;
    width: 7px;
  }
`;
