import React, { useEffect } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "app/assets/img/logo.svg";
import AuthLayout from "app/styles/blocks/AuthLayout";
import StepIndicator from "./components/StepIndicator";
import EmailForm from "./components/EmailForm";
import ReferralForm from "./components/ReferralForm";
import PersonalInfoForm from "./components/PersonalInfoForm";
import { SignupPayload } from "app/api/auth/types";
import UsernameForm from "./components/UsernameForm";
import PasswordForm from "./components/PasswordForm";
import { RootState } from "app/redux/reducers";
import {
  signup,
  cleanupSignupState,
  cleanupSignupErrors,
} from "app/redux/auth/actions";

const Signup: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { signup: SignUpState } = useSelector((state: RootState) => state.auth);
  const NUM_OF_STEPS = 5;
  const [activeStep, setActiveStep] = React.useState(1);
  const goToStep = (n: number) => setActiveStep(n);
  const toNextStep = () =>
    setActiveStep((n) => (n === NUM_OF_STEPS ? n : n + 1));
  const toPrevStep = () => setActiveStep((n) => (n === 1 ? n : n - 1));

  // check if the user was redirected from an to the signup page
  let continueFromApp: any;
  if (location.search) {
    const queryValues = queryString.parse(location.search);
    continueFromApp = queryValues.redirect;
  }

  const [formState, setFormState] = React.useState<SignupPayload>({
    email: "",
    refCode: "",
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    token: "",
  });

  const onEmailVerified = (email: string, hasRefCode = false) => {
    setFormState((f) => ({ ...f, email, refCode: "" }));
    if (hasRefCode) {
      toNextStep();
    } else {
      goToStep(3);
    }
  };

  const onChangeEmail = () => {
    goToStep(1);
  };

  const onRefCodeVerified = (ref: string) => {
    setFormState((f) => ({ ...f, refCode: ref }));
    toNextStep();
  };

  const onPersonalInfoDone = (firstname: string, lastname: string) => {
    setFormState((f) => ({ ...f, firstname, lastname }));
    toNextStep();
  };

  const onUsernameDone = (username: string) => {
    setFormState((f) => ({ ...f, username }));
    toNextStep();
  };

  const onSubmitForm = (password: string, token: string | null) => {
    dispatch(signup({ ...formState, password, token, continueFromApp }));
  };

  const cleanSignUpErrors = () => dispatch(cleanupSignupErrors());

  // clean up signup state on page load
  useEffect(() => {
    dispatch(cleanupSignupState());
  }, [dispatch]);

  useEffect(() => {
    // if user has successfully signed in, send them to the success page
    if (SignUpState.success) {
      history.push({
        pathname: "/signup/success",
        state: { email: formState.email, continueFromApp },
      });
    }
  }, [history, SignUpState.success, formState.email, continueFromApp]);

  return (
    <AuthLayout.Main>
      <AuthLayout.Body>
        <AuthLayout.Header>
          <Logo className="logo" />
          <StepIndicator numberOfSteps={NUM_OF_STEPS} activeStep={activeStep} />
        </AuthLayout.Header>
        {activeStep === 1 && <EmailForm onEmailVerified={onEmailVerified} />}
        {activeStep === 2 && (
          <ReferralForm
            email={formState.email}
            onRefCodeVerified={onRefCodeVerified}
            onNoRefCode={toNextStep}
            onChangeEmail={toPrevStep}
          />
        )}
        {activeStep === 3 && (
          <PersonalInfoForm
            formState={formState}
            onDone={onPersonalInfoDone}
            onChangeRef={toPrevStep}
            onChangeEmail={onChangeEmail}
          />
        )}
        {activeStep === 4 && (
          <UsernameForm
            fullName={`${formState.firstname} ${formState.lastname}`}
            onDone={onUsernameDone}
            onChangeName={toPrevStep}
          />
        )}
        {activeStep === 5 && (
          <PasswordForm
            formState={formState}
            onChangeUsername={toPrevStep}
            onSubmitForm={onSubmitForm}
            submitting={SignUpState.pending}
            error={SignUpState.error}
            clearError={cleanSignUpErrors}
          />
        )}
      </AuthLayout.Body>
    </AuthLayout.Main>
  );
};

export default Signup;
