import request from "../request";
import { FetchDevicesResponse } from "./types";
import {
  GenericParamsPayload,
  GenericDeletePayload,
  GenericResponse,
} from "../types";

const fetchDevices = async (payload: GenericParamsPayload) => {
  const query = `?page=${payload.page}`;
  return await request.get<FetchDevicesResponse>(`/v3/me/devices${query}`);
};

const removeDevice = async (payload: GenericDeletePayload) => {
  return await request.delete<GenericResponse>(`/v3/me/devices/${payload.id}`);
};

export default { fetchDevices, removeDevice };
