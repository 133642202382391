import { fetchDevices, removeDevice } from "./types";

const fetchUserDevices = (page: number | string = 1) => {
  return { type: fetchDevices.default, meta: { page } };
};

const removeUserDevice = (id: number | string) => {
  return { type: removeDevice.default, payload: { id } };
};

export { fetchUserDevices, removeUserDevice };
