import { logoutUser } from "../auth/types";
import {
  ProfileState,
  fetchProfile,
  updateProfile,
  updateUsername,
  updateAvatar,
  resetProfileAction,
} from "./types";
import session from "app/utils/session";

const cachedProfile = session.get("profile");

const INITIAL_STATE: ProfileState = {
  pending: false,
  action: "idle",
  error: null,
  success: false,
  data: cachedProfile,
};

export default function auth(state = INITIAL_STATE, action: any): ProfileState {
  switch (action.type) {
    case fetchProfile.pending:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case fetchProfile.rejected:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };

    case fetchProfile.fulfilled:
      return {
        ...state,
        pending: false,
        error: null,
        data: action.payload,
      };

    case updateProfile.pending:
    case updateUsername.pending:
    case updateAvatar.pending:
      return {
        ...state,
        action: "updating",
        error: null,
      };

    case updateProfile.rejected:
    case updateUsername.rejected:
    case updateAvatar.rejected:
      return {
        ...state,
        action: "error",
        error: action.payload,
      };

    case updateProfile.fulfilled:
    case updateUsername.fulfilled:
    case updateAvatar.fulfilled:
      return {
        ...state,
        action: "updated",
        error: null,
      };

    case resetProfileAction.default:
      return {
        ...state,
        action: "idle",
      };

    case logoutUser.fulfilled:
      return INITIAL_STATE;

    default:
      return state;
  }
}
