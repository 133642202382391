import styled from "styled-components";

import media from "app/styles/utils/media";

export const Form = styled.form`
  width: 380px;
  max-width: 100%;

  .doc-id {
    margin-top: 24px;
  }

  & > button {
    width: 335px;
    max-width: 100%;
    margin-top: 36px;

    ${media.mobile`
      width: 100%;
      `};
  }
`;
