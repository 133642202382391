import React from "react";
import { Container, Item } from "./styles";

interface Props {
  numberOfSteps: number;
  activeStep: number;
}

const StepItem = ({ active = false }) => <Item active={active} />;

const StepIndicator: React.FC<Props> = props => {
  const { numberOfSteps, activeStep } = props;

  const renderItems = () => {
    const items = [];
    for (let index = 0; index < numberOfSteps; index++) {
      items.push(<StepItem key={index} active={activeStep === index + 1} />);
    }
    return items;
  };

  return <Container>{renderItems()}</Container>;
};

export default StepIndicator;
