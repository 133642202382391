import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SecurityPage from "./Security";
import TwoStepAuthPage from "./TwoStepAuth";
import ChangePasswordPage from "./ChangePassword";
import ChangePhoneNumberPage from "./ChangePhoneNumber";

const Security: React.FC = (props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={SecurityPage} exact />
      <Route
        path={`${match.path}/password`}
        component={ChangePasswordPage}
        exact
      />
      <Route path={`${match.path}/two-step-auth`} component={TwoStepAuthPage} />
      <Route path={`${match.path}/phone`} component={ChangePhoneNumberPage} />
    </Switch>
  );
};

export default Security;
