import styled from "styled-components";

export const PageSection = styled.section`
  width: 100%;
  border-bottom: 1px solid rgba(211, 213, 216, 0.5);
  padding-bottom: 35px;
`;

export const EditProfileTitleBox = styled.div`
  display: flex;
  align-items: center;
  padding: 37px 0px 24px;
`;

export const UsernameForm = styled.form`
  padding: 36px 0px;
  width: 380px;
  max-width: 100%;

  p {
    margin-top: 8px;

    & > span {
      color: #18981d;
    }
  }

  button {
    margin-top: 32px;
  }
`;
