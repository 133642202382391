import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { LevelProps } from "app/views/LimitsAndLevels/LimitsAndLevels/components/Level";

interface Props {
  level?: string | number;
  bvn?: string | number;
  phone?: string | number;
  completeProfile?: boolean;
}

export default function useLevelProps({
  level = "0",
  bvn,
  phone,
  completeProfile,
}: Props) {
  const history = useHistory();
  const match = useRouteMatch();
  const [levelProps, setLevelProps] = React.useState<LevelProps[]>([]);

  const [state, setState] = React.useState({
    currentLevel: parseInt(String(level)),
    hasAddedBvn: !!bvn,
    hasAddedPhonenumber: !!phone,
  });

  const level1Step = completeProfile ? (phone ? "bvn" : "phone") : "profile";

  React.useEffect(() => {
    setState({
      currentLevel: parseInt(String(level)),
      hasAddedBvn: !!bvn,
      hasAddedPhonenumber: !!phone,
    });
  }, [level, bvn, phone, setState]);

  React.useEffect(() => {
    const levels: LevelProps[] = [
      {
        name: "Level 1",
        actionButtonText: "Start verification",
        maxCardDeposit: "₦ 200,000",
        maxBankTransfer: "₦ 200,000",
        hideActionButton: state.currentLevel !== 0,
        onActionButtonClicked: () =>
          history.push(`${match.path}/verify/${level1Step}`),
        status:
          state.hasAddedPhonenumber && state.hasAddedBvn ? "done" : "active",
        items: [
          {
            name: "Verify phone number",
            done: state.hasAddedPhonenumber,
          },
          {
            name: "Verify BVN",
            done: state.hasAddedBvn,
          },
        ],
      },
      {
        name: "Level 2",
        actionButtonText: "Start verification",
        hideActionButton: state.currentLevel !== 1,
        maxCardDeposit: "₦ 1,000,000",
        maxBankTransfer: "₦ 1,000,000",
        maxValuesinMonths: true,
        onActionButtonClicked: () => history.push(`${match.path}/documents`),
        status:
          state.currentLevel < 1
            ? "inactive"
            : state.currentLevel >= 2
            ? "done"
            : "active",
        items: [
          {
            name: "Verify identity",
            done: state.currentLevel >= 2,
          },
        ],
      },
      {
        name: "Level 3",
        actionButtonText: "Start verification",
        hideActionButton: state.currentLevel !== 2,
        maxCardDeposit: "₦ 10,000,000",
        maxBankTransfer: "₦ 10,000,000",
        maxValuesinMonths: true,
        onActionButtonClicked: () => history.push(`${match.path}/documents`),
        status:
          state.currentLevel < 2
            ? "inactive"
            : state.currentLevel >= 3
            ? "done"
            : "active",
        items: [
          {
            name: "Verify residential address",
            done: state.currentLevel >= 3,
          },
        ],
        hidePipeLine: true,
      },
    ];

    setLevelProps(levels);
  }, [history, level1Step, match.path, state]);

  return levelProps;
}
