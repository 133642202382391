import React from "react";
import useAuth from "app/hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "app/styles/blocks/DashboardLayout";
import Sidebar from "./Sidebar";
import Topnav from "./Topnav";
import MobileSidebar from "./Sidebar/Mobile";
import { fetchUserProfile } from "app/redux/profile/actions";
import { fetchUserSecurity } from "app/redux/security/actions";
import { RootState } from "app/redux/reducers";
import { MobileAppContext } from "./MobileAppAuth";

const DashboardRoutesWrapper: React.FC = (props) => {
  const dispatch = useDispatch();

  const mobileAppContext = React.useContext(MobileAppContext);

  const [showMobileSidebar, setShowMobileSidebar] = React.useState(false);

  const openMobileSidebar = () => setShowMobileSidebar(true);
  const closeMobileSidebar = () => setShowMobileSidebar(false);

  const { data: userProfile } = useSelector(
    (state: RootState) => state.profile
  );
  const { data: userSecurityData } = useSelector(
    (state: RootState) => state.security
  );

  const isLoggedIn = useAuth();

  React.useEffect(
    React.useCallback(() => {
      if (isLoggedIn) {
        if (!userProfile) {
          dispatch(fetchUserProfile());
        }
        if (!userSecurityData) {
          dispatch(fetchUserSecurity());
        }
      }
    }, [dispatch, userProfile, userSecurityData, isLoggedIn]),
    []
  );

  return (
    <DashboardLayout.Main>
      {!mobileAppContext.isWebView && (
        <>
          <MobileSidebar
            show={showMobileSidebar}
            onMobileCloseSidebar={closeMobileSidebar}
          />
          <DashboardLayout.Header>
            <Topnav onMobileBtnclicked={openMobileSidebar} />
          </DashboardLayout.Header>
        </>
      )}
      <DashboardLayout.Body>
        <DashboardLayout.Sidebar>
          <Sidebar />
        </DashboardLayout.Sidebar>
        <DashboardLayout.Content>{props.children}</DashboardLayout.Content>
      </DashboardLayout.Body>
    </DashboardLayout.Main>
  );
};

export default DashboardRoutesWrapper;
