import styled from "styled-components";
import media from "app/styles/utils/media";

export const Form = styled.form`
  width: 100%;

  .submit-button,
  .form-group {
    margin-top: 24px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-top: 24px;

  .divider {
    background: #cbd2d9;
    border-radius: 6px;
    width: 16px;
    height: 3px;
    margin: 0 13px;
  }
`;

export const InputGroup = styled.div`
  display: flex;

  & > input:nth-child(2) {
    border-right: none;
    border-left: none;
    border-radius: 0;
  }
  & > input:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & > input:nth-child(3) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > input {
    width: 48px;

    &:focus {
      border: 2px solid #18981d;
    }
  }

  ${media.smallMobile`
    & > input {
      width: 40px;
      padding: 0px 10px;
    }
  `};
`;

export const RecaptchaBlock = styled.div`
  margin-top: 30px;
  border: 1px solid #d3d3d3;
  background-color: #f9f9f9;
  border-radius: 3px;
  height: 74px;
  width: 300px;
`;
