import styled from "styled-components";
import media from "app/styles/utils/media";

export const Section = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 36px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  ${media.mobile`
    flex-wrap: wrap;
    & > * {
      flex: 100%;
    }
  `};
`;

export const SectionInfo = styled.div`
  & > p.sub-title {
    margin-top: 10px;
    color: #616e7c;
    display: flex;
    align-items: center;
    margin-right: 20px;
    & > svg {
      margin-right: 8px;
      height: 14px;
      width: 14px;
    }
  }
`;

export const SectionInfoDescription = styled.div`
  width: 450px;
  max-width: 100%;
  text-align: left;
  margin-top: 25px;

  .title {
    margin-top: 46px;
  }
  .description {
    color: #3e4c59;
  }

  a {
    margin-top: 32px;
    width: 238px;
  }
`;

export const SectionAction = styled.div`
  & > a {
    width: 320px;
    height: 48px;
    font-size: 0.8rem;

    ${media.mobile`
      margin-top: 24px;
      width: 100%;
    `};
  }
`;

export const ItemsContainer = styled.section`
  width: 100%;
  height: 100%;
`;
