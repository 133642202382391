import styled from "styled-components";
import media from "app/styles/utils/media";

export const Container = styled.div`
  width: 100%;
  padding-top: 37px;
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;

  & > a {
    text-decoration: none;
    color: #18981d;
    margin-left: 16px;
    font-size: 0.8rem;
  }
`;

export const DetailsBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    flex: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  ${media.mobile`
    & > div {
      flex: 100%;
    }
  `};
`;

export const DetailItem = styled.div`
  text-align: left;
  margin-bottom: 16px;
  & > .title {
    color: #616e7c;
  }
  & > .value {
    margin-top: 2px;
  }
`;
