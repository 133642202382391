import styled, { css } from "styled-components";

interface Props {
  fullwidth?: boolean;
  error?: boolean;
}

const Input = styled.input<Props>`
  border: 1px solid #cbd2d9;
  border-radius: 5px;
  width: ${props => (props.fullwidth ? "100%" : "100px")};
  height: 54px;
  padding: 0px 16px;
  outline: none;
  transition: border 100ms ease-in, background-color 100ms ease-in;
  font-size: 0.9rem;
  font-family: "Aribau-Grotesk";
  background-color: #fff;

  &::placeholder {
    color: #7b8794;
  }

  &:disabled {
    opacity: 0.8;
  }

  &:focus {
    border: 2px solid #18981d;
  }

  ${props =>
    props.error &&
    css`
      background-color: #ffe3e3;
      border: 1px solid #e12d39;
    `};
`;

export default Input;
