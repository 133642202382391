import request from "../request";
import { FetchActivitiesResponse } from "./types";
import { GenericParamsPayload } from "../types";

const fetchActivities = async (payload: GenericParamsPayload) => {
  const query = `?page=${payload.page}`;
  return await request.get<FetchActivitiesResponse>(
    `/v3/me/activities${query}`
  );
};

export default { fetchActivities };
