import request from "../request";
import {
  FetchProfileResponse,
  UpdateProfilePayload,
  UpdateProfileResponse,
  UpdateUsernamePayload,
  UpdateAvatarPayload,
} from "./types";

const fetchProfile = async () => {
  return await request.get<FetchProfileResponse>(`/v3/me`);
};

const updateProfile = async (payload: UpdateProfilePayload) => {
  return await request.put<UpdateProfileResponse>(`/v3/me`, payload);
};

const updateUsername = async (payload: UpdateUsernamePayload) => {
  return await request.put<UpdateProfileResponse>(`/v3/me/username`, payload);
};

const updateAvatar = async (payload: UpdateAvatarPayload) => {
  return await request.put<UpdateProfileResponse>(`/v3/me/avatar`, payload);
};

export default { fetchProfile, updateProfile, updateUsername, updateAvatar };
