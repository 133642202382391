import React, { useEffect, useCallback, useRef, useState } from "react";
import queryString from "query-string";
import Lottie from "lottie-web";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LoaderBox from "app/components/LoaderBox";
import AuthLayout from "app/styles/blocks/AuthLayout";
import { SuccessPageBody } from "app/views/Signup/styles";
import { ReactComponent as Logo } from "app/assets/img/logo.svg";
import CheckMarkAnimation from "app/assets/animations/check-mark-green.json";
import WarningAnimation from "app/assets/animations/warning.json";
import Button from "app/components/Button";
import H2 from "app/styles/elements/H2";
import P from "app/styles/elements/P";
import { RootState } from "app/redux/reducers";
import {
  verifyUserEmail,
  cleanupVerifyUserEmailState,
} from "app/redux/auth/actions";
import {
  resendUserVerificationEmail,
  cleanupResendVerificationEmailState,
} from "app/redux/auth/actions";
import decodeJWT from "app/utils/decodeJWT";

const VerifyEmailAddressPage: React.FC = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const animationRef = useRef<HTMLDivElement>(null);
  const [email, setEmail] = useState<string | null>(null);
  const { verifyEmailAddress: VerifyEmailAddressState } = useSelector(
    (state: RootState) => state.auth
  );
  const { resendVerificationEmail: ResendVerificationEmailState } = useSelector(
    (state: RootState) => state.auth
  );

  const goToLogin = useCallback(() => {
    let continueFromApp;
    if (location.search) {
      const queryValues = queryString.parse(location.search);
      continueFromApp = queryValues.redirect;
    }
    history.push({
      pathname: "/login",
      search: continueFromApp ? `?continue=${continueFromApp}` : "",
    });
  }, [history, location.search]);

  const onResendVerificationEmail = () => {
    if (email) {
      dispatch(resendUserVerificationEmail({ email }));
    }
  };

  // clean up resend verification email state on page load
  useEffect(() => {
    dispatch(cleanupVerifyUserEmailState());
    dispatch(cleanupResendVerificationEmailState());

    if (location.search) {
      const queryValues = queryString.parse(location.search);
      const code = String(queryValues.code);
      const { email } = decodeJWT(code);
      setEmail(email);
      dispatch(verifyUserEmail(code));
    } else {
      goToLogin();
    }
  }, [dispatch, goToLogin, location.search]);

  // display animation based on either a verify email success or failure
  useEffect(() => {
    if (VerifyEmailAddressState.success || VerifyEmailAddressState.error) {
      if (animationRef.current) {
        Lottie.loadAnimation({
          container: animationRef.current,
          renderer: "svg",
          loop: false,
          autoplay: true,
          animationData: VerifyEmailAddressState.success
            ? CheckMarkAnimation
            : WarningAnimation,
        }).setSpeed(2);
      }
    }
  }, [VerifyEmailAddressState.error, VerifyEmailAddressState.success]);

  if (VerifyEmailAddressState.pending) {
    return <LoaderBox text="Verifying email address..." />;
  }

  return (
    <AuthLayout.Main>
      <SuccessPageBody>
        <AuthLayout.Header>
          <Link to="/">
            <Logo className="logo" />
          </Link>
        </AuthLayout.Header>
        {(VerifyEmailAddressState.error || VerifyEmailAddressState.success) && (
          <>
            <div className="icon" ref={animationRef} />
            <H2 medium semiBold className="title">
              {VerifyEmailAddressState.error && "Verification link expired"}
              {VerifyEmailAddressState.success && "Email address verified"}
            </H2>
            <P small className="description">
              {VerifyEmailAddressState.error &&
                "The verification link you clicked on has expired, please click on the link below to get a new link sent to your email"}
              {VerifyEmailAddressState.success &&
                "Your email address has been successfully verified. Login to start using busha."}
            </P>
            <Button
              fullwidth
              variant="green"
              type="button"
              text={
                VerifyEmailAddressState.success ||
                !email ||
                ResendVerificationEmailState.error === "user has been verified"
                  ? "Start using busha"
                  : "Resend verification email"
              }
              onClick={
                VerifyEmailAddressState.success ||
                !email ||
                ResendVerificationEmailState.error === "user has been verified"
                  ? goToLogin
                  : onResendVerificationEmail
              }
              loading={ResendVerificationEmailState.pending}
            />
          </>
        )}
      </SuccessPageBody>
    </AuthLayout.Main>
  );
};

export default VerifyEmailAddressPage;
