import { logoutUser } from "../auth/types";
import { cleanSecurityActionState } from "./types";
import {
  finalizeSecurityService,
  deactivateTwoStepAuth,
  changePassword,
} from "./types";
import {
  SecurityState,
  fetchSecurity,
  initializeSecurityService,
} from "./types";
import session from "app/utils/session";

const cachedSecurity = session.get("security");

const INITIAL_STATE: SecurityState = {
  pending: false,
  error: null,
  success: false,
  data: cachedSecurity,
  action: {
    data: null,
    pending: false,
    error: null,
    success: false,
    initialized: false,
    finalized: false,
  },
};

export default function auth(
  state = INITIAL_STATE,
  action: any
): SecurityState {
  switch (action.type) {
    case fetchSecurity.pending:
      return {
        ...state,
        success: false,
        pending: true,
        error: null,
      };

    case fetchSecurity.rejected:
      return {
        ...state,
        success: false,
        pending: false,
        error: action.payload,
      };

    case fetchSecurity.fulfilled:
      return {
        ...state,
        success: true,
        pending: false,
        error: null,
        data: action.payload,
      };

    case initializeSecurityService.pending:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          pending: true,
          error: null,
          initialized: false,
          finalized: false,
        },
      };

    case finalizeSecurityService.pending:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          pending: true,
          error: null,
          finalized: false,
        },
      };

    case deactivateTwoStepAuth.pending:
    case changePassword.pending:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          pending: true,
          error: null,
        },
      };

    case initializeSecurityService.rejected:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          pending: false,
          initialized: false,
          finalized: false,
          error: action.payload,
        },
      };

    case finalizeSecurityService.rejected:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          pending: false,
          finalized: false,
          error: action.payload,
        },
      };

    case deactivateTwoStepAuth.rejected:
    case changePassword.rejected:
      return {
        ...state,
        action: {
          ...state.action,
          success: false,
          pending: false,
          error: action.payload,
        },
      };

    case initializeSecurityService.fulfilled:
      return {
        ...state,
        action: {
          ...state.action,
          success: true,
          pending: false,
          error: null,
          initialized: true,
          data: action.payload,
        },
      };

    case finalizeSecurityService.fulfilled:
      return {
        ...state,
        action: {
          ...state.action,
          success: true,
          pending: false,
          error: null,
          finalized: true,
        },
      };

    case deactivateTwoStepAuth.fulfilled:
    case changePassword.fulfilled:
      return {
        ...state,
        action: {
          ...state.action,
          success: true,
          pending: false,
          error: null,
        },
      };

    case cleanSecurityActionState.default:
      return {
        ...state,
        action: INITIAL_STATE.action,
      };

    case logoutUser.fulfilled:
      return INITIAL_STATE;

    default:
      return state;
  }
}
