import React from "react";
import PageHeader from "app/styles/blocks/PageHeader";
import PasswordSection from "./components/PasswordSection";
import TwoStepAuthSection from "./components/TwoStepAuthSection";
import PhoneSection from "./components/PhoneSection";
import BvnSection from "./components/BvnSection";
import { MobileAppContext } from "../../components/MobileAppAuth";

const SecurityPage: React.FC = (props) => {
  const mobileAppContext = React.useContext(MobileAppContext);

  return (
    <div className="page-content">
      <PageHeader.Main>
        <PageHeader.Title big>Security</PageHeader.Title>
        <PageHeader.Description small>
          Review your accounts to make sure they are as secure as possible.
        </PageHeader.Description>
      </PageHeader.Main>
      <TwoStepAuthSection />
      {!mobileAppContext.isWebView && <PasswordSection />}
      <PhoneSection />
      <BvnSection />
    </div>
  );
};

export default SecurityPage;
