import media from "app/styles/utils/media";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30%) 1fr;
  grid-template-areas: "device date location action";
  border-bottom: 1px solid rgba(211, 213, 216, 0.5);
  padding: 20px 0px;
  align-items: flex-start;

  & > button {
    grid-area: action;
    font-size: 0.7rem;
    justify-self: end;
    padding: 0;
    width: 100px;
  }

  ${media.mobile`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: 'device action'
                          'location location'
                          'date date';
  `}
`;

export const ItemName = styled.div`
  grid-area: device;
  text-align: left;
  box-sizing: border-box;

  & > p {
    font-size: 0.8rem;
    font-weight: 500;
  }

  & > .active-device {
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: #637381;
    font-size: 0.6rem;

    & > svg {
      margin-right: 6px;
      width: 11.67px;
      height: 11.67px;
    }
  }
`;

export const ItemDate = styled.div`
  grid-area: date;
  color: #3e4c59;
  font-size: 0.7rem;
  text-align: left;
`;

export const ItemLocation = styled.div`
  grid-area: location;
  color: #3e4c59;
  text-align: left;

  & > p {
    font-size: 0.8rem;
  }

  .ip-address {
    color: #7b8794;
    font-size: 0.7rem;
    margin-top: 8px;
  }
`;
