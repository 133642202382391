import { decodeString, encodeString } from "./helpers";
let SESSION = sessionStorage;

const session = {
  get(key: string) {
    const itemStr = SESSION.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(decodeString(itemStr));
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      SESSION.removeItem(key);
      return null;
    }
    return item.value;
  },
  set(key: string, value: Object, ttl: number = 300000) {
    const now: Date = new Date();
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    SESSION.setItem(key, encodeString(JSON.stringify(item)));
  },
  remove(key: string) {
    SESSION.removeItem(key);
  },
  clear() {
    SESSION.clear();
  },
};

export default session;
