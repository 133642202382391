import createActionType from "../util/createActionType";
import { PaginatorType, GenericResponse } from "app/api/types";
import { SessionType } from "app/api/sessions/types";
import { GenericParamsPayload, GenericDeletePayload } from "app/api/types";

export interface SessionsState {
  data: SessionType[];
  paginator: PaginatorType | null;
  updating: boolean;
  pending: boolean;
  error: GenericResponse extends string
    ? string | null
    : GenericResponse | null;
  success: boolean;
  selectedSessionId?: string | null;
}

export const fetchSessions = createActionType("fetch_sessions");
export const signoutSession = createActionType("signout_session");

export interface FetchSessionsAction {
  type: string;
  meta: GenericParamsPayload;
}

export interface SignoutSessionAction {
  type: string;
  payload: GenericDeletePayload;
}
