import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 4px;
  }
`;

type ItemType = { active: boolean };
export const Item = styled.div<ItemType>`
  width: ${props => (props.active ? 18 : 6)}px;
  background-color: ${props => (props.active ? "#04A700;" : "#C4C4C4;")};
  height: 6px;
  border-radius: 16px;
  transition: width 200ms ease-in, background-color 300ms ease-in;
`;
