import createActionType from "../util/createActionType";
import { PaginatorType, GenericResponse } from "app/api/types";
import { DeviceType } from "app/api/devices/types";
import { GenericParamsPayload, GenericDeletePayload } from "app/api/types";

export interface DevicesState {
  data: DeviceType[];
  paginator: PaginatorType | null;
  updating: boolean;
  pending: boolean;
  error: GenericResponse extends string
    ? string | null
    : GenericResponse | null;
  success: boolean;
  selectedDeviceId: string | null;
}

export const fetchDevices = createActionType("fetch_devices");
export const removeDevice = createActionType("remove_device");

export interface FetchDevicesAction {
  type: string;
  meta: GenericParamsPayload;
}

export interface RemoveDeviceAction {
  type: string;
  payload: GenericDeletePayload;
}
