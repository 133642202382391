import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "app/styles/blocks/PageHeader";
import Level from "./components/Level";
import { LevelsContainer } from "./styles";
import { RootState } from "app/redux/reducers";
import useLevelProps from "app/hooks/useLevelProps";
import { fetchUserDocumentsTypes } from "app/redux/documents/actions";

const LimitsAndLevelsPage: React.FC = (props) => {
  const dispatch = useDispatch();

  const { data: userData } = useSelector((state: RootState) => state.profile);
  const { data: securityData } = useSelector(
    (state: RootState) => state.security
  );

  const levelProps = useLevelProps({
    level: userData?.level,
    bvn: userData?.bvn,
    phone: securityData?.phone,
    completeProfile: securityData?.complete_profile,
  });

  useEffect(() => {
    // only fetch document types when the user is at level 1 or 2, because that is when submission is required
    if ([1, 2].includes(Number(userData?.level))) {
      dispatch(fetchUserDocumentsTypes());
    }
  }, [userData, dispatch]);

  return (
    <div className="page-content">
      <PageHeader.Main>
        <PageHeader.Title big>Limits and Levels</PageHeader.Title>
        <PageHeader.Description small>
          You’re currently Level-{userData?.level} verified
        </PageHeader.Description>
      </PageHeader.Main>
      <LevelsContainer>
        {levelProps.map((_props) => {
          return <Level key={_props.name} {..._props} />;
        })}
      </LevelsContainer>
    </div>
  );
};

export default LimitsAndLevelsPage;
