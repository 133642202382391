import api from "app/api";
import { getFileSizeInKB } from "./helpers";

async function UploadFile(file: File, category: "avatar" | "document") {
  const { name, size } = file;

  const MAX_SIZE = 5000;
  const fileSizeInKB: number = getFileSizeInKB(size);

  if (fileSizeInKB > MAX_SIZE) {
    throw new Error(`“${name}” is too large. Try a file size less than 5 MB.`);
  } else {
    const res = await api.amazonS3.getPresignedLink({
      filename: name,
      category,
    });
    const { presigned_url, public_link } = res.data;

    await api.amazonS3.uploadFile({
      presigned_url,
      file: file,
    });
    return public_link;
  }
}

const UploadImage = async (ImageFile: File) =>
  await UploadFile(ImageFile, "avatar");

const UploadDocument = async (Document: File) =>
  await UploadFile(Document, "document");

export { UploadImage, UploadDocument };
