import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

import { Section, SectionAction, SectionInfo } from "./styles";
import P from "app/styles/elements/P";
import Button from "app/styles/elements/Button";

interface Props {
  totalDevices?: number;
}

const MobileSection: React.FC<Props> = (props) => {
  const match = useRouteMatch();

  return (
    <Section>
      <SectionInfo>
        <P medium semiBold>
          Mobile Applications
        </P>
        <P small className="sub-title">
          {props?.totalDevices
            ? `You have authorized ${props?.totalDevices} mobile applications`
            : "Busha mobile applications you are currently logged into"}
        </P>
      </SectionInfo>
      <SectionAction>
        <Button as={Link} to={`${match.path}/mobile`} variant="outline">
          View applications
        </Button>
      </SectionAction>
    </Section>
  );
};

export default MobileSection;
