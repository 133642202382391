import styled from "styled-components";
import media from "app/styles/utils/media";

const Body = styled.section`
  padding: 60px 20px 0px;
  /* width: 1080px; */
  /* width: 1120px; */
  width: 1280px;
  max-width: 100%;
  height: calc(100% - 56px);
  margin: 0 auto;

  ${media.mobile`
    padding: 20px 20px 0px;
  `};
`;

export default Body;
