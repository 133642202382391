import React, { useState } from "react";
import { useRouteMatch, useHistory, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import PrevPageLink from "app/components/PrevPageLink";
import PageHeader from "app/styles/blocks/PageHeader";
import { Container, DocTypeSelctBox } from "./styles";
import Button from "app/components/Button";
import Select from "app/components/Select";
import P from "app/styles/elements/P";
import { RootState } from "app/redux/reducers";

const DocumemtsPage: React.FC = (props) => {
  const history = useHistory();
  const match = useRouteMatch();

  const { documentTypes, countryInfo } = useSelector(
    (state: RootState) => state.documents
  );

  const [selectedDocType, setSelectedDocType] = useState("");

  if (documentTypes?.length < 1 || !countryInfo) {
    return <Redirect to="/limits-and-levels" />;
  }

  return (
    <div className="page-content">
      <PrevPageLink to="/limits-and-levels" text="Limits and levels" />
      <PageHeader.Main>
        <PageHeader.Title big>Select ID type</PageHeader.Title>
      </PageHeader.Main>
      <Container>
        <Select
          fullWidth
          name="country"
          label="Issuing country/region"
          value={countryInfo?.id || ""}
          options={[
            {
              label: countryInfo?.name || "",
              value: countryInfo?.id || "",
              icon: countryInfo?.flag || "",
            },
          ]}
          onChange={() => {}}
          disabled
        />
        <DocTypeSelctBox>
          <P medium semiBold className="label">
            Choose document type
          </P>
          <P small className="description">
            Please use a document that is in date, as expired documents may not
            be approved
          </P>
          <Select
            fullWidth
            containerClassName="select"
            name="document_type"
            options={documentTypes}
            labelAccessor="name"
            valueAccessor="id"
            value={selectedDocType}
            onChange={(v) => setSelectedDocType(v["id"])}
          />
        </DocTypeSelctBox>
        <Button
          text="Proceed"
          type="button"
          variant="green"
          disabled={!selectedDocType}
          onClick={() =>
            history.push({
              pathname: `${match.path}/upload`,
              state: { selectedDocType },
            })
          }
        />
      </Container>
    </div>
  );
};

export default DocumemtsPage;
