import React, { useRef, useEffect } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import Lottie from "lottie-web";
import AuthLayout from "app/styles/blocks/AuthLayout";
import { SuccessPageBody } from "../Signup/styles";
import { ReactComponent as Logo } from "app/assets/img/logo.svg";
import Button from "app/components/Button";
import H2 from "app/styles/elements/H2";
import P from "app/styles/elements/P";
import CheckMarkAnimation from "app/assets/animations/check-mark-green.json";
import WarningAnimation from "app/assets/animations/warning.json";

interface ParamsTypes {
  message: string;
}

const ResponsePage: React.FC = (props) => {
  const match = useRouteMatch<ParamsTypes>();
  const history = useHistory();
  const animationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animationRef.current) {
      Lottie.loadAnimation({
        container: animationRef.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData:
          match.params.message === "success"
            ? CheckMarkAnimation
            : WarningAnimation,
      });
    }
  }, [match.params.message]);

  return (
    <AuthLayout.Main>
      <SuccessPageBody>
        <AuthLayout.Header>
          <Link to="/">
            <Logo className="logo" />
          </Link>
        </AuthLayout.Header>
        <div className="icon" ref={animationRef} />
        <H2 medium semiBold className="title">
          {match.params.message === "success"
            ? "You are almost there!"
            : "Link expired."}
        </H2>
        <P small className="description">
          {match.params.message === "success"
            ? "Your password has been succesfully changed."
            : "Your link has expired, click the button below to get a new link."}
        </P>
        <Button
          fullwidth
          variant="green"
          type="button"
          text={
            match.params.message === "success"
              ? "Start using Busha"
              : "Get reset password link"
          }
          onClick={() => {
            if (match.params.message === "success") {
              history.push("/login");
            } else {
              history.push("/forgot-password");
            }
          }}
        />
      </SuccessPageBody>
    </AuthLayout.Main>
  );
};

export default ResponsePage;
