import styled from "styled-components";
import media from "app/styles/utils/media";

export const Container = styled.div`
  width: 100%;
  padding-top: 24px;

  & > h2 {
    margin-top: 25px;
    color: #3e4c59;
  }
  & > p {
    margin-top: 9px;
    color: #7b8794;
    font-size: 0.7rem;
  }
  a {
    text-decoration: none;
    color: #18981d;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin-right: 21px;
  }

  & > a {
    font-size: 0.8rem;
  }

  ${media.mobile`
    width: 100%;
    justify-content: space-between;
  `}
`;
