import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Section,
  SectionAction,
  SectionInfo,
  SectionInfoDescription,
} from "./styles";
import P from "app/styles/elements/P";
import Button from "app/components/Button";
import ButtonLink from "app/styles/elements/Button";
import { ReactComponent as TwoStepOffIcon } from "app/assets/img/icons/2fa-off.svg";
import { ReactComponent as TwoStepOnIcon } from "app/assets/img/icons/2fa-on.svg";
import { RootState } from "app/redux/reducers";
import { deactivateUserTwoStepAuth } from "app/redux/security/actions";

const TwoStepAuthSection: React.FC = (props) => {
  const dispatch = useDispatch();

  const { data, action, pending } = useSelector(
    (state: RootState) => state.security
  );

  const turnOffTwoStepAuth = () => dispatch(deactivateUserTwoStepAuth());

  return (
    <Section>
      <SectionInfo>
        <P medium semiBold>
          Two-step authentication
        </P>
        <P small className="sub-title">
          {!data?.two_step ? (
            <>
              <TwoStepOffIcon />
              <span>Off</span>
            </>
          ) : (
            <>
              <TwoStepOnIcon />
              <span>On ({data?.two_step_medium})</span>
            </>
          )}
        </P>
        {!data?.two_step && (
          <SectionInfoDescription>
            <P small className="description">
              With two-step authentication, only you can access your account -
              even if someone else has your password.
            </P>
            <P medium semiBold className="title">
              How it works?
            </P>
            <P small className="description">
              When you log in to Busha, you’ll need to enter your password and a
              code generated by an authentication app or an SMS text message.
            </P>
            {/* {data?.two_step ? (
              <Button
                variant="green"
                onClick={() => dispatch(deactivateUserTwoStepAuth())}
                loading={action.pending}
                text="Turn off two-step"
              />
            ) : ( */}
            <ButtonLink as={Link} to="/security/two-step-auth" variant="green">
              Turn on two-step
            </ButtonLink>
            {/* )} */}
          </SectionInfoDescription>
        )}
      </SectionInfo>
      {data?.two_step && (
        <SectionAction>
          <Button
            variant="outline"
            text="Turn off two-step"
            // text=" Manage Two-step"
            disabled={pending}
            loading={action.pending}
            onClick={turnOffTwoStepAuth}
          />
        </SectionAction>
      )}
    </Section>
  );
};

export default TwoStepAuthSection;
