import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Documents from "./Documents";
import AllDocuments from "./AllDocuments";
import DocumentsRequests from "./DocumentsRequests";

const DocumentsRoutes: React.FC = (props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={Documents} exact />
      <Route path={`${match.path}/all`} component={AllDocuments} exact />
      <Route
        path={`${match.path}/requests`}
        component={DocumentsRequests}
        exact
      />
    </Switch>
  );
};

export default DocumentsRoutes;
