import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import LimitsAndLevelsPage from "./LimitsAndLevels";
import DocumentsRoutes from "./Documents";
import LevelOneVerificationRoutes from "./LevelOneVerification";

const LimitsAndLevels: React.FC = (props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={LimitsAndLevelsPage} exact />
      <Route
        path={`${match.path}/verify`}
        component={LevelOneVerificationRoutes}
      />
      <Route path={`${match.path}/documents`} component={DocumentsRoutes} />
    </Switch>
  );
};

export default LimitsAndLevels;
