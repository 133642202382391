import styled from "styled-components";
import Header from "./Header";
import Body from "./Body";
import Sidebar from "./Sidebar";
import Content from "./Content";

const Main = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
`;

const DashboardLayout = {
  Main,
  Header,
  Body,
  Sidebar,
  Content
};

export default DashboardLayout;
