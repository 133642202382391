import React from "react";
import { ItemsContainer } from "./styles";
import DeviceItem from "./DeviceItem";
import { PaginatorType } from "app/api/types";
import Pagination from "app/components/Pagination";
import { DeviceType } from "app/api/devices/types";

interface Props {
  devices: DeviceType[];
  paginator: PaginatorType | null;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  handleRemoveDevice: (id: string | number) => void;
  selectedDeviceId?: any;
  updating: boolean;
}

const ConfirmedDevices: React.FC<Props> = ({
  devices,
  paginator,
  handleNextPage,
  handlePreviousPage,
  handleRemoveDevice,
  selectedDeviceId,
  updating,
}) => {
  return (
    <ItemsContainer>
      {devices.map((device, index) => (
        <DeviceItem
          key={index}
          {...device}
          loading={updating && device.id === selectedDeviceId}
          onRemove={() => handleRemoveDevice(device?.id || "")}
        />
      ))}
      {devices.length > 0 && (
        <Pagination
          paginator={paginator}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      )}
    </ItemsContainer>
  );
};

export default ConfirmedDevices;
