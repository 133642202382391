import React from "react";
import { EmptyListContainer } from "./styles";
import P from "app/styles/elements/P";

interface Props {
  text?: string;
}

const EmptyList: React.FC<Props> = ({ text }) => {
  return (
    <EmptyListContainer>
      {text && (
        <P semiBold subdued>
          {text}
        </P>
      )}
    </EmptyListContainer>
  );
};

export default EmptyList;
