import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "app/styles/blocks/PageHeader";
import CommunicationItems from "./components/CommunicationItems";
import { RootState } from "app/redux/reducers";
import {
  fetchUserNotifications,
  updateUserNotifications,
} from "app/redux/notifications/actions";
import { UpdateNotificationsPayload } from "../../api/notifications/types";

const Communications: React.FC = (props) => {
  const dispatch = useDispatch();
  const { data, updating } = useSelector(
    (state: RootState) => state.notifications
  );

  function onUpdateNotifications(payload: UpdateNotificationsPayload) {
    dispatch(updateUserNotifications(payload));
  }

  useEffect(() => {
    dispatch(fetchUserNotifications());
  }, [dispatch]);

  return (
    <div className="page-content">
      <PageHeader.Main>
        <PageHeader.Title big>Communications</PageHeader.Title>
        <PageHeader.Description small>
          Manage how Busha notifies you of events
        </PageHeader.Description>
      </PageHeader.Main>
      <CommunicationItems
        initialValues={data}
        updating={updating}
        onUpdateNotifications={onUpdateNotifications}
      />
    </div>
  );
};

export default Communications;
