import createActionType from "../util/createActionType";
import { LoginResponse } from "../../api/auth/types";

export interface TokensState extends LoginResponse {}

export const setTokens = createActionType("set_tokens");

export interface SetTokensAction {
  type: string;
  payload: LoginResponse;
}
