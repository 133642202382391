import React from "react";
import { format } from "date-fns";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Info } from "../styles";
import Button from "app/components/Button";
import P from "app/styles/elements/P";
import Input from "app/components/Input";
import DatePicker from "app/components/DatePicker";
import FormInput from "app/styles/blocks/FormInput";
import {
  initializeUserSecurityService,
  cleanupSecurityActionState,
} from "app/redux/security/actions";
import { RootState } from "app/redux/reducers";
import { InitializeSecurityServicePayload } from "app/api/security/types";
import StepIndicator from "app/views/Signup/components/StepIndicator";
import { IndicatorContext } from "..";

interface LocationState {
  securityPageAction?: boolean;
}

const BVNPage: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const { action } = useSelector((state: RootState) => state.security);

  const stepIndicatorCntxt = React.useContext(IndicatorContext);

  const validate = (values: InitializeSecurityServicePayload) => {
    const errors: any = {};

    if (!values.bvn) {
      errors.bvn = "BVN is required";
    }
    if (!values.dob) {
      errors.dob = "Date of birth is required";
    }
    return errors;
  };

  const form = useFormik<InitializeSecurityServicePayload>({
    initialValues: {
      bvn: "",
      dob: new Date().toString(),
    },
    onSubmit: (values) => {
      const formattedDate = values.dob
        ? format(new Date(values.dob), "d-MMM-yyyy")
        : "";
      dispatch(
        initializeUserSecurityService("bvn", { ...values, dob: formattedDate })
      );
    },
    validate,
    validateOnChange: false,
  });

  const onInputFocus = (name: string) => () =>
    form.setFieldError(name, undefined);

  React.useEffect(() => {
    dispatch(cleanupSecurityActionState());
  }, [dispatch]);

  React.useEffect(() => {
    if (action.success && action.initialized) {
      const { bvn } = form.values;
      history.push({
        pathname: `${match.path}/enable`,
        state: {
          bvn,
          first_number: action.data?.first_number,
          last_number: action.data?.last_number,
          complete_phone_number_allowed:
            action.data?.complete_phone_number_allowed,
          securityPageAction: !!location.state?.securityPageAction,
        },
      });
    }
  }, [action, history, match.path, form.values, location.state]);

  return (
    <Container>
      <Info>
        <div className="step-indicator">
          <StepIndicator
            numberOfSteps={stepIndicatorCntxt.totalSteps}
            activeStep={stepIndicatorCntxt.currentStep}
          />
        </div>
        <P medium semiBold>
          Enter your Bank Verification Number
        </P>
        <P small className="description">
          Your BVN is required to verify your name(s) and date of birth
        </P>
      </Info>
      <form onSubmit={form.handleSubmit}>
        <Input
          label="BVN"
          name="bvn"
          containerClassName="form-group"
          value={form.values.bvn}
          onChange={form.handleChange}
          error={!!form.errors.bvn}
          errorMessage={form.errors.bvn}
          onFocus={onInputFocus("bvn")}
        />
        <FormInput.Main className="form-group" fullwidth>
          <FormInput.Label>Date of birth</FormInput.Label>
          <DatePicker
            fullWidth
            value={form.values.dob ? new Date(form.values.dob) : new Date()}
            onChange={(newDateOfBirth) => {
              form.setFieldValue("dob", newDateOfBirth);
            }}
          />
          {form.errors.dob && (
            <FormInput.ErrorMsg>{form.errors.dob}</FormInput.ErrorMsg>
          )}
        </FormInput.Main>
        <Button
          type="submit"
          variant="green"
          text="Verify BVN"
          loading={action.pending}
        />
      </form>
    </Container>
  );
};

export default BVNPage;
