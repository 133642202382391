import styled from "styled-components";

import P from "app/styles/elements/P";
import media from "app/styles/utils/media";

export const Title = styled.div`
  margin-bottom: 32px;

  & > p {
    color: #7b8794;
    margin-top: 10px;
  }
`;

export const InfoText = styled(P)`
  margin-top: 24px;
  display: flex;

  & > a {
    text-decoration: none;
    color: #18981d;
    font-size: 0.8rem;
  }
  & > button {
    border: none;
    outline: none;
    color: #18981d;
    font-size: 0.8rem;
    font-family: "Aribau-Grotesk";
  }
`;

export const EmailBlock = styled.div`
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -5px;

  & > * {
    margin-top: 5px;
  }

  & > button {
    border: none;
    outline: none;
    color: #18981d;
    font-size: 0.8rem;
    font-family: "Aribau-Grotesk";
  }
`;

export const SuccessPageBody = styled.div`
  width: 335px;
  max-width: 100%;
  margin: 0 auto;

  text-align: center;

  .icon {
    padding-top: 10px;
    width: 150px;
    margin: 0 auto;

    ${media.mobile`
    padding-top: 60px;
    `};
  }

  .title {
    margin-top: 50px;
  }

  .description {
    margin: 24px 0 50px;
    color: #7b8794;
  }
`;

export const RecaptchaBlock = styled.div`
  margin-top: 30px;
  border: 1px solid #d3d3d3;
  background-color: #f9f9f9;
  border-radius: 3px;
  height: 74px;
  width: 300px;
`;
