import {
  AuthState,
  loginUser,
  loginConfirm,
  clearLoginData,
  clearLoginErrors,
  clearSignupData,
  clearSignupErrors,
  signupUser,
  resetPassword,
  clearResetPasswordData,
  forgotPassword,
  clearForgotPasswordData,
  resendLoginCode,
  clearResendLoginCodeData,
  resendVerificationEmail,
  clearResendVerificationEmailData,
  verifyEmailAddress,
  clearVerifyEmailAddressData,
  logoutUser,
} from "./types";

const INITIAL_STATE: AuthState = {
  login: {
    pending: false,
    error: null,
    success: false,
    data: undefined,
  },
  signup: {
    pending: false,
    error: null,
    success: false,
    data: undefined,
  },
  resendLoginCode: {
    pending: false,
    error: null,
    success: false,
    data: undefined,
  },
  resendVerificationEmail: {
    pending: false,
    error: null,
    success: false,
    data: undefined,
  },
  verifyEmailAddress: {
    pending: false,
    error: null,
    success: false,
  },
  resetPassword: {
    pending: false,
    error: null,
    success: false,
  },
  forgotPassword: {
    pending: false,
    error: null,
    success: false,
  },
  logout: {
    pending: false,
    error: null,
    success: false,
  },
};

export default function auth(state = INITIAL_STATE, action: any): AuthState {
  switch (action.type) {
    case clearLoginErrors.default:
      return {
        ...state,
        login: {
          ...state.login,
          error: null,
        },
      };
    case clearLoginData.default:
      return {
        ...state,
        login: INITIAL_STATE.login,
      };
    case loginUser.pending:
    case loginConfirm.pending:
      return {
        ...state,
        login: {
          ...state.login,
          pending: true,
          error: null,
        },
      };
    case loginUser.rejected:
    case loginConfirm.rejected:
      return {
        ...state,
        login: {
          ...state.login,
          pending: false,
          error: action.payload,
        },
      };
    case loginUser.fulfilled:
    case loginConfirm.fulfilled:
      return {
        ...state,
        login: {
          ...state.login,
          pending: false,
          error: null,
          success: true,
          data: action.payload,
        },
      };
    // SIGNUP
    case clearSignupErrors.default:
      return {
        ...state,
        signup: {
          ...state.signup,
          error: null,
        },
      };
    case clearSignupData.default:
      return {
        ...state,
        signup: INITIAL_STATE.signup,
      };
    case signupUser.pending:
      return {
        ...state,
        signup: {
          ...state.signup,
          pending: true,
          error: null,
        },
      };
    case signupUser.rejected:
      return {
        ...state,
        signup: {
          ...state.signup,
          pending: false,
          error: action.payload,
        },
      };
    case signupUser.fulfilled:
      return {
        ...state,
        signup: {
          ...state.signup,
          pending: false,
          error: null,
          success: true,
        },
      };
    // RESEND VERIFICATION EMAIL
    case clearResendVerificationEmailData.default:
      return {
        ...state,
        resendVerificationEmail: INITIAL_STATE.resendVerificationEmail,
      };
    case resendVerificationEmail.pending:
      return {
        ...state,
        resendVerificationEmail: {
          ...state.resendVerificationEmail,
          pending: true,
          error: null,
        },
      };
    case resendVerificationEmail.rejected:
      return {
        ...state,
        resendVerificationEmail: {
          ...state.resendVerificationEmail,
          pending: false,
          error: action.payload,
        },
      };
    case resendVerificationEmail.fulfilled:
      return {
        ...state,
        resendVerificationEmail: {
          ...state.resendVerificationEmail,
          pending: false,
          error: null,
          success: true,
        },
      };
    // RESEND LOGIN CODE
    case clearResendLoginCodeData.default:
      return {
        ...state,
        resendLoginCode: INITIAL_STATE.resendLoginCode,
      };
    case resendLoginCode.pending:
      return {
        ...state,
        resendLoginCode: {
          ...state.resendLoginCode,
          pending: true,
          error: null,
        },
      };
    case resendLoginCode.rejected:
      return {
        ...state,
        resendLoginCode: {
          ...state.resendLoginCode,
          pending: false,
          error: action.payload,
        },
      };
    case resendLoginCode.fulfilled:
      return {
        ...state,
        resendLoginCode: {
          ...state.resendLoginCode,
          pending: false,
          error: null,
          success: true,
        },
      };
    // RESEND VERIFICATION EMAIL
    case clearVerifyEmailAddressData.default:
      return {
        ...state,
        verifyEmailAddress: INITIAL_STATE.verifyEmailAddress,
      };
    case verifyEmailAddress.pending:
      return {
        ...state,
        verifyEmailAddress: {
          ...state.verifyEmailAddress,
          pending: true,
          error: null,
        },
      };
    case verifyEmailAddress.rejected:
      return {
        ...state,
        verifyEmailAddress: {
          ...state.verifyEmailAddress,
          pending: false,
          error: action.payload,
        },
      };
    case verifyEmailAddress.fulfilled:
      return {
        ...state,
        verifyEmailAddress: {
          ...state.verifyEmailAddress,
          pending: false,
          error: null,
          success: true,
        },
      };
    // FORGOT_PASSWORD
    case clearResetPasswordData.default:
      return {
        ...state,
        resetPassword: INITIAL_STATE.resetPassword,
      };
    case resetPassword.pending:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          pending: true,
          error: null,
        },
      };
    case resetPassword.rejected:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          pending: false,
          error: action.payload,
        },
      };
    case resetPassword.fulfilled:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          pending: false,
          error: null,
          success: true,
        },
      };
    // FORGOT_PASSWORD
    case clearForgotPasswordData.default:
      return {
        ...state,
        forgotPassword: INITIAL_STATE.forgotPassword,
      };
    case forgotPassword.pending:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          pending: true,
          error: null,
        },
      };
    case forgotPassword.rejected:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          pending: false,
          error: action.payload,
        },
      };
    case forgotPassword.fulfilled:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          pending: false,
          error: null,
          success: true,
        },
      };
    //LOG OUT
    case logoutUser.pending:
      return {
        ...state,
        logout: {
          ...state.logout,
          pending: true,
          error: null,
        },
      };
    case logoutUser.rejected:
      return {
        ...state,
        logout: {
          ...state.logout,
          pending: false,
          error: action.payload,
        },
      };

    case logoutUser.fulfilled:
      return INITIAL_STATE;

    default:
      return state;
  }
}
