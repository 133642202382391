import { logoutUser } from "../auth/types";
import {
  ApplicationsState,
  fetchApplications,
  removeApplication,
} from "./types";

const INITIAL_STATE: ApplicationsState = {
  pending: false,
  updating: false,
  error: null,
  success: false,
  paginator: null,
  data: [],
  selectedApplicationId: null,
};

export default function auth(
  state = INITIAL_STATE,
  action: any
): ApplicationsState {
  switch (action.type) {
    case fetchApplications.pending:
      return {
        ...state,
        pending: true,
        error: null,
        success: false,
      };

    case fetchApplications.rejected:
      return {
        ...state,
        pending: false,
        success: false,
        error: action.payload,
      };

    case fetchApplications.fulfilled:
      return {
        ...state,
        pending: false,
        success: true,
        error: null,
        data: action.payload.data || [],
        paginator: action.payload.paginator,
      };

    case removeApplication.default:
      return {
        ...state,
        selectedApplicationId: action.payload.id,
      };

    case removeApplication.pending:
      return {
        ...state,
        updating: true,
      };

    case removeApplication.rejected:
      return {
        ...state,
        updating: false,
      };

    case removeApplication.fulfilled:
      return {
        ...state,
        updating: false,
      };

    case logoutUser.fulfilled:
      return INITIAL_STATE;

    default:
      return state;
  }
}
