import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import SuccessPage from "./Success";

const ForgotPasswordRoutes: React.FC = (props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ForgotPassword} exact />
      <Route path={`${match.path}/success`} component={SuccessPage} exact />
    </Switch>
  );
};

export default ForgotPasswordRoutes;
