import React from "react";

import {
  Container,
  Selector,
  Label,
  DropdownItem,
  Dropdown,
  SearchBox,
} from "./styles";
import { ReactComponent as DropdownIcon } from "app/assets/img/icons/dropdown-icon.svg";
import ClickAway from "../ClickAway";

export interface SelectOption {
  label?: string;
  value?: any;
  icon?: JSX.Element | string;
  meta?: string;
  [key: string]: any;
}

interface Props {
  fullWidth?: boolean;
  containerClassName?: string;
  className?: string;
  label?: string;
  options: SelectOption[];
  value: any;
  name: string;
  placeholder?: string;
  onChange: (value: SelectOption) => void;
  disabled?: boolean;
  valueAccessor?: string;
  labelAccessor?: string;
  iconAccessor?: string;
  metaAccessor?: string;
  showSearch?: boolean;
  searchPlaceholder?: string;
}

const Select: React.FC<Props> = (props) => {
  const {
    fullWidth,
    containerClassName,
    className,
    label,
    value,
    name,
    options,
    onChange,
    disabled,
    valueAccessor,
    labelAccessor,
    iconAccessor,
    metaAccessor,
    showSearch,
    searchPlaceholder,
    placeholder,
  } = props;

  const [showDropdown, setShowDropdown] = React.useState(false);
  const toggleDropDown = () => setShowDropdown((v) => !v);
  const closeDropdown = () => setShowDropdown(false);

  const [searchInput, setSearchInput] = React.useState("");

  const [selectedValue, setSelectedValue] = React.useState<SelectOption | null>(
    null
  );
  const [selectOptions, setSelectOptions] = React.useState<JSX.Element[]>([]);
  const onSearchInputChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchInput(e.target.value);

  const onOptionSelected = React.useCallback(
    (option: SelectOption) => {
      const onOptionSelectedHandler = (
        e: React.MouseEvent<any, MouseEvent>
      ) => {
        onChange(option);
        toggleDropDown();
      };
      return onOptionSelectedHandler;
    },
    [onChange]
  );

  React.useEffect(() => {
    const _selectOptions: JSX.Element[] = [];

    options
      .filter((_option) =>
        _option[labelAccessor || "label"]
          .toLowerCase()
          .startsWith(searchInput.toLowerCase())
      )
      .forEach((_option) => {
        if (value === _option[valueAccessor || "value"])
          setSelectedValue(_option);
        const selectOption = (
          <DropdownItem
            key={_option[valueAccessor || "value"]}
            onClick={onOptionSelected(_option)}
          >
            <div className="value">
              {_option[iconAccessor || "icon"] && (
                <div className="icon">
                  {typeof _option[iconAccessor || "icon"] === "string" ? (
                    <img
                      src={_option[iconAccessor || "icon"]}
                      alt={_option[labelAccessor || "label"]}
                    />
                  ) : (
                    _option[iconAccessor || "icon"]
                  )}
                </div>
              )}
              <span>{_option[labelAccessor || "label"]}</span>
            </div>
            {_option[metaAccessor || "meta"] && (
              <span className="meta">{_option[metaAccessor || "meta"]}</span>
            )}
          </DropdownItem>
        );
        _selectOptions.push(selectOption);
      });

    setSelectOptions(_selectOptions);
  }, [
    iconAccessor,
    labelAccessor,
    metaAccessor,
    onOptionSelected,
    options,
    searchInput,
    value,
    valueAccessor,
  ]);

  return (
    <ClickAway
      onClickAway={closeDropdown}
      fullWidth={fullWidth}
      className={containerClassName}
    >
      <Container fullwidth={fullWidth} className={className}>
        {<input name={name} type="text" value={value} readOnly />}
        {label && <Label>{label}</Label>}
        <Selector type="button" disabled={disabled} onClick={toggleDropDown}>
          <div className="value">
            {selectedValue && selectedValue[iconAccessor || "icon"] && (
              <div className="icon">
                {typeof selectedValue[iconAccessor || "icon"] === "string" ? (
                  <img
                    src={selectedValue[iconAccessor || "icon"]}
                    alt={selectedValue[labelAccessor || "label"]}
                  />
                ) : (
                  selectedValue[iconAccessor || "icon"]
                )}
              </div>
            )}
            <span>
              {value && selectedValue
                ? selectedValue[labelAccessor || "label"]
                : placeholder || "Select a option..."}
            </span>
          </div>
          <DropdownIcon className="dropdown-icon" />
        </Selector>
        {showDropdown && (
          <Dropdown>
            {showSearch && (
              <SearchBox>
                <input
                  type="text"
                  placeholder={searchPlaceholder || "Search..."}
                  value={searchInput}
                  onChange={onSearchInputChanged}
                />
              </SearchBox>
            )}
            <div className="items">{selectOptions}</div>
          </Dropdown>
        )}
      </Container>
    </ClickAway>
  );
};

export default Select;
