import createActionType from "../util/createActionType";
import { PaginatorType, GenericResponse } from "app/api/types";
import { ApplicationType } from "app/api/applications/types";
import { GenericParamsPayload, GenericDeletePayload } from "app/api/types";

export interface ApplicationsState {
  data: ApplicationType[];
  paginator: PaginatorType | null;
  updating: boolean;
  pending: boolean;
  error: GenericResponse extends string
    ? string | null
    : GenericResponse | null;
  success: boolean;
  selectedApplicationId?: string | null;
}

export const fetchApplications = createActionType("fetch_applications");
export const removeApplication = createActionType("remove_application");

export interface FetchApplicationsAction {
  type: string;
  meta: GenericParamsPayload;
}

export interface RemoveApplicationAction {
  type: string;
  payload: GenericDeletePayload;
}
