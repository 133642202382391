import Fingerprint from "fingerprintjs2";
import UAParser from "ua-parser-js";

const options = {
  preprocessor: function (key: string, value: string) {
    if (key === "userAgent") {
      // @ts-ignore
      const parser = UAParser(value);
      const userAgentMinusVersion = parser.os.name;
      return userAgentMinusVersion;
    }
    return value;
  },
  excludes: {
    hardwareConcurrency: true,
    canvas: true,
    webgl: true,
    adBlock: true,
    customEntropyFunction: true,
    fonts: true,
    audio: true,
    enumerateDevices: true,
    plugins: true,
    doNotTrack: true,
    openDatabase: true,
    addBehavior: true,
    sessionStorage: true,
    localStorage: true,
    indexedDb: true,
    screenResolution: true,
    colorDepth: true,
    language: true,
    deviceMemory: true,
    cpuClass: true,
    fontsFlash: true,
    webdriver: true,
    pixelRatio: true,
    timezoneOffset: true,
    timezone: true,
    hasLiedLanguages: true,
    hasLiedBrowser: true,
  },
};

async function getDeviceFingerprintHash() {
  const hash = await Fingerprint.getPromise(options).then(function (
    components
  ) {
    const values = components.map(function (component) {
      return component.value;
    });
    return Fingerprint.x64hash128(values.join(""), 31);
  });
  return hash;
}

function getOsAndBrowsername(userAgent = window.navigator.userAgent) {
  // @ts-ignore
  const parser = UAParser(userAgent);
  const osAndBrowsername = parser.os.name + " " + parser.browser.name;
  return osAndBrowsername;
}

export { getDeviceFingerprintHash, getOsAndBrowsername };
