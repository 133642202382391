import { fetchNotifications, updateNotifications } from "./types";
import { UpdateNotificationsPayload } from "app/api/notifications/types";

const fetchUserNotifications = () => {
  return { type: fetchNotifications.default };
};

const updateUserNotifications = (payload: UpdateNotificationsPayload) => {
  return { type: updateNotifications.default, payload };
};

export { fetchUserNotifications, updateUserNotifications };
