import styled, { css } from "styled-components";

import FormInput from "app/styles/blocks/FormInput";
import searchIcon from "app/assets/img/search.svg";

export const Container = styled(FormInput.Main)`
  position: relative;
  & > input {
    display: none;
  }
`;

export const Label = styled(FormInput.Label)``;

export const Selector = styled.button`
  width: 100%;
  outline: none;
  border: 1px solid #cbd2d9;
  border-radius: 5px;
  font-family: "Aribau-Grotesk";
  height: 54px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .value {
    display: flex;
    align-items: center;

    & > .icon {
      height: 28px;
      width: 36px;
      margin-right: 16px;
      & > svg,
      & > img {
        height: 100%;
        width: 100%;
        border-radius: 2px;
      }
    }

    & > span {
      display: inline-block;
      font-size: 0.9rem;
    }
  }

  & > .dropdown-icon {
    ${(props) =>
      props.disabled &&
      css`
        display: none;
      `};
  }

  &:disabled {
    background: #f5f7fa;
    cursor: not-allowed;
  }
`;

export const Dropdown = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(16, 36, 94, 0.1);
  border-radius: 5px;
  width: 100%;
  position: absolute;
  top: 100%;
  margin-top: 3px;
  max-height: 547px;
  padding: 8px;
  z-index: 2;

  .items {
    max-height: 490px;
    overflow: auto;
  }
`;

export const DropdownItem = styled.div`
  height: 48px;
  width: 100%;
  padding: 0px 12px;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: #f5f7fa;
  }

  & > .value {
    display: flex;
    align-items: center;

    & > .icon {
      height: 20px;
      width: 25px;
      margin-right: 16px;
      & > svg,
      & > img {
        height: 100%;
        width: 100%;
        border-radius: 2px;
      }
    }

    & > span {
      display: inline-block;
      font-size: 0.8rem;
    }
  }

  & > .meta {
    font-size: 0.7rem;
    color: #7b8794;
  }
`;

export const SearchBox = styled.div`
  padding: 15px 4px;
  margin: 0 4px 4px;
  border-bottom: 0.5px solid #e4e7eb;
  display: flex;
  align-items: center;

  & > input {
    flex: 1;
    outline: none;
    font-size: 0.8rem;
    font-family: "Aribau-Grotesk";
    border: none;
    &::placeholder {
      color: #cbd2d9;
    }
  }

  &::before {
    content: "";
    display: block;
    height: 20px;
    width: 20px;
    background: url(${searchIcon}) no-repeat;
    background-size: contain;
    margin-right: 19px;
  }
`;
