import { AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/utils/parseError";
import { fetchActivities, FetchActivitiesAction } from "./types";
import { FetchActivitiesResponse } from "app/api/activities/types";

function* fetchActivitiesSaga(action: FetchActivitiesAction) {
  try {
    const { meta } = action;

    yield put({ type: fetchActivities.pending });

    const res: AxiosResponse<FetchActivitiesResponse> = yield call(
      api.activitiesService.fetchActivities,
      meta
    );
    const data = res.data;
    yield put({ type: fetchActivities.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: fetchActivities.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([takeLatest(fetchActivities.default, fetchActivitiesSaga)]);
}
