import { AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
import api from "app/api";
import parseError from "app/utils/parseError";
import {
  fetchDevices,
  FetchDevicesAction,
  removeDevice,
  RemoveDeviceAction,
} from "./types";
import { FetchDevicesResponse } from "app/api/devices/types";

function* fetchDevicesSaga(action: FetchDevicesAction) {
  try {
    const { meta } = action;

    yield put({ type: fetchDevices.pending });

    const res: AxiosResponse<FetchDevicesResponse> = yield call(
      api.devicesService.fetchDevices,
      meta
    );
    const data = res.data;
    yield put({ type: fetchDevices.fulfilled, payload: data });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: fetchDevices.rejected, payload: errorMessage });
  }
}

function* removeDevicesSaga(action: RemoveDeviceAction) {
  try {
    const { payload } = action;

    yield put({ type: removeDevice.pending });

    const res: AxiosResponse<FetchDevicesResponse> = yield call(
      api.devicesService.removeDevice,
      payload
    );
    const data = res.data;
    yield put({ type: removeDevice.fulfilled, payload: data });
    yield put({ type: fetchDevices.default, meta: { page: 1 } });
  } catch (error) {
    const errorMessage = parseError(error);
    yield put({ type: removeDevice.rejected, payload: errorMessage });
  }
}

export default function* allSaga() {
  yield all([
    takeLatest(fetchDevices.default, fetchDevicesSaga),
    takeLatest(removeDevice.default, removeDevicesSaga),
  ]);
}
