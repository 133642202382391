import {
  ForgotPasswordPayload,
  ResetPasswordPayload,
} from "../../api/auth/types";
import {
  loginUser,
  clearLoginData,
  clearLoginErrors,
  signupUser,
  clearSignupData,
  clearSignupErrors,
  resendVerificationEmail,
  clearResendVerificationEmailData,
  verifyEmailAddress,
  clearVerifyEmailAddressData,
  loginConfirm,
  resendLoginCode,
  logoutUser,
  clearResendLoginCodeData,
  resetPassword,
  clearResetPasswordData,
  forgotPassword,
  clearForgotPasswordData,
} from "./types";
import {
  LoginPayload,
  SignupPayload,
  ResendVerificationEmailPayload,
  LoginConfirmPayload,
} from "app/api/auth/types";

const login = (payload: LoginPayload) => {
  return { type: loginUser.default, payload };
};

const confirmLogin = (payload: LoginConfirmPayload) => {
  return { type: loginConfirm.default, payload };
};

const cleanupLoginState = () => {
  return { type: clearLoginData.default };
};

const cleanupLoginErrors = () => {
  return { type: clearLoginErrors.default };
};

const signup = (payload: SignupPayload) => {
  return { type: signupUser.default, payload };
};

const cleanupSignupState = () => {
  return { type: clearSignupData.default };
};

const cleanupSignupErrors = () => {
  return { type: clearSignupErrors.default };
};

const sendForgotPasswordEmail = (payload: ForgotPasswordPayload) => {
  return { type: forgotPassword.default, payload };
};

const cleanupForgotPasswordState = () => {
  return { type: clearForgotPasswordData.default };
};

const resetUserPassword = (payload: ResetPasswordPayload) => {
  return { type: resetPassword.default, payload };
};

const cleanupResetPasswordState = () => {
  return { type: clearResetPasswordData.default };
};

const resendUserLoginCode = (token: string) => {
  return { type: resendLoginCode.default, payload: { token } };
};

const cleanupResendLoginCodeState = () => {
  return { type: clearResendLoginCodeData.default };
};

const resendUserVerificationEmail = (
  payload: ResendVerificationEmailPayload
) => {
  return { type: resendVerificationEmail.default, payload };
};

const cleanupResendVerificationEmailState = () => {
  return { type: clearResendVerificationEmailData.default };
};

const verifyUserEmail = (code: string) => {
  return { type: verifyEmailAddress.default, payload: { code } };
};

const cleanupVerifyUserEmailState = () => {
  return { type: clearVerifyEmailAddressData.default };
};

const logout = () => {
  return { type: logoutUser.default };
};

export {
  login,
  confirmLogin,
  cleanupLoginState,
  cleanupLoginErrors,
  sendForgotPasswordEmail,
  cleanupForgotPasswordState,
  resetUserPassword,
  cleanupResetPasswordState,
  signup,
  cleanupSignupState,
  cleanupSignupErrors,
  resendUserLoginCode,
  cleanupResendLoginCodeState,
  resendUserVerificationEmail,
  cleanupResendVerificationEmailState,
  verifyUserEmail,
  cleanupVerifyUserEmailState,
  logout,
};
