import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ProfilePage from "./Profile";
import EditProfilePage from "./EditProfile";
import EditUsernamePage from "./EditUsername";

const ProfileRoutes: React.FC = (props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ProfilePage} exact />
      <Route path={`${match.path}/edit`} component={EditProfilePage} exact />
      <Route
        path={`${match.path}/username`}
        component={EditUsernamePage}
        exact
      />
    </Switch>
  );
};

export default ProfileRoutes;
