export const emailIsValid = (email: string = "") =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

export const passwordConditions = {
  minLengthRegex: /^.{8,}$/,
  minOneLetterRegex: /[a-z]/i,
  minOneUpperCaseRegex: /.*[A-Z].*/,
  minOneLowerCaseRegex: /.*[a-z].*/,
  minOneNumberRegex: /\d{1,}/,
  minOneSpecialRegex: /[-+_!@#$%^&*.,?]/,
  nameRegexChecker: /^(?![ .]+$)[a-zA-Z .-]*$/i,
};

export const isUrl = (str: string): boolean => {
  const regexp = /^(https?|ftp|torrent|image|irc):\/\/(-\.)?([^\s/?.#]+\.?)+(\/[^\s]*)?$/i;
  return regexp.test(str);
};
