import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import PrevPageLink from "app/components/PrevPageLink";
import PageHeader from "app/styles/blocks/PageHeader";
import SmsPage from "./Sms";
import EnableSmsPage from "./EnableSms";

const AuthApp: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div className="page-content">
      <PrevPageLink to="/security" text="Security" />
      <PageHeader.Main>
        <PageHeader.Title big>Enable SMS authentication</PageHeader.Title>
      </PageHeader.Main>
      <Switch>
        <Route path={`${match.path}`} component={SmsPage} exact />

        <Route path={`${match.path}/enable`} component={EnableSmsPage} exact />
      </Switch>
    </div>
  );
};

export default AuthApp;
