import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "app/styles/blocks/PageHeader";
import PrevPageLink from "app/components/PrevPageLink";
import { UsernameForm } from "./styles";
import P from "app/styles/elements/P";
import { RootState } from "app/redux/reducers";
import {
  clearProfileActionState,
  updateUserUsername,
} from "app/redux/profile/actions";
import Input from "app/components/Input";
import Button from "app/components/Button";

const EditUsernamePage: React.FC = () => {
  const dispatch = useDispatch();
  const { data, action } = useSelector((state: RootState) => state.profile);

  const [username, setUsername] = React.useState(data?.username || "");
  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const onUpdateUsername = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (username.trim()) {
        dispatch(updateUserUsername({ username }));
      }
    },
    [dispatch, username]
  );

  React.useEffect(() => {
    dispatch(clearProfileActionState());
  }, [dispatch]);

  return (
    <div className="page-content">
      <PrevPageLink to="/profile" text="General" />
      <PageHeader.Main>
        <PageHeader.Title big>Edit Username</PageHeader.Title>
      </PageHeader.Main>
      <UsernameForm onSubmit={onUpdateUsername}>
        <Input
          label="Username"
          name="username"
          id="username"
          value={username}
          onChange={onInputChanged}
        />
        <P little>
          Your new payment page: <span>https://b-tag.com/{username}</span>
        </P>
        <Button
          variant="green"
          text="Change username"
          disabled={!username.length}
          loading={action === "updating"}
        />
      </UsernameForm>
    </div>
  );
};

export default EditUsernamePage;
