import React, { useState, useRef } from "react";
import { useFormik } from "formik";

import ReCAPTCHA from "react-google-recaptcha";
import { Form, RecaptchaBlock } from "./styles";
import Checkbox from "app/components/Checkbox";
import Input from "app/components/Input";
import Button from "app/components/Button";
import { LoginPayload } from "app/api/auth/types";

interface Props {
  email: string;
  onSubmitForm: (
    password: string,
    remember_me: boolean,
    token: string | null
  ) => void;
  clearError: () => void;
  loading?: boolean;
  error?: string | null;
}

const LoginForm: React.FC<Props> = (props) => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const validate = (values: LoginPayload) => {
    const errors: any = {};

    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };
  const form = useFormik<LoginPayload>({
    initialValues: {
      email: props.email,
      password: "",
      remember_me: false,
    },
    onSubmit: (values) => {
      if (!recaptchaToken) return;
      props.onSubmitForm(values.password, !!values.remember_me, recaptchaToken);

      const capcha = recaptchaRef.current;
      if (capcha) capcha.reset();
    },
    validate,
    validateOnChange: false,
  });

  const onInputFocus = (name: string) => () => {
    form.setFieldError(name, undefined);
    props.clearError();
  };

  const onRecapthaTokenChange = (token: any) => {
    setRecaptchaToken(token);
  };

  return (
    <Form onSubmit={form.handleSubmit}>
      <Input
        fullwidth
        placeholder="Enter password"
        label="Password"
        name="password"
        id="password"
        type="password"
        autoFocus
        value={form.values.password}
        onChange={form.handleChange}
        error={!!form.errors.password || !!props.error}
        errorMessage={form.errors.password || props?.error || ""}
        onFocus={onInputFocus("password")}
      />
      <Checkbox
        containerClassname="form-group"
        label="Keep me signed in on this computer"
        name="remember_me"
        checked={form.values.remember_me}
        onChange={form.handleChange}
      />
      <RecaptchaBlock>
        <ReCAPTCHA
          sitekey={process?.env?.REACT_APP_RECAPTHA_SITE_KEY || ""}
          onChange={onRecapthaTokenChange}
          ref={recaptchaRef}
        />
      </RecaptchaBlock>
      <Button
        type="submit"
        className="submit-button"
        text="Log in"
        variant="green"
        loading={props.loading}
      />
    </Form>
  );
};

export default LoginForm;
