import React from "react";
import { Container, Text, Checkmark } from "./styles";

interface Props extends React.InputHTMLAttributes<any> {
  label?: string;
  containerClassname?: string;
}

const Checkbox: React.FC<Props> = props => {
  const { label, containerClassname, ...rest } = props;
  return (
    <Container className={containerClassname}>
      <input type="checkbox" {...rest} />
      <Checkmark />
      {!!label && <Text>{label}</Text>}
    </Container>
  );
};

export default Checkbox;
