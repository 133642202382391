import styled from "styled-components";
import { AuthLayoutProps } from ".";
import media from "app/styles/utils/media";

const Header = styled.header<AuthLayoutProps>`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => (props.signup ? "148px" : "40px")};

  ${media.mobile`
    margin-bottom: 40px;
  `};

  & > .logo {
    height: 26px;
  }
`;

export default Header;
