import React from "react";
import { useFormik } from "formik";
import { PhoneInputBox } from "../styles";
import Button from "app/components/Button";
import P from "app/styles/elements/P";
import Select from "app/components/Select";
import countries from "app/data/countries";
import FormInput from "app/styles/blocks/FormInput";
import { InitializeSecurityServicePayload } from "app/api/security/types";

interface Props {
  handleSubmit: (values: InitializeSecurityServicePayload) => void;
  loading: boolean;
}

const PhoneNumberForm: React.FC<Props> = (props) => {
  const [country, setCountry] = React.useState("Nigeria");

  const validate = (values: InitializeSecurityServicePayload) => {
    const errors: any = {};

    if (!values.phone) {
      errors.phone = "Phone number is required";
    }
    return errors;
  };

  const form = useFormik<InitializeSecurityServicePayload>({
    initialValues: {
      phone: "",
      country_call_code: "234",
    },
    onSubmit: (values) => {
      props.handleSubmit(values);
    },
    validate,
    validateOnChange: false,
  });

  const onInputFocus = (name: string) => () =>
    form.setFieldError(name, undefined);

  return (
    <form onSubmit={form.handleSubmit}>
      <Select
        containerClassName="form-group"
        fullWidth
        name="country"
        placeholder="Choose country"
        showSearch
        searchPlaceholder="Search country"
        options={countries}
        label="Country"
        iconAccessor="flag"
        labelAccessor="name"
        valueAccessor="name"
        metaAccessor="callingCode"
        value={country}
        onChange={(v) => {
          setCountry(v["name"]);
          form.setFieldValue(
            "country_call_code",
            v["callingCode"]?.replace("+", "")
          );
        }}
      />
      <FormInput.Main className="form-group" fullwidth>
        <FormInput.Label>Phone number</FormInput.Label>
        <PhoneInputBox>
          <div className="prefix">
            <P medium>+{form.values.country_call_code}</P>
          </div>
          <FormInput.Input
            name="phone"
            value={form.values.phone}
            onChange={form.handleChange}
            error={!!form.errors.phone}
            onFocus={onInputFocus("phone")}
          />
        </PhoneInputBox>
        {!!form.errors.phone && (
          <FormInput.ErrorMsg>{form.errors.phone}</FormInput.ErrorMsg>
        )}
      </FormInput.Main>
      <P little className="help-text">
        We’ll send a 6-digit authentication code to verify you own the number.
      </P>
      <Button
        type="submit"
        variant="green"
        text="Send authentication code"
        loading={props.loading}
      />
    </form>
  );
};

export default PhoneNumberForm;
