import React from "react";
import useAuth from "app/hooks/useAuth";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import api from "app/api";
import queryString from "query-string";
import { RootState } from "app/redux/reducers";
import { asyncWrap } from "app/utils/helpers";
import decodeJWT from "app/utils/decodeJWT";
import { logout } from "app/redux/auth/actions";
import LoaderBox from "./LoaderBox";
import { setAppTokens } from "../redux/tokens/actions";
import { isUrl } from "app/utils/regex";

type States = "verifying_token" | "not_logged_in" | "logged_in";

const HandleServiceRedirectRoute: React.FC<RouteProps> = (props) => {
  const [state, setState] = React.useState<States>("verifying_token");

  const tokens = useSelector((state: RootState) => state.tokens);

  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useAuth();

  React.useEffect(
    React.useCallback(() => {
      async function onRouteEnter() {
        if (isLoggedIn) {
          const queryValues = queryString.parse(location.search);
          const logoutUser = queryValues.logout;

          if (!!logoutUser) {
            dispatch(logout());
            setState("not_logged_in");
          } else {
            const app = queryValues.redirect;
            const { jti: jwtIdentifier } = decodeJWT(
              tokens?.access_token || ""
            );

            if (app && isUrl(String(app)) && jwtIdentifier) {
              const [, tokenExists] = await asyncWrap(
                api.authService.validateCredential({
                  name: "token_valid",
                  value: jwtIdentifier,
                })
              );

              if (tokenExists) {
                let redirectUrl = `${app}/oauth/${tokens?.access_token}`;
                if (!!tokens?.refresh_token) {
                  redirectUrl = `${redirectUrl}?refreshToken=${tokens?.refresh_token}`;
                }
                window.location.replace(redirectUrl);
              } else {
                if (tokens?.refresh_token) {
                  const [, response] = await asyncWrap(
                    api.authService.refreshAccessToken(tokens.refresh_token)
                  );

                  if (response?.access_token) {
                    dispatch(setAppTokens(response));
                    const redirectUrl = `${app}/oauth/${tokens?.access_token}?refreshToken=${tokens?.refresh_token}`;
                    window.location.replace(redirectUrl);
                  } else {
                    setState("not_logged_in");
                    dispatch(logout());
                  }
                } else {
                  setState("not_logged_in");
                  dispatch(logout());
                }
              }
            } else {
              setState("logged_in");
            }
          }
        } else {
          setState("not_logged_in");
        }
      }

      onRouteEnter();
    }, [dispatch, isLoggedIn, location.search, tokens]),
    [isLoggedIn]
  );

  return (
    <Route
      {...props}
      component={() => {
        if (state === "verifying_token") return <LoaderBox />;

        if (state === "logged_in") return <Redirect to="/profile" />;

        return <Route {...props} />;
      }}
    />
  );
};

export default HandleServiceRedirectRoute;
