import styled from "styled-components";
import Title from "./Title";
import Description from "./Description";

const Main = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(211, 213, 216, 0.5);
  padding-bottom: 35px;
`;

const PageHeader = {
  Main,
  Title,
  Description
};

export default PageHeader;
