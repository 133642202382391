import React from "react";
import { useFormik } from "formik";

import { Form } from "./styles";
import Input from "app/components/Input";
import Button from "app/components/Button";
import { Title, EmailBlock } from "../styles";
import H2 from "app/styles/elements/H2";
import P from "app/styles/elements/P";
import { SignupPayload } from "app/api/auth/types";

interface Props {
  onDone: (firstname: string, lastname: string) => void;
  onChangeRef: () => void;
  onChangeEmail: () => void;
  formState: SignupPayload;
}

type PersonalInfoFormType = {
  firstname: string;
  lastname: string;
};

const PersonalInfoForm: React.FC<Props> = (props) => {
  const { onDone, onChangeRef, formState, onChangeEmail } = props;

  const validate = (values: PersonalInfoFormType) => {
    const errors: any = {};

    if (!values.firstname) {
      errors.firstname = "First name is required";
    }
    if (!values.lastname) {
      errors.lastname = "Last name is required";
    }

    return errors;
  };
  const form = useFormik<PersonalInfoFormType>({
    initialValues: {
      firstname: "",
      lastname: "",
    },
    onSubmit: (values) => {
      onDone(values.firstname, values.lastname);
    },
    validate,
    validateOnChange: true,
  });
  const onInputFocus = (name: string) => () =>
    form.setFieldError(name, undefined);

  return (
    <>
      <Title>
        <H2>Personal Information</H2>
        <P small>
          Please make sure the details are correct as they will be used to
          verify your identity.
        </P>
      </Title>
      {!!formState.refCode ? (
        <EmailBlock>
          <P small>{formState.refCode}</P>
          <button onClick={onChangeRef}>Change referral code</button>
        </EmailBlock>
      ) : (
        <EmailBlock>
          <P small>{formState.email}</P>
          <button onClick={onChangeEmail}>Change email</button>
        </EmailBlock>
      )}
      <Form onSubmit={form.handleSubmit}>
        <Input
          fullwidth
          containerClassName="form-group"
          placeholder="Enter your firstname"
          label="Firstname"
          name="firstname"
          id="firstname"
          value={form.values.firstname}
          onChange={form.handleChange}
          autoFocus
          error={!!form.errors.firstname && form.touched.firstname}
          errorMessage={
            form.touched.firstname ? form.errors.firstname : undefined
          }
          onFocus={onInputFocus("firstname")}
        />
        <Input
          fullwidth
          containerClassName="form-group"
          placeholder="Enter your lastname"
          label="Lastname"
          name="lastname"
          id="lastname"
          value={form.values.lastname}
          onChange={form.handleChange}
          error={!!form.errors.lastname && form.touched.lastname}
          errorMessage={
            form.touched.lastname ? form.errors.lastname : undefined
          }
          onFocus={onInputFocus("lastname")}
        />
        <Button
          type="submit"
          className="submit-button"
          text="Next"
          variant="green"
        />
      </Form>
    </>
  );
};

export default PersonalInfoForm;
