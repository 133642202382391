import React from "react";
import { format } from "date-fns";
import {
  Container,
  ItemName,
  ItemDate,
  ItemLocation,
  DeviceBadge,
} from "./styles";
import P from "app/styles/elements/P";
import { ActivityType } from "app/api/activities/types";

const ActivityItem: React.FC<ActivityType> = (props) => {
  const date = props?.created_at
    ? format(new Date(props.created_at), "MMMM dd, yyyy 'at' hh:mm a")
    : "-";

  return (
    <Container>
      <ItemName>
        <P>{props?.action}</P>
      </ItemName>
      <div>
        <DeviceBadge>{props?.agent}</DeviceBadge>
      </div>
      <ItemLocation>
        <P>{props?.location}</P>
        <P className="ip-address">{props?.ip}</P>
      </ItemLocation>
      <ItemDate>{date}</ItemDate>
    </Container>
  );
};

export default ActivityItem;
