import styled from "styled-components";

const Label = styled.label`
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 22px;
  display: block;
  margin-bottom: 8px;
  color: #616e7c;
`;

export default Label;
