import React from "react";
import { ItemContainer, ItemInfo, ItemAction } from "./styles";
import Checkbox from "app/components/Checkbox";
import P from "app/styles/elements/P";

interface Props extends React.InputHTMLAttributes<React.ChangeEventHandler> {
  title: string;
  description: string;
  active: boolean;
}

const CommunicationItem: React.FC<Props> = (props) => {
  const { title, description, active, ...rest } = props;
  return (
    <ItemContainer>
      <ItemInfo>
        <P medium semiBold>
          {title}
        </P>
        <P small className="sub-title">
          {description}
        </P>
      </ItemInfo>
      <ItemAction>
        <Checkbox label="Email" checked={active} {...rest} />
      </ItemAction>
    </ItemContainer>
  );
};

export default CommunicationItem;
