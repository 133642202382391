import React from "react";
import { CodeInputBox, PhoneContainer, Section } from "../styles";
import CodeInput from "app/components/CodeInput";
// import Button from "app/components/Button";
import P from "app/styles/elements/P";

interface Props {
  onCodeCompleted: (code: string) => void;
  diableInputs: boolean;
  message?: string;
}

const ConfirmPhoneNumberForm: React.FC<Props> = (props) => {
  return (
    <PhoneContainer>
      <Section>
        <P medium semiBold>
          Enter authentication code
        </P>
        <P small className="description">
          {props.message}
        </P>
      </Section>
      <CodeInputBox>
        <CodeInput
          onCodeCompleted={props.onCodeCompleted}
          disableFields={props.diableInputs}
        />
      </CodeInputBox>
      {/* <Button variant="green" className="link" text="Enable" /> */}
    </PhoneContainer>
  );
};

export default ConfirmPhoneNumberForm;
