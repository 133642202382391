import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/redux/reducers";

export default function useAuth() {
  const tokens = useSelector((state: RootState) => state.tokens);

  const [isLoggedIn, setIsLoggedIn] = React.useState(!!tokens?.access_token);

  React.useEffect(() => {
    setIsLoggedIn(!!tokens?.access_token);
  }, [tokens]);

  return isLoggedIn;
}
