import React from "react";
import { useFormik } from "formik";
import { Form, Container, InputsBox } from "./styles";
//@ts-ignore
import countriesstatescities from "countrycitystatejson";
import Input from "app/components/Input";
import Select from "app/components/Select";
import Button from "app/components/Button";
import DatePicker from "app/components/DatePicker";
import FormInput from "app/styles/blocks/FormInput";
import { UserProfileType, UpdateProfilePayload } from "app/api/profile/types";

interface Props {
  initialValues: UserProfileType | null;
  onUpdateProfile: (payload: UpdateProfilePayload) => void;
  loading?: boolean;
}

const countries = countriesstatescities.getCountries().map((country: any) => ({
  ...country,
  icon: `https://www.countryflags.io/${country.shortName?.toLowerCase()}/flat/64.png`,
}));

const PersonalDetailsForm: React.FC<Props> = (props) => {
  const { initialValues, onUpdateProfile, loading } = props;

  const [country, setCountry] = React.useState(() => {
    const _country = countries.find(
      (c: any) => c.name === initialValues?.country
    )?.shortName;

    return _country;
  });

  const validate = (values: UpdateProfilePayload) => {
    const errors: any = {};

    if (!values.first_name) {
      errors.first_name = "First name is required";
    }

    if (!values.last_name) {
      errors.last_name = "Last name is required";
    }

    if (!values.country) {
      errors.country = "Country name is required";
    }

    return errors;
  };

  const form = useFormik<UpdateProfilePayload>({
    initialValues: {
      first_name: initialValues?.first_name,
      last_name: initialValues?.last_name,
      gender: initialValues?.gender,
      date_of_birth: initialValues?.date_of_birth,
      city: initialValues?.city,
      state: initialValues?.state,
      country: initialValues?.country,
      street_address_1: initialValues?.street_address_1,
      street_address_2: initialValues?.street_address_2,
    },
    onSubmit: (values) => {
      onUpdateProfile(values);
    },
    validate,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const states = React.useMemo(() => {
    const _states = countriesstatescities.getStatesByShort(country);

    if (_states) {
      const mappedStates = _states.map((state: string) => ({
        label: state,
        value: state,
      }));
      return mappedStates;
    } else {
      return [];
    }
  }, [country]);

  const onCityChange = (value: any) =>
    form.setFieldValue("city", value["value"]);

  const cities = React.useMemo(
    React.useCallback(() => {
      const _cities = countriesstatescities.getCities(
        country,
        form.values.state
      );

      if (_cities && _cities.length > 0) {
        const mappedCities = _cities.map((city: string) => ({
          label: city,
          value: city,
        }));

        return mappedCities;
      } else {
        if (states?.length > 0 && countries?.length > 0 && form.values.state) {
          return [{ label: form.values.state, value: form.values.state }];
        } else {
          return [];
        }
      }
    }, [country, form.values.state, states]),
    [country, form.values.state]
  );

  const onCountryChange = (value: any) => {
    form.setFieldValue("country", value["name"]);
    form.setFieldValue("state", "");
    form.setFieldValue("city", "");
    setCountry(value["shortName"]);
  };

  const onStateChange = (value: any) => {
    form.setFieldValue("state", value["value"]);
    form.setFieldValue("city", "");
  };

  const genders = [
    {
      label: "Male",
      value: "male",
    },
    {
      label: "Female",
      value: "female",
    },
    {
      label: "Others",
      value: "others",
    },
  ];

  const onInputFocus = (name: string) => () =>
    form.setFieldError(name, undefined);

  return (
    <Container>
      <Form onSubmit={form.handleSubmit}>
        <InputsBox>
          <Input
            containerClassName="form-group"
            label="First name"
            name="first_name"
            id="first_name"
            value={form.values.first_name}
            onChange={form.handleChange}
            error={!!form.errors.first_name}
            errorMessage={form.errors.first_name}
            onFocus={onInputFocus("first_name")}
          />
          <Input
            containerClassName="form-group"
            name="last_name"
            id="last_name"
            label="Last name"
            value={form.values.last_name}
            onChange={form.handleChange}
            error={!!form.errors.last_name}
            errorMessage={form.errors.last_name}
            onFocus={onInputFocus("last_name")}
          />
        </InputsBox>

        <InputsBox>
          <Select
            fullWidth
            className="form-group"
            name="gender"
            label="Gender"
            options={genders}
            value={form.values.gender}
            onChange={(v) => form.setFieldValue("gender", v["value"])}
          />

          <FormInput.Main className="form-group" fullwidth>
            <FormInput.Label>Date of birth</FormInput.Label>
            <DatePicker
              fullWidth
              value={
                form.values.date_of_birth
                  ? new Date(form.values.date_of_birth)
                  : new Date()
              }
              onChange={(newDateofBirth) => {
                form.setFieldValue("date_of_birth", newDateofBirth);
              }}
            />
          </FormInput.Main>
        </InputsBox>

        <InputsBox>
          <Input
            containerClassName="form-group"
            name="street_address_1"
            id="street_address_1"
            label="Street address 1"
            value={form.values.street_address_1}
            onChange={form.handleChange}
            error={!!form.errors.street_address_1}
            errorMessage={form.errors.street_address_1}
            onFocus={onInputFocus("street_address_1")}
          />
          <Input
            containerClassName="form-group"
            name="street_address_2"
            id="street_address_2"
            label="Street address 2"
            value={form.values.street_address_2}
            onChange={form.handleChange}
            error={!!form.errors.street_address_2}
            errorMessage={form.errors.street_address_2}
            onFocus={onInputFocus("street_address_2")}
          />
        </InputsBox>

        <InputsBox>
          <Select
            showSearch={true}
            fullWidth
            className="form-group"
            name="country"
            label="Country of residence"
            searchPlaceholder="Search countries"
            options={countries}
            value={form.values.country}
            valueAccessor="name"
            labelAccessor="name"
            onChange={onCountryChange}
          />
          <Select
            showSearch={true}
            fullWidth
            containerClassName="form-group"
            name="state"
            label="State"
            searchPlaceholder="Search states"
            options={states}
            value={form.values.state}
            onChange={onStateChange}
          />
        </InputsBox>
        <InputsBox>
          <Select
            showSearch={true}
            fullWidth
            containerClassName="form-group"
            name="city"
            label="City"
            searchPlaceholder="Search cities"
            options={cities}
            value={form.values.city}
            onChange={onCityChange}
          />
        </InputsBox>
        <div className="footer">
          <Button type="submit" variant="green" text="Save" loading={loading} />
        </div>
      </Form>
    </Container>
  );
};

export default PersonalDetailsForm;
